/*=============================
    Keyframe Animation
===============================*/
// Image zoom
.zoom-img {
    transform: translateZ(0);
    overflow: hidden;

    & img {
        display: block;
        transition: transform 0.35s ease-in-out;
    }

    &:hover img {
        transform: scale(1.05);
    }
}

@-webkit-keyframes hoverShine {
    100% {
        left: 125%;
    }
}

@keyframes hoverShine {
    100% {
        left: 125%;
    }
}

.shine-animate {
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 1;

    &:before {
        position: absolute;
        top: 0;
        left: -100%;
        display: block;
        content: '';
        width: 50%;
        height: 100%;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
        transform: skewX(-25deg);
        z-index: 1;
    }
}

.shine-animate-item:hover .shine-animate:before {
    animation: hoverShine 1s;
}

.alltuchtopdown {
    -webkit-animation: alltuchtopdown 1.5s ease-in-out 0s infinite alternate;
    animation: alltuchtopdown 1.5s ease-in-out 0s infinite alternate;
    animation-duration: 3s;
}

@keyframes alltuchtopdown {
    0% {
        -webkit-transform: rotateX(0deg) translateY(0px);
        -moz-transform: rotateX(0deg) translateY(0px);
        -ms-transform: rotateX(0deg) translateY(0px);
        -o-transform: rotateX(0deg) translateY(0px);
        transform: rotateX(0deg) translateY(0px);
    }

    50% {
        -webkit-transform: rotateX(0deg) translateY(-20px);
        -moz-transform: rotateX(0deg) translateY(-20px);
        -ms-transform: rotateX(0deg) translateY(-20px);
        -o-transform: rotateX(0deg) translateY(-20px);
        transform: rotateX(0deg) translateY(-20px);
    }

    100% {
        -webkit-transform: rotateX(0deg) translateY(0px);
        -moz-transform: rotateX(0deg) translateY(0px);
        -ms-transform: rotateX(0deg) translateY(0px);
        -o-transform: rotateX(0deg) translateY(0px);
        transform: rotateX(0deg) translateY(0px);
    }
}

@-webkit-keyframes alltuchtopdown {
    0% {
        -webkit-transform: rotateX(0deg) translateY(0px);
        -moz-transform: rotateX(0deg) translateY(0px);
        -ms-transform: rotateX(0deg) translateY(0px);
        -o-transform: rotateX(0deg) translateY(0px);
        transform: rotateX(0deg) translateY(0px);
    }

    50% {
        -webkit-transform: rotateX(0deg) translateY(-20px);
        -moz-transform: rotateX(0deg) translateY(-20px);
        -ms-transform: rotateX(0deg) translateY(-20px);
        -o-transform: rotateX(0deg) translateY(-20px);
        transform: rotateX(0deg) translateY(-20px);
    }

    100% {
        -webkit-transform: rotateX(0deg) translateY(0px);
        -moz-transform: rotateX(0deg) translateY(0px);
        -ms-transform: rotateX(0deg) translateY(0px);
        -o-transform: rotateX(0deg) translateY(0px);
        transform: rotateX(0deg) translateY(0px);
    }
}

.rightToLeft {
    -webkit-animation-name: rightToLeft;
    animation-name: rightToLeft;
    -webkit-animation-duration: 10s;
    animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

@keyframes rightToLeft {
    0% {
        transform: translateX(-30px);
    }

    50% {
        transform: translateX(-10px);
    }

    100% {
        transform: translateX(-30px);
    }
}

.ribbonRotate {
    -webkit-animation-name: ribbonRotate;
    animation-name: ribbonRotate;
    -webkit-animation-duration: 10s;
    animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

@keyframes ribbonRotate {
    0%,
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    25%,
    75% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg);
    }

    50% {
        -webkit-transform: rotate(30deg);
        transform: rotate(30deg);
    }
}

.rotateme {
    -webkit-animation-name: rotateme;
    animation-name: rotateme;
    -webkit-animation-duration: 10s;
    animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

@keyframes rotateme {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rotateme {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes fadeInUp2 {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

.fadeInUp2 {
    -webkit-animation-name: fadeInUp2;
    animation-name: fadeInUp2;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@keyframes fadeInLeft2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}

.fadeInLeft2 {
    -webkit-animation-name: fadeInLeft2;
    animation-name: fadeInLeft2;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@keyframes marquee {
    0% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
    }

    100% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

@-webkit-keyframes heartbeat {
    from {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }

    to {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
    }
}

@keyframes heartbeat {
    from {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }

    to {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
    }
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}

@-webkit-keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}

.shake {
    -webkit-animation-name: shake;
    animation-name: shake;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

@-webkit-keyframes shake {
    0% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg);
    }

    50% {
        -webkit-transform: rotate(-15deg);
        transform: rotate(-15deg);
    }

    100% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg);
    }
}

@keyframes shake {
    0% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg);
    }

    50% {
        -webkit-transform: rotate(-15deg);
        transform: rotate(-15deg);
    }

    100% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg);
    }
}

.shake-2 {
    -webkit-animation-name: shake-2;
    animation-name: shake-2;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

@-webkit-keyframes shake-2 {
    0% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
    }

    50% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }

    100% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
    }
}

@keyframes shake-2 {
    0% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
    }

    50% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }

    100% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
    }
}

.flickering {
    -webkit-animation-name: flickering;
    animation-name: flickering;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

@-webkit-keyframes flickering {
    0% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }

    50% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }

    100% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
}

@keyframes flickering {
    0% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }

    50% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }

    100% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
}

@keyframes animation__shape {
    0% {
        stroke-dashoffset: 12;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes animation__shape-two {
    0% {
        stroke-dashoffset: 18;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes animation__shape-three {
    0% {
        stroke-dashoffset: 24;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes animation__shape-four {
    0% {
        stroke-dashoffset: 14;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes animation__shape-five {
    0% {
        stroke-dashoffset: 16;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

// This is for Progress bar animation also has a js code
@keyframes animate-positive {
    0% {
        width: 0;
    }
}

.spin {
    animation: spin 15s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*img-animation**********************/
.img-custom-anim-right {
    animation: img-anim-right 1.3s forwards cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;
    opacity: 0;
}

@keyframes img-anim-right {
    0% {
        transform: translateX(5%);
        clip-path: inset(0 0 0 100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        clip-path: inset(0 0 0 0);
        opacity: 1;
    }
}

.img-custom-anim-left {
    animation: img-anim-left 1.3s forwards cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;
    opacity: 0;
}

@keyframes img-anim-left {
    0% {
        transform: translateX(-5%);
        clip-path: inset(0 100% 0 0);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        clip-path: inset(0 0 0 0);
        opacity: 1;
    }
}

.img-custom-anim-top {
    animation: img-anim-top 1.3s forwards cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 0;
}

@keyframes img-anim-top {
    0% {
        transform: translateY(-5%);
        clip-path: inset(0 0 100% 0);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        clip-path: inset(0 0 0 0);
        opacity: 1;
    }
}

@media (max-width: 767px) {
    .img-custom-anim-right,
    .img-custom-anim-left,
    .img-custom-anim-top {
        animation: none;
        opacity: 1;
    }
}

.slideinleft,
.slideinright,
.slideindown,
.slideinup {
    opacity: 0;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-duration: 1.3s;
    animation-delay: 0.3s;
    animation-name: var(--animation-name);
}

.swiper-slide-active {
    .slideinleft {
        --animation-name: slideinleft;
    }

    .slideinright {
        --animation-name: slideinright;
    }

    .slideinup {
        --animation-name: slideinup;
    }

    .slideindown {
        --animation-name: slideindown;
    }
}

@keyframes slideinleft {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideinright {
    0% {
        opacity: 0;
        transform: translateX(180px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideindown {
    0% {
        opacity: 0;
        transform: translateY(-100px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideinup {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-default .odometer-digit {
    padding-right: 4px;
}

.icon-flip {
    &:hover {
        img {
            transform: rotateY(180deg);
        }
    }
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
    /* Độ mờ của background khi popup mở */
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
    opacity: 0;
}
