/*=============================
	Typography css start
===============================*/
body {
    font-family: var(--tc-body-font-family);
    font-size: var(--tc-body-font-size);
    font-weight: var(--tc-fw-regular);
    color: var(--tc-body-text);
    line-height: var(--tc-body-line-height);
}
::selection {
    background-color: var(--tc-theme-primary);
    color: var(--tc-system-white);
}
::-moz-selection {
    background-color: var(--tc-theme-primary);
    color: var(--tc-system-white);
}

a,
button {
    font-family: var(--tc-btn-font-family);
    color: var(--tc-system-black);
    outline: none;
    text-decoration: none;
    transition: 0.3s;
}

button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
    outline: 0 !important;
    box-shadow: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--tc-heading-font-family);
    color: var(--tc-system-black);
    margin-top: 0px;
    font-weight: var(--tc-fw-semi-bold);
    line-height: var(--tc-heading-line-height);
    text-transform: unset;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit;
}
h1 {
    font-size: 64px;
}
h2 {
    font-size: 48px;
}
h3 {
    font-size: 40px;
}
h4 {
    font-size: 36px;
}
h5 {
    font-size: 32px;
}
h6 {
    font-size: 24px;
}

p {
    font-family: var(--tc-body-font-family);
    font-size: var(--tc-body-font-size);
    line-height: var(--tc-body-line-height);
    font-weight: var(--tc-fw-regular);
    color: var(--tc-body-text);
    margin-bottom: 15px;
}
hr {
    border-bottom: 1px solid var(--tc-color-gray-1);
    border-top: 0 none;
    margin: 30px 0;
    padding: 0;
}
label {
    color: var(--tc-heading-color);
    cursor: pointer;
    font-size: var(--tc-body-font-size);
    font-weight: var(--tc-fw-regular);
}
input[type='color'] {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    padding: 0;
    border-radius: 50%;
}
/*=============================
    - Input Placeholder
===============================*/
input,
textarea {
    color: var(--tc-body-text);
}
*::-moz-placeholder {
    color: var(--tc-body-text);
    font-size: var(--tc-fs-7);
    opacity: 1;
}
*::placeholder {
    color: var(--tc-body-text);
    font-size: var(--tc-fs-7);
    opacity: 1;
}
.list-wrap {
    margin: 0px;
    padding: 0px;
    & li {
        list-style: none;
    }
}
img,
.img {
    max-width: 100%;
    transition: 0.3s;
}
a:focus,
.btn:focus,
.button:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
}
a:hover {
    color: var(--tc-theme-primary) !important;
    text-decoration: none;
}
.btn:hover {
    color: var(--bs-btn-hover-color) !important;
    background: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
}
.fs-10 {
    font-size: 10px;
}
.fs-18 {
    font-size: 18px;
}
.fs-20 {
    font-size: 20px;
}
.fs-22 {
    font-size: 22px;
}
.fs-28 {
    font-size: 28px;
}
.fs-60 {
    font-size: 60px;
}
.fs-120 {
    font-size: 120px;
}
.fs-128 {
    font-size: 128px;
}
.fs-140 {
    font-size: 140px;
}
.fs-160 {
    font-size: 160px;
}
.fs-170 {
    font-size: 170px;
}
.fs-200 {
    font-size: 200px;
}
.ds-60 {
    font-size: 400px;
}
.fs-64 {
    font-size: 64px;
}
.fs-68 {
    font-size: 68px;
}
.fs-70 {
    font-size: 70px;
}
.fs-86 {
    font-size: 86px;
}
.fs-96 {
    font-size: 96px;
}
.fs-100 {
    font-size: 100px;
}
.fs-230 {
    font-size: 230px;
}
.fs-250 {
    font-size: 250px;
}
.fs-30 {
    font-size: 30px;
}
.fs-36 {
    font-size: 36px !important;
}
.ds-6 {
    font-size: 40px;
}
.fs-42 {
    font-size: 42px;
}
.fs-1 {
    font-size: var(--tc-fs-1) !important;
}
.fs-2 {
    font-size: var(--tc-fs-2) !important;
}
.fs-3 {
    font-size: var(--tc-fs-3) !important;
}
.fs-4 {
    font-size: var(--tc-fs-4) !important;
}
.fs-5 {
    font-size: var(--tc-fs-5) !important;
}
.fs-6 {
    font-size: var(--tc-fs-6) !important;
}
.fs-7 {
    font-size: var(--tc-fs-7) !important;
}
.fs-8 {
    font-size: var(--tc-fs-8) !important;
}
.ds-1 {
    font-size: var(--tc-ds-1) !important;
}
.ds-2 {
    font-size: var(--tc-ds-2) !important;
}
.ds-3 {
    font-size: var(--tc-ds-3) !important;
}
.ds-4 {
    font-size: var(--tc-ds-4) !important;
}
.ds-5 {
    font-size: var(--tc-ds-5) !important;
}
.ds-6 {
    font-size: var(--tc-ds-6) !important;
}
.ds-xs-1 {
    font-size: var(--tc-ds-xs-1) !important;
}
.ds-xs-2 {
    font-size: var(--tc-ds-xs-2) !important;
}
.ds-xs-3 {
    font-size: var(--tc-ds-xs-3) !important;
}
.ds-xs-4 {
    font-size: var(--tc-ds-xs-4) !important;
}
.ds-xs-5 {
    font-size: var(--tc-ds-xs-5) !important;
}
.ds-xs-6 {
    font-size: var(--tc-ds-xs-6) !important;
}
// Font Weight
.fw-light {
    font-weight: var(--tc-fw-light) !important;
}
.fw-regular {
    font-weight: var(--tc-fw-regular) !important;
}
.fw-medium {
    font-weight: var(--tc-fw-medium) !important;
}
.fw-semi-bold {
    font-weight: var(--tc-fw-semi-bold) !important;
}
.fw-bold {
    font-weight: var(--tc-fw-bold) !important;
}
.fw-extra-bold {
    font-weight: var(--tc-fw-extra-bold) !important;
}
.fw-black {
    font-weight: var(--tc-fw-black) !important;
}
.size-14 {
    width: 14px;
    height: 14px;
}
.shadow-1 {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}
.text-primary {
    color: var(--tc-theme-primary) !important;
}
.text-light {
    color: var(--tc-bg-light) !important;
}
.z-5 {
    z-index: 5 !important;
}
/*=============================
    - Common Classes
===============================*/
