.pagination_item {
    background: var(--tc-bg-light);
    color: var(--tc-system-black);
    border-radius: 8px;
    font-size: 20px;
    font-weight: 600;
    svg {
        path {
            stroke: var(--tc-system-black) !important;
            transition: 0.3s ease-in-out;
        }
    }
    &.active {
        background: var(--tc-theme-primary) !important;
        color: var(--tc-bg-light) !important;
        transition: 0.3s ease-in-out;
        svg {
            path {
                stroke: var(--tc-bg-light) !important;
                transition: 0.3s ease-in-out;
            }
        }
    }
    &:hover {
        background: var(--tc-theme-primary) !important;
        color: var(--tc-bg-light) !important;
        transition: 0.3s ease-in-out;
        svg {
            path {
                stroke: var(--tc-bg-light) !important;
                transition: 0.3s ease-in-out;
            }
        }
    }
    &:focus {
        box-shadow: none;
        background-color: var(--tc-theme-primary) !important;
        color: var(--tc-bg-light) !important;
        transition: 0.3s ease-in-out;
        svg {
            path {
                stroke: var(--tc-bg-light) !important;
                transition: 0.3s ease-in-out;
            }
        }
    }
}
