.top-bar {
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.circle-text {
    position: absolute;
    margin-bottom: 0;
    font-size: 15px;
    text-transform: uppercase;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    span {
        position: absolute;
        top: -74px;
        display: inline-block;
        transform-origin: 0 74px;
    }
}
label.text-sm-medium {
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    color: var(--bs-system-black);
    margin-bottom: 20px;
    text-transform: uppercase;
}
input.username {
    background-image: url(../imgs/template/icons/user.svg);
    background-position: left 18px center;
    background-repeat: no-repeat;
    padding-left: 45px;

    &:focus {
        background-image: url(../imgs/template/icons/user.svg);
        background-position: left 18px center;
        background-repeat: no-repeat;
        padding-left: 45px;
    }
}
input.username-2 {
    background-image: url(../imgs/template/icons/user-yellow.svg);
    background-position: left 18px center;
    background-repeat: no-repeat;
    padding-left: 45px;

    &:focus {
        background-image: url(../imgs/template/icons/user-yellow.svg);
        background-position: left 18px center;
        background-repeat: no-repeat;
        padding-left: 45px;
    }
}
input.username-white {
    background-image: url(../imgs/template/icons/user-w.svg);
    background-position: left 18px center;
    background-repeat: no-repeat;
    padding-left: 45px;

    &:focus {
        background-image: url(../imgs/template/icons/user-w.svg);
        background-position: left 18px center;
        background-repeat: no-repeat;
        padding-left: 45px;
    }
}
input.phone {
    background-image: url(../imgs/template/icons/phone.svg);
    background-position: left 18px center;
    background-repeat: no-repeat;
    padding-left: 45px;

    &:focus {
        background-image: url(../imgs/template/icons/phone.svg);
        background-position: left 18px center;
        background-repeat: no-repeat;
        padding-left: 45px;
    }
}
input.phone-white {
    background-image: url(../imgs/template/icons/phone-white.svg);
    background-position: left 18px center;
    background-repeat: no-repeat;
    padding-left: 45px;

    &:focus {
        background-image: url(../imgs/template/icons/phone-white.svg);
        background-position: left 18px center;
        background-repeat: no-repeat;
        padding-left: 45px;
    }
}
input.email {
    background-image: url(../imgs/template/icons/email.svg);
    background-position: left 18px center;
    background-repeat: no-repeat;
    padding-left: 45px;

    &:focus {
        background-image: url(../imgs/template/icons/email.svg);
        background-position: left 18px center;
        background-repeat: no-repeat;
        padding-left: 45px;
    }
}
input.email-2 {
    background-image: url(../imgs/template/icons/email-yellow.svg);
    background-position: left 18px center;
    background-repeat: no-repeat;
    padding-left: 45px;

    &:focus {
        background-image: url(../imgs/template/icons/email-yellow.svg);
        background-position: left 18px center;
        background-repeat: no-repeat;
        padding-left: 45px;
    }
}
input.email-3 {
    background-image: url(../imgs/template/icons/email-green.svg);
    background-position: left 18px center;
    background-repeat: no-repeat;
    padding-left: 45px;

    &:focus {
        background-image: url(../imgs/template/icons/email-green.svg);
        background-position: left 18px center;
        background-repeat: no-repeat;
        padding-left: 45px;
    }
}
input.email-white {
    background-color: #00282e;
    background-image: url(../imgs/template/icons/email-white.svg);
    background-position: left 18px center;
    background-repeat: no-repeat;
    padding-left: 45px;

    &:focus {
        background-image: url(../imgs/template/icons/email-white.svg);
        background-position: left 18px center;
        background-repeat: no-repeat;
        padding-left: 45px;
        color: #fff !important;
    }

    color: #fff !important;
}
input.generate {
    background-image: url(../imgs/template/icons/message.svg);
    background-position: left 18px center;
    background-repeat: no-repeat;
    padding-left: 45px;

    &:focus {
        background-image: url(../imgs/template/icons/message.svg);
        background-position: left 18px center;
        background-repeat: no-repeat;
        padding-left: 45px;
    }
}
input.search {
    background-image: url(../imgs/template/icons/message.svg);
    background-position: left 18px center;
    background-repeat: no-repeat;
    padding-left: 45px;

    &:focus {
        background-image: url(../imgs/template/icons/message.svg);
        background-position: left 18px center;
        background-repeat: no-repeat;
        padding-left: 45px;
    }
}
input.search-2 {
    background-image: url(../imgs/template/icons/search.svg);
    background-position: left 18px center;
    background-repeat: no-repeat;
    padding-left: 45px;

    &:focus {
        background-image: url(../imgs/template/icons/search.svg);
        background-position: left 18px center;
        background-repeat: no-repeat;
        padding-left: 45px;
    }
}
input.password {
    background-image: url(../imgs/template/popup/pass.svg);
    background-position: left 18px center;
    background-repeat: no-repeat;
    padding-left: 45px;

    &:focus {
        background-image: url(../imgs/template/popup/pass.svg);
        background-position: left 18px center;
        background-repeat: no-repeat;
        padding-left: 45px;
    }
}
.form-control {
    &::placeholder {
        color: var(--tc-body-text-secondary);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
    }
}
textarea {
    background-image: url(../imgs/template/icons/message.svg);
    background-position: left 18px top;
    background-position-y: top 18px;
    background-repeat: no-repeat;
    padding-left: 45px !important;

    &:focus {
        background-image: url(../imgs/template/icons/message.svg);
        background-position: left 18px top;
        background-position-y: top 18px;
        background-repeat: no-repeat;
        padding-left: 45px;
        box-shadow: none;
        border: 1px solid var(--tc-theme-primary);
    }
}
textarea {
    &.message {
        background-image: none;

        &:focus {
            background-image: none;
            background-position: left 18px top;
            background-position-y: top 18px;
            background-repeat: no-repeat;
        }

        &:focus {
            box-shadow: none;
            border: 1px solid var(--tc-theme-primary);
        }
    }
}
.rotate-90 {
    transform: rotate(-90deg);
}
.socials-group {
    margin-right: -84px;
    .socials {
        .icon {
            svg {
                height: 14px;
                width: 14px;
                fill: var(--tc-body-text-secondary);
            }
            &:hover {
                svg {
                    fill: var(--tc-system-white);
                    transition-duration: 0.3s;
                }
            }
        }
    }
}
.opacity-10 {
    opacity: 0.1;
}
.w-20 {
    width: 20%;
}
.custom-cursor-cover {
    position: fixed;
    z-index: 9999999;
    pointer-events: none;

    span.dot {
        position: fixed;
        width: 0.5em;
        height: 0.5em;
        border-radius: 50%;
        background-color: #707070;
        pointer-events: none;
        mix-blend-mode: difference;
    }
}
.follower {
    pointer-events: none;
    position: fixed;
    transition: all 0.09s ease;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    background-color: transparent;
    mix-blend-mode: difference;
    border: 1px solid #707070;
    z-index: 9999999;
}
.typewrite > .wrap {
    /* Blinking cursor */
    padding-right: 5px;
}
/* Cursor blinking animation */
@keyframes blink {
    50% {
        opacity: 0;
    }
}
.typewrite > .wrap::after {
    content: '|';
    /* Cursor */
    animation: blink 0.7s infinite;
}

.parallax-item-wrap {
    perspective: 1000px;
}
