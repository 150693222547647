// $variable-prefix: tc- !default;
$prefix: tc- !default;
@media (min-width: 576px) {
    .border-sm {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-sm-0 {
        border: 0 !important;
    }
    .border-top-sm {
        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-top-sm-0 {
        border-top: 0 !important;
    }
    .border-end-sm {
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-end-sm-0 {
        border-right: 0 !important;
    }
    .border-bottom-sm {
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-bottom-sm-0 {
        border-bottom: 0 !important;
    }
    .border-start-sm {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-start-sm-0 {
        border-left: 0 !important;
    }
    .border-sm-primary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
    }
    .border-sm-secondary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
    }
    .border-sm-success {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
    }
    .border-sm-info {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
    }
    .border-sm-warning {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
    }
    .border-sm-danger {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
    }
    .border-sm-light {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
    }
    .border-sm-dark {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
    }
    .border-sm-white {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
    }
    .border-sm-black {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
    }
    .rounded-top-sm {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-top-sm-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }
    .rounded-top-sm-1 {
        border-top-left-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-top-sm-2 {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-top-sm-3 {
        border-top-left-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-top-sm-4 {
        border-top-left-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-top-sm-5 {
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-top-sm-circle {
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important;
    }
    .rounded-top-sm-pill {
        border-top-left-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-end-sm {
        border-top-right-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-end-sm-0 {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    .rounded-end-sm-1 {
        border-top-right-radius: var(--bs-border-radius-sm) !important;
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-end-sm-2 {
        border-top-right-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-end-sm-3 {
        border-top-right-radius: var(--bs-border-radius-lg) !important;
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-end-sm-4 {
        border-top-right-radius: var(--bs-border-radius-xl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-end-sm-5 {
        border-top-right-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-end-sm-circle {
        border-top-right-radius: 50% !important;
        border-bottom-right-radius: 50% !important;
    }
    .rounded-end-sm-pill {
        border-top-right-radius: var(--bs-border-radius-pill) !important;
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-bottom-sm {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-bottom-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-bottom-sm-0 {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    .rounded-bottom-sm-1 {
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-bottom-sm-2 {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-bottom-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-bottom-sm-3 {
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-bottom-sm-4 {
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-bottom-sm-5 {
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-bottom-sm-circle {
        border-bottom-right-radius: 50% !important;
        border-bottom-left-radius: 50% !important;
    }
    .rounded-bottom-sm-pill {
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-start-sm {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-start-sm-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }
    .rounded-start-sm-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-top-left-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-start-sm-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-start-sm-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-top-left-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-start-sm-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-top-left-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-start-sm-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-start-sm-circle {
        border-bottom-left-radius: 50% !important;
        border-top-left-radius: 50% !important;
    }
    .rounded-start-sm-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-top-left-radius: var(--bs-border-radius-pill) !important;
    }
    .w-sm-25 {
        width: 25% !important;
    }
    .w-sm-50 {
        width: 50% !important;
    }
    .w-sm-75 {
        width: 75% !important;
    }
    .w-sm-100 {
        width: 100% !important;
    }
    .w-sm-auto {
        width: auto !important;
    }
    .mw-sm-100 {
        max-width: 100% !important;
    }
    .vw-sm-100 {
        width: 100vw !important;
    }
    .min-vw-sm-100 {
        min-width: 100vw !important;
    }
    .h-sm-25 {
        height: 25% !important;
    }
    .h-sm-50 {
        height: 50% !important;
    }
    .h-sm-75 {
        height: 75% !important;
    }
    .h-sm-100 {
        height: 100% !important;
    }
    .h-sm-auto {
        height: auto !important;
    }
    .mh-sm-100 {
        max-height: 100% !important;
    }
    .min-vh-sm-100 {
        min-height: 100vh !important;
    }
    .position-sm-static {
        position: static !important;
    }
    .position-sm-relative {
        position: relative !important;
    }
    .position-sm-absolute {
        position: absolute !important;
    }
    .position-sm-fixed {
        position: fixed !important;
    }
    .position-sm-sticky {
        position: sticky !important;
    }
    .top-sm-0 {
        top: 0 !important;
    }
    .top-sm-50 {
        top: 50% !important;
    }
    .top-sm-100 {
        top: 100% !important;
    }
    .bottom-sm-0 {
        bottom: 0 !important;
    }
    .bottom-sm-50 {
        bottom: 50% !important;
    }
    .bottom-sm-100 {
        bottom: 100% !important;
    }
    .start-sm-0 {
        left: 0 !important;
    }
    .start-sm-50 {
        left: 50% !important;
    }
    .start-sm-100 {
        left: 100% !important;
    }
    .translate-middle-sm {
        transform: translate(-50%, -50%) !important;
    }
    .translate-middle-sm-x {
        transform: translateX(-50%) !important;
    }
    .translate-middle-sm-y {
        transform: translateY(-50%) !important;
    }
}
@media (min-width: 768px) {
    .border-md {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-md-0 {
        border: 0 !important;
    }
    .border-top-md {
        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-top-md-0 {
        border-top: 0 !important;
    }
    .border-end-md {
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-end-md-0 {
        border-right: 0 !important;
    }
    .border-bottom-md {
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-bottom-md-0 {
        border-bottom: 0 !important;
    }
    .border-start-md {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-start-md-0 {
        border-left: 0 !important;
    }
    .border-md-primary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
    }
    .border-md-secondary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
    }
    .border-md-success {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
    }
    .border-md-info {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
    }
    .border-md-warning {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
    }
    .border-md-danger {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
    }
    .border-md-light {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
    }
    .border-md-dark {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
    }
    .border-md-white {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
    }
    .border-md-black {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
    }
    .rounded-top-md {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-top-md-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }
    .rounded-top-md-1 {
        border-top-left-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-top-md-2 {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-top-md-3 {
        border-top-left-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-top-md-4 {
        border-top-left-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-top-md-5 {
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-top-md-circle {
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important;
    }
    .rounded-top-md-pill {
        border-top-left-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-end-md {
        border-top-right-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-end-md-0 {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    .rounded-end-md-1 {
        border-top-right-radius: var(--bs-border-radius-sm) !important;
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-end-md-2 {
        border-top-right-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-end-md-3 {
        border-top-right-radius: var(--bs-border-radius-lg) !important;
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-end-md-4 {
        border-top-right-radius: var(--bs-border-radius-xl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-end-md-5 {
        border-top-right-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-end-md-circle {
        border-top-right-radius: 50% !important;
        border-bottom-right-radius: 50% !important;
    }
    .rounded-end-md-pill {
        border-top-right-radius: var(--bs-border-radius-pill) !important;
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-bottom-md {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-bottom-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-bottom-md-0 {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    .rounded-bottom-md-1 {
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-bottom-md-2 {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-bottom-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-bottom-md-3 {
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-bottom-md-4 {
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-bottom-md-5 {
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-bottom-md-circle {
        border-bottom-right-radius: 50% !important;
        border-bottom-left-radius: 50% !important;
    }
    .rounded-bottom-md-pill {
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-start-md {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-start-md-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }
    .rounded-start-md-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-top-left-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-start-md-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-start-md-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-top-left-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-start-md-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-top-left-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-start-md-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-start-md-circle {
        border-bottom-left-radius: 50% !important;
        border-top-left-radius: 50% !important;
    }
    .rounded-start-md-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-top-left-radius: var(--bs-border-radius-pill) !important;
    }
    .w-md-25 {
        width: 25% !important;
    }
    .w-md-50 {
        width: 50% !important;
    }
    .w-md-75 {
        width: 75% !important;
    }
    .w-md-100 {
        width: 100% !important;
    }
    .w-md-auto {
        width: auto !important;
    }
    .mw-md-100 {
        max-width: 100% !important;
    }
    .vw-md-100 {
        width: 100vw !important;
    }
    .min-vw-md-100 {
        min-width: 100vw !important;
    }
    .h-md-25 {
        height: 25% !important;
    }
    .h-md-50 {
        height: 50% !important;
    }
    .h-md-75 {
        height: 75% !important;
    }
    .h-md-100 {
        height: 100% !important;
    }
    .h-md-auto {
        height: auto !important;
    }
    .mh-md-100 {
        max-height: 100% !important;
    }
    .min-vh-md-100 {
        min-height: 100vh !important;
    }
    .position-md-static {
        position: static !important;
    }
    .position-md-relative {
        position: relative !important;
    }
    .position-md-absolute {
        position: absolute !important;
    }
    .position-md-fixed {
        position: fixed !important;
    }
    .position-md-sticky {
        position: sticky !important;
    }
    .top-md-0 {
        top: 0 !important;
    }
    .top-md-50 {
        top: 50% !important;
    }
    .top-md-100 {
        top: 100% !important;
    }
    .bottom-md-0 {
        bottom: 0 !important;
    }
    .bottom-md-50 {
        bottom: 50% !important;
    }
    .bottom-md-100 {
        bottom: 100% !important;
    }
    .start-md-0 {
        left: 0 !important;
    }
    .start-md-50 {
        left: 50% !important;
    }
    .start-md-100 {
        left: 100% !important;
    }
    .translate-middle-md {
        transform: translate(-50%, -50%) !important;
    }
    .translate-middle-md-x {
        transform: translateX(-50%) !important;
    }
    .translate-middle-md-y {
        transform: translateY(-50%) !important;
    }
}
@media (min-width: 992px) {
    .w-lg-20 {
        width: 20%;
    }
    .border-lg {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-lg-0 {
        border: 0 !important;
    }
    .border-top-lg {
        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-top-lg-0 {
        border-top: 0 !important;
    }
    .border-end-lg {
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-end-lg-0 {
        border-right: 0 !important;
    }
    .border-bottom-lg {
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-bottom-lg-0 {
        border-bottom: 0 !important;
    }
    .border-start-lg {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-start-lg-0 {
        border-left: 0 !important;
    }
    .border-lg-primary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
    }
    .border-lg-secondary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
    }
    .border-lg-success {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
    }
    .border-lg-info {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
    }
    .border-lg-warning {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
    }
    .border-lg-danger {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
    }
    .border-lg-light {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
    }
    .border-lg-dark {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
    }
    .border-lg-white {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
    }
    .border-lg-black {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
    }
    .rounded-top-lg {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-top-lg-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }
    .rounded-top-lg-1 {
        border-top-left-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-top-lg-2 {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-top-lg-3 {
        border-top-left-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-top-lg-4 {
        border-top-left-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-top-lg-5 {
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-top-lg-circle {
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important;
    }
    .rounded-top-lg-pill {
        border-top-left-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-end-lg {
        border-top-right-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-end-lg-0 {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    .rounded-end-lg-1 {
        border-top-right-radius: var(--bs-border-radius-sm) !important;
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-end-lg-2 {
        border-top-right-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-end-lg-3 {
        border-top-right-radius: var(--bs-border-radius-lg) !important;
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-end-lg-4 {
        border-top-right-radius: var(--bs-border-radius-xl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-end-lg-5 {
        border-top-right-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-end-lg-circle {
        border-top-right-radius: 50% !important;
        border-bottom-right-radius: 50% !important;
    }
    .rounded-end-lg-pill {
        border-top-right-radius: var(--bs-border-radius-pill) !important;
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-bottom-lg {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-bottom-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-bottom-lg-0 {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    .rounded-bottom-lg-1 {
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-bottom-lg-2 {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-bottom-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-bottom-lg-3 {
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-bottom-lg-4 {
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-bottom-lg-5 {
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-bottom-lg-circle {
        border-bottom-right-radius: 50% !important;
        border-bottom-left-radius: 50% !important;
    }
    .rounded-bottom-lg-pill {
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-start-lg {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-start-lg-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }
    .rounded-start-lg-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-top-left-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-start-lg-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-start-lg-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-top-left-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-start-lg-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-top-left-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-start-lg-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-start-lg-circle {
        border-bottom-left-radius: 50% !important;
        border-top-left-radius: 50% !important;
    }
    .rounded-start-lg-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-top-left-radius: var(--bs-border-radius-pill) !important;
    }
    .w-lg-25 {
        width: 25% !important;
    }
    .w-lg-50 {
        width: 50% !important;
    }
    .w-lg-75 {
        width: 75% !important;
    }
    .w-lg-100 {
        width: 100% !important;
    }
    .w-lg-auto {
        width: auto !important;
    }
    .mw-lg-100 {
        max-width: 100% !important;
    }
    .vw-lg-100 {
        width: 100vw !important;
    }
    .min-vw-lg-100 {
        min-width: 100vw !important;
    }
    .h-lg-25 {
        height: 25% !important;
    }
    .h-lg-50 {
        height: 50% !important;
    }
    .h-lg-75 {
        height: 75% !important;
    }
    .h-lg-100 {
        height: 100% !important;
    }
    .h-lg-auto {
        height: auto !important;
    }
    .mh-lg-100 {
        max-height: 100% !important;
    }
    .min-vh-lg-100 {
        min-height: 100vh !important;
    }
    .position-lg-static {
        position: static !important;
    }
    .position-lg-relative {
        position: relative !important;
    }
    .position-lg-absolute {
        position: absolute !important;
    }
    .position-lg-fixed {
        position: fixed !important;
    }
    .position-lg-sticky {
        position: sticky !important;
    }
    .top-lg-0 {
        top: 0 !important;
    }
    .top-lg-50 {
        top: 50% !important;
    }
    .top-lg-100 {
        top: 100% !important;
    }
    .bottom-lg-0 {
        bottom: 0 !important;
    }
    .bottom-lg-50 {
        bottom: 50% !important;
    }
    .bottom-lg-100 {
        bottom: 100% !important;
    }
    .start-lg-0 {
        left: 0 !important;
    }
    .start-lg-50 {
        left: 50% !important;
    }
    .start-lg-100 {
        left: 100% !important;
    }
    .translate-middle-lg {
        transform: translate(-50%, -50%) !important;
    }
    .translate-middle-lg-x {
        transform: translateX(-50%) !important;
    }
    .translate-middle-lg-y {
        transform: translateY(-50%) !important;
    }
}
@media (min-width: 1200px) {
    .border-xl {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-xl-0 {
        border: 0 !important;
    }
    .border-top-xl {
        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-top-xl-0 {
        border-top: 0 !important;
    }
    .border-end-xl {
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-end-xl-0 {
        border-right: 0 !important;
    }
    .border-bottom-xl {
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-bottom-xl-0 {
        border-bottom: 0 !important;
    }
    .border-start-xl {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-start-xl-0 {
        border-left: 0 !important;
    }
    .border-xl-primary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xl-secondary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xl-success {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xl-info {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xl-warning {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xl-danger {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xl-light {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xl-dark {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xl-white {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xl-black {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
    }
    .rounded-top-xl {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-top-xl-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }
    .rounded-top-xl-1 {
        border-top-left-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-top-xl-2 {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-top-xl-3 {
        border-top-left-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-top-xl-4 {
        border-top-left-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-top-xl-5 {
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-top-xl-circle {
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important;
    }
    .rounded-top-xl-pill {
        border-top-left-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-end-xl {
        border-top-right-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-end-xl-0 {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    .rounded-end-xl-1 {
        border-top-right-radius: var(--bs-border-radius-sm) !important;
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-end-xl-2 {
        border-top-right-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-end-xl-3 {
        border-top-right-radius: var(--bs-border-radius-lg) !important;
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-end-xl-4 {
        border-top-right-radius: var(--bs-border-radius-xl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-end-xl-5 {
        border-top-right-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-end-xl-circle {
        border-top-right-radius: 50% !important;
        border-bottom-right-radius: 50% !important;
    }
    .rounded-end-xl-pill {
        border-top-right-radius: var(--bs-border-radius-pill) !important;
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-bottom-xl {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-bottom-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-bottom-xl-0 {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    .rounded-bottom-xl-1 {
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-bottom-xl-2 {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-bottom-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-bottom-xl-3 {
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-bottom-xl-4 {
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-bottom-xl-5 {
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-bottom-xl-circle {
        border-bottom-right-radius: 50% !important;
        border-bottom-left-radius: 50% !important;
    }
    .rounded-bottom-xl-pill {
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-start-xl {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-start-xl-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }
    .rounded-start-xl-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-top-left-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-start-xl-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-start-xl-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-top-left-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-start-xl-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-top-left-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-start-xl-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-start-xl-circle {
        border-bottom-left-radius: 50% !important;
        border-top-left-radius: 50% !important;
    }
    .rounded-start-xl-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-top-left-radius: var(--bs-border-radius-pill) !important;
    }
    .w-xl-25 {
        width: 25% !important;
    }
    .w-xl-50 {
        width: 50% !important;
    }
    .w-xl-75 {
        width: 75% !important;
    }
    .w-xl-100 {
        width: 100% !important;
    }
    .w-xl-auto {
        width: auto !important;
    }
    .mw-xl-100 {
        max-width: 100% !important;
    }
    .vw-xl-100 {
        width: 100vw !important;
    }
    .min-vw-xl-100 {
        min-width: 100vw !important;
    }
    .h-xl-25 {
        height: 25% !important;
    }
    .h-xl-50 {
        height: 50% !important;
    }
    .h-xl-75 {
        height: 75% !important;
    }
    .h-xl-100 {
        height: 100% !important;
    }
    .h-xl-auto {
        height: auto !important;
    }
    .mh-xl-100 {
        max-height: 100% !important;
    }
    .min-vh-xl-100 {
        min-height: 100vh !important;
    }
    .position-xl-static {
        position: static !important;
    }
    .position-xl-relative {
        position: relative !important;
    }
    .position-xl-absolute {
        position: absolute !important;
    }
    .position-xl-fixed {
        position: fixed !important;
    }
    .position-xl-sticky {
        position: sticky !important;
    }
    .top-xl-0 {
        top: 0 !important;
    }
    .top-xl-50 {
        top: 50% !important;
    }
    .top-xl-100 {
        top: 100% !important;
    }
    .bottom-xl-0 {
        bottom: 0 !important;
    }
    .bottom-xl-50 {
        bottom: 50% !important;
    }
    .bottom-xl-100 {
        bottom: 100% !important;
    }
    .start-xl-0 {
        left: 0 !important;
    }
    .start-xl-50 {
        left: 50% !important;
    }
    .start-xl-100 {
        left: 100% !important;
    }
    .translate-middle-xl {
        transform: translate(-50%, -50%) !important;
    }
    .translate-middle-xl-x {
        transform: translateX(-50%) !important;
    }
    .translate-middle-xl-y {
        transform: translateY(-50%) !important;
    }
}
@media (min-width: 1400px) {
    .border-xxl {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-xxl-0 {
        border: 0 !important;
    }
    .border-top-xxl {
        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-top-xxl-0 {
        border-top: 0 !important;
    }
    .border-end-xxl {
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-end-xxl-0 {
        border-right: 0 !important;
    }
    .border-bottom-xxl {
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-bottom-xxl-0 {
        border-bottom: 0 !important;
    }
    .border-start-xxl {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-start-xxl-0 {
        border-left: 0 !important;
    }
    .border-xxl-primary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xxl-secondary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xxl-success {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xxl-info {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xxl-warning {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xxl-danger {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xxl-light {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xxl-dark {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xxl-white {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xxl-black {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
    }
    .rounded-top-xxl {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-top-xxl-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }
    .rounded-top-xxl-1 {
        border-top-left-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-top-xxl-2 {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-top-xxl-3 {
        border-top-left-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-top-xxl-4 {
        border-top-left-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-top-xxl-5 {
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-top-xxl-circle {
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important;
    }
    .rounded-top-xxl-pill {
        border-top-left-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-end-xxl {
        border-top-right-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-end-xxl-0 {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    .rounded-end-xxl-1 {
        border-top-right-radius: var(--bs-border-radius-sm) !important;
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-end-xxl-2 {
        border-top-right-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-end-xxl-3 {
        border-top-right-radius: var(--bs-border-radius-lg) !important;
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-end-xxl-4 {
        border-top-right-radius: var(--bs-border-radius-xl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-end-xxl-5 {
        border-top-right-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-end-xxl-circle {
        border-top-right-radius: 50% !important;
        border-bottom-right-radius: 50% !important;
    }
    .rounded-end-xxl-pill {
        border-top-right-radius: var(--bs-border-radius-pill) !important;
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-bottom-xxl {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-bottom-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-bottom-xxl-0 {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    .rounded-bottom-xxl-1 {
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-bottom-xxl-2 {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-bottom-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-bottom-xxl-3 {
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-bottom-xxl-4 {
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-bottom-xxl-5 {
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-bottom-xxl-circle {
        border-bottom-right-radius: 50% !important;
        border-bottom-left-radius: 50% !important;
    }
    .rounded-bottom-xxl-pill {
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-start-xxl {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-start-xxl-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }
    .rounded-start-xxl-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-top-left-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-start-xxl-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-start-xxl-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-top-left-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-start-xxl-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-top-left-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-start-xxl-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-start-xxl-circle {
        border-bottom-left-radius: 50% !important;
        border-top-left-radius: 50% !important;
    }
    .rounded-start-xxl-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-top-left-radius: var(--bs-border-radius-pill) !important;
    }
    .w-xxl-25 {
        width: 25% !important;
    }
    .w-xxl-50 {
        width: 50% !important;
    }
    .w-xxl-75 {
        width: 75% !important;
    }
    .w-xxl-100 {
        width: 100% !important;
    }
    .w-xxl-auto {
        width: auto !important;
    }
    .mw-xxl-100 {
        max-width: 100% !important;
    }
    .vw-xxl-100 {
        width: 100vw !important;
    }
    .min-vw-xxl-100 {
        min-width: 100vw !important;
    }
    .h-xxl-25 {
        height: 25% !important;
    }
    .h-xxl-50 {
        height: 50% !important;
    }
    .h-xxl-75 {
        height: 75% !important;
    }
    .h-xxl-100 {
        height: 100% !important;
    }
    .h-xxl-auto {
        height: auto !important;
    }
    .mh-xxl-100 {
        max-height: 100% !important;
    }
    .min-vh-xxl-100 {
        min-height: 100vh !important;
    }
    .position-xxl-static {
        position: static !important;
    }
    .position-xxl-relative {
        position: relative !important;
    }
    .position-xxl-absolute {
        position: absolute !important;
    }
    .position-xxl-fixed {
        position: fixed !important;
    }
    .position-xxl-sticky {
        position: sticky !important;
    }
    .top-xxl-0 {
        top: 0 !important;
    }
    .top-xxl-50 {
        top: 50% !important;
    }
    .top-xxl-100 {
        top: 100% !important;
    }
    .bottom-xxl-0 {
        bottom: 0 !important;
    }
    .bottom-xxl-50 {
        bottom: 50% !important;
    }
    .bottom-xxl-100 {
        bottom: 100% !important;
    }
    .start-xxl-0 {
        left: 0 !important;
    }
    .start-xxl-50 {
        left: 50% !important;
    }
    .start-xxl-100 {
        left: 100% !important;
    }
    .translate-middle-xxl {
        transform: translate(-50%, -50%) !important;
    }
    .translate-middle-xxl-x {
        transform: translateX(-50%) !important;
    }
    .translate-middle-xxl-y {
        transform: translateY(-50%) !important;
    }
}
@media (min-width: 576px) {
    .float-sm-start {
        float: left !important;
    }
    .float-sm-end {
        float: right !important;
    }
    .float-sm-none {
        float: none !important;
    }
    .object-fit-sm-contain {
        -o-object-fit: contain !important;
        object-fit: contain !important;
    }
    .object-fit-sm-cover {
        -o-object-fit: cover !important;
        object-fit: cover !important;
    }
    .object-fit-sm-fill {
        -o-object-fit: fill !important;
        object-fit: fill !important;
    }
    .object-fit-sm-scale {
        -o-object-fit: scale-down !important;
        object-fit: scale-down !important;
    }
    .object-fit-sm-none {
        -o-object-fit: none !important;
        object-fit: none !important;
    }
    .d-sm-inline {
        display: inline !important;
    }
    .d-sm-inline-block {
        display: inline-block !important;
    }
    .d-sm-block {
        display: block !important;
    }
    .d-sm-grid {
        display: grid !important;
    }
    .d-sm-inline-grid {
        display: inline-grid !important;
    }
    .d-sm-table {
        display: table !important;
    }
    .d-sm-table-row {
        display: table-row !important;
    }
    .d-sm-table-cell {
        display: table-cell !important;
    }
    .d-sm-flex {
        display: flex !important;
    }
    .d-sm-inline-flex {
        display: inline-flex !important;
    }
    .d-sm-none {
        display: none !important;
    }
    .position-sm-static {
        position: static !important;
    }
    .position-sm-relative {
        position: relative !important;
    }
    .position-sm-absolute {
        position: absolute !important;
    }
    .position-sm-fixed {
        position: fixed !important;
    }
    .position-sm-sticky {
        position: sticky !important;
    }
    .top-sm-0 {
        top: 0 !important;
    }
    .top-sm-50 {
        top: 50% !important;
    }
    .top-sm-100 {
        top: 100% !important;
    }
    .bottom-sm-0 {
        bottom: 0 !important;
    }
    .bottom-sm-50 {
        bottom: 50% !important;
    }
    .bottom-sm-100 {
        bottom: 100% !important;
    }
    .start-sm-0 {
        left: 0 !important;
    }
    .start-sm-50 {
        left: 50% !important;
    }
    .start-sm-100 {
        left: 100% !important;
    }
    .translate-middle-sm {
        transform: translate(-50%, -50%) !important;
    }
    .translate-middle-sm-x {
        transform: translateX(-50%) !important;
    }
    .translate-middle-sm-y {
        transform: translateY(-50%) !important;
    }
    .border-sm {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-sm-0 {
        border: 0 !important;
    }
    .border-top-sm {
        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-top-sm-0 {
        border-top: 0 !important;
    }
    .border-end-sm {
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-end-sm-0 {
        border-right: 0 !important;
    }
    .border-bottom-sm {
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-bottom-sm-0 {
        border-bottom: 0 !important;
    }
    .border-start-sm {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-start-sm-0 {
        border-left: 0 !important;
    }
    .border-sm-primary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
    }
    .border-sm-secondary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
    }
    .border-sm-success {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
    }
    .border-sm-info {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
    }
    .border-sm-warning {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
    }
    .border-sm-danger {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
    }
    .border-sm-light {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
    }
    .border-sm-dark {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
    }
    .border-sm-white {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
    }
    .border-sm-black {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
    }
    .w-sm-25 {
        width: 25% !important;
    }
    .w-sm-50 {
        width: 50% !important;
    }
    .w-sm-75 {
        width: 75% !important;
    }
    .w-sm-100 {
        width: 100% !important;
    }
    .w-sm-auto {
        width: auto !important;
    }
    .mw-sm-100 {
        max-width: 100% !important;
    }
    .vw-sm-100 {
        width: 100vw !important;
    }
    .min-vw-sm-100 {
        min-width: 100vw !important;
    }
    .h-sm-25 {
        height: 25% !important;
    }
    .h-sm-50 {
        height: 50% !important;
    }
    .h-sm-75 {
        height: 75% !important;
    }
    .h-sm-100 {
        height: 100% !important;
    }
    .h-sm-auto {
        height: auto !important;
    }
    .mh-sm-100 {
        max-height: 100% !important;
    }
    .min-vh-sm-100 {
        min-height: 100vh !important;
    }
    .flex-sm-fill {
        flex: 1 1 auto !important;
    }
    .flex-sm-row {
        flex-direction: row !important;
    }
    .flex-sm-column {
        flex-direction: column !important;
    }
    .flex-sm-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-sm-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-sm-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-sm-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1 {
        flex-shrink: 1 !important;
    }
    .flex-sm-wrap {
        flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .justify-content-sm-start {
        justify-content: flex-start !important;
    }
    .justify-content-sm-end {
        justify-content: flex-end !important;
    }
    .justify-content-sm-center {
        justify-content: center !important;
    }
    .justify-content-sm-between {
        justify-content: space-between !important;
    }
    .justify-content-sm-around {
        justify-content: space-around !important;
    }
    .justify-content-sm-evenly {
        justify-content: space-evenly !important;
    }
    .align-items-sm-start {
        align-items: flex-start !important;
    }
    .align-items-sm-end {
        align-items: flex-end !important;
    }
    .align-items-sm-center {
        align-items: center !important;
    }
    .align-items-sm-baseline {
        align-items: baseline !important;
    }
    .align-items-sm-stretch {
        align-items: stretch !important;
    }
    .align-content-sm-start {
        align-content: flex-start !important;
    }
    .align-content-sm-end {
        align-content: flex-end !important;
    }
    .align-content-sm-center {
        align-content: center !important;
    }
    .align-content-sm-between {
        align-content: space-between !important;
    }
    .align-content-sm-around {
        align-content: space-around !important;
    }
    .align-content-sm-stretch {
        align-content: stretch !important;
    }
    .align-self-sm-auto {
        align-self: auto !important;
    }
    .align-self-sm-start {
        align-self: flex-start !important;
    }
    .align-self-sm-end {
        align-self: flex-end !important;
    }
    .align-self-sm-center {
        align-self: center !important;
    }
    .align-self-sm-baseline {
        align-self: baseline !important;
    }
    .align-self-sm-stretch {
        align-self: stretch !important;
    }
    .order-sm-first {
        order: -1 !important;
    }
    .order-sm-0 {
        order: 0 !important;
    }
    .order-sm-1 {
        order: 1 !important;
    }
    .order-sm-2 {
        order: 2 !important;
    }
    .order-sm-3 {
        order: 3 !important;
    }
    .order-sm-4 {
        order: 4 !important;
    }
    .order-sm-5 {
        order: 5 !important;
    }
    .order-sm-last {
        order: 6 !important;
    }
    .m-sm-0 {
        margin: 0 !important;
    }
    .m-sm-1 {
        margin: 0.25rem !important;
    }
    .m-sm-2 {
        margin: 0.5rem !important;
    }
    .m-sm-3 {
        margin: 1rem !important;
    }
    .m-sm-4 {
        margin: 1.5rem !important;
    }
    .m-sm-5 {
        margin: 2rem !important;
    }
    .m-sm-6 {
        margin: 2.5rem !important;
    }
    .m-sm-7 {
        margin: 3rem !important;
    }
    .m-sm-8 {
        margin: 4rem !important;
    }
    .m-sm-9 {
        margin: 5rem !important;
    }
    .m-sm-10 {
        margin: 6rem !important;
    }
    .m-sm-auto {
        margin: auto !important;
    }
    .mx-sm-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .mx-sm-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }
    .mx-sm-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }
    .mx-sm-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }
    .mx-sm-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }
    .mx-sm-5 {
        margin-right: 2rem !important;
        margin-left: 2rem !important;
    }
    .mx-sm-6 {
        margin-right: 2.5rem !important;
        margin-left: 2.5rem !important;
    }
    .mx-sm-7 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }
    .mx-sm-8 {
        margin-right: 4rem !important;
        margin-left: 4rem !important;
    }
    .mx-sm-9 {
        margin-right: 5rem !important;
        margin-left: 5rem !important;
    }
    .mx-sm-10 {
        margin-right: 6rem !important;
        margin-left: 6rem !important;
    }
    .mx-sm-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .my-sm-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .my-sm-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
    .my-sm-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    .my-sm-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    .my-sm-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
    .my-sm-5 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
    }
    .my-sm-6 {
        margin-top: 2.5rem !important;
        margin-bottom: 2.5rem !important;
    }
    .my-sm-7 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
    .my-sm-8 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
    }
    .my-sm-9 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important;
    }
    .my-sm-10 {
        margin-top: 6rem !important;
        margin-bottom: 6rem !important;
    }
    .my-sm-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }
    .mt-sm-0 {
        margin-top: 0 !important;
    }
    .mt-sm-1 {
        margin-top: 0.25rem !important;
    }
    .mt-sm-2 {
        margin-top: 0.5rem !important;
    }
    .mt-sm-3 {
        margin-top: 1rem !important;
    }
    .mt-sm-4 {
        margin-top: 1.5rem !important;
    }
    .mt-sm-5 {
        margin-top: 2rem !important;
    }
    .mt-sm-6 {
        margin-top: 2.5rem !important;
    }
    .mt-sm-7 {
        margin-top: 3rem !important;
    }
    .mt-sm-8 {
        margin-top: 4rem !important;
    }
    .mt-sm-9 {
        margin-top: 5rem !important;
    }
    .mt-sm-10 {
        margin-top: 6rem !important;
    }
    .mt-sm-auto {
        margin-top: auto !important;
    }
    .me-sm-0 {
        margin-right: 0 !important;
    }
    .me-sm-1 {
        margin-right: 0.25rem !important;
    }
    .me-sm-2 {
        margin-right: 0.5rem !important;
    }
    .me-sm-3 {
        margin-right: 1rem !important;
    }
    .me-sm-4 {
        margin-right: 1.5rem !important;
    }
    .me-sm-5 {
        margin-right: 2rem !important;
    }
    .me-sm-6 {
        margin-right: 2.5rem !important;
    }
    .me-sm-7 {
        margin-right: 3rem !important;
    }
    .me-sm-8 {
        margin-right: 4rem !important;
    }
    .me-sm-9 {
        margin-right: 5rem !important;
    }
    .me-sm-10 {
        margin-right: 6rem !important;
    }
    .me-sm-auto {
        margin-right: auto !important;
    }
    .mb-sm-0 {
        margin-bottom: 0 !important;
    }
    .mb-sm-1 {
        margin-bottom: 0.25rem !important;
    }
    .mb-sm-2 {
        margin-bottom: 0.5rem !important;
    }
    .mb-sm-3 {
        margin-bottom: 1rem !important;
    }
    .mb-sm-4 {
        margin-bottom: 1.5rem !important;
    }
    .mb-sm-5 {
        margin-bottom: 2rem !important;
    }
    .mb-sm-6 {
        margin-bottom: 2.5rem !important;
    }
    .mb-sm-7 {
        margin-bottom: 3rem !important;
    }
    .mb-sm-8 {
        margin-bottom: 4rem !important;
    }
    .mb-sm-9 {
        margin-bottom: 5rem !important;
    }
    .mb-sm-10 {
        margin-bottom: 6rem !important;
    }
    .mb-sm-auto {
        margin-bottom: auto !important;
    }
    .ms-sm-0 {
        margin-left: 0 !important;
    }
    .ms-sm-1 {
        margin-left: 0.25rem !important;
    }
    .ms-sm-2 {
        margin-left: 0.5rem !important;
    }
    .ms-sm-3 {
        margin-left: 1rem !important;
    }
    .ms-sm-4 {
        margin-left: 1.5rem !important;
    }
    .ms-sm-5 {
        margin-left: 2rem !important;
    }
    .ms-sm-6 {
        margin-left: 2.5rem !important;
    }
    .ms-sm-7 {
        margin-left: 3rem !important;
    }
    .ms-sm-8 {
        margin-left: 4rem !important;
    }
    .ms-sm-9 {
        margin-left: 5rem !important;
    }
    .ms-sm-10 {
        margin-left: 6rem !important;
    }
    .ms-sm-auto {
        margin-left: auto !important;
    }
    .m-sm-n1 {
        margin: -0.25rem !important;
    }
    .m-sm-n2 {
        margin: -0.5rem !important;
    }
    .m-sm-n3 {
        margin: -1rem !important;
    }
    .m-sm-n4 {
        margin: -1.5rem !important;
    }
    .m-sm-n5 {
        margin: -2rem !important;
    }
    .m-sm-n6 {
        margin: -2.5rem !important;
    }
    .m-sm-n7 {
        margin: -3rem !important;
    }
    .m-sm-n8 {
        margin: -4rem !important;
    }
    .m-sm-n9 {
        margin: -5rem !important;
    }
    .m-sm-n10 {
        margin: -6rem !important;
    }
    .mx-sm-n1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important;
    }
    .mx-sm-n2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important;
    }
    .mx-sm-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important;
    }
    .mx-sm-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important;
    }
    .mx-sm-n5 {
        margin-right: -2rem !important;
        margin-left: -2rem !important;
    }
    .mx-sm-n6 {
        margin-right: -2.5rem !important;
        margin-left: -2.5rem !important;
    }
    .mx-sm-n7 {
        margin-right: -3rem !important;
        margin-left: -3rem !important;
    }
    .mx-sm-n8 {
        margin-right: -4rem !important;
        margin-left: -4rem !important;
    }
    .mx-sm-n9 {
        margin-right: -5rem !important;
        margin-left: -5rem !important;
    }
    .mx-sm-n10 {
        margin-right: -6rem !important;
        margin-left: -6rem !important;
    }
    .my-sm-n1 {
        margin-top: -0.25rem !important;
        margin-bottom: -0.25rem !important;
    }
    .my-sm-n2 {
        margin-top: -0.5rem !important;
        margin-bottom: -0.5rem !important;
    }
    .my-sm-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important;
    }
    .my-sm-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important;
    }
    .my-sm-n5 {
        margin-top: -2rem !important;
        margin-bottom: -2rem !important;
    }
    .my-sm-n6 {
        margin-top: -2.5rem !important;
        margin-bottom: -2.5rem !important;
    }
    .my-sm-n7 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important;
    }
    .my-sm-n8 {
        margin-top: -4rem !important;
        margin-bottom: -4rem !important;
    }
    .my-sm-n9 {
        margin-top: -5rem !important;
        margin-bottom: -5rem !important;
    }
    .my-sm-n10 {
        margin-top: -6rem !important;
        margin-bottom: -6rem !important;
    }
    .mt-sm-n1 {
        margin-top: -0.25rem !important;
    }
    .mt-sm-n2 {
        margin-top: -0.5rem !important;
    }
    .mt-sm-n3 {
        margin-top: -1rem !important;
    }
    .mt-sm-n4 {
        margin-top: -1.5rem !important;
    }
    .mt-sm-n5 {
        margin-top: -2rem !important;
    }
    .mt-sm-n6 {
        margin-top: -2.5rem !important;
    }
    .mt-sm-n7 {
        margin-top: -3rem !important;
    }
    .mt-sm-n8 {
        margin-top: -4rem !important;
    }
    .mt-sm-n9 {
        margin-top: -5rem !important;
    }
    .mt-sm-n10 {
        margin-top: -6rem !important;
    }
    .me-sm-n1 {
        margin-right: -0.25rem !important;
    }
    .me-sm-n2 {
        margin-right: -0.5rem !important;
    }
    .me-sm-n3 {
        margin-right: -1rem !important;
    }
    .me-sm-n4 {
        margin-right: -1.5rem !important;
    }
    .me-sm-n5 {
        margin-right: -2rem !important;
    }
    .me-sm-n6 {
        margin-right: -2.5rem !important;
    }
    .me-sm-n7 {
        margin-right: -3rem !important;
    }
    .me-sm-n8 {
        margin-right: -4rem !important;
    }
    .me-sm-n9 {
        margin-right: -5rem !important;
    }
    .me-sm-n10 {
        margin-right: -6rem !important;
    }
    .mb-sm-n1 {
        margin-bottom: -0.25rem !important;
    }
    .mb-sm-n2 {
        margin-bottom: -0.5rem !important;
    }
    .mb-sm-n3 {
        margin-bottom: -1rem !important;
    }
    .mb-sm-n4 {
        margin-bottom: -1.5rem !important;
    }
    .mb-sm-n5 {
        margin-bottom: -2rem !important;
    }
    .mb-sm-n6 {
        margin-bottom: -2.5rem !important;
    }
    .mb-sm-n7 {
        margin-bottom: -3rem !important;
    }
    .mb-sm-n8 {
        margin-bottom: -4rem !important;
    }
    .mb-sm-n9 {
        margin-bottom: -5rem !important;
    }
    .mb-sm-n10 {
        margin-bottom: -6rem !important;
    }
    .ms-sm-n1 {
        margin-left: -0.25rem !important;
    }
    .ms-sm-n2 {
        margin-left: -0.5rem !important;
    }
    .ms-sm-n3 {
        margin-left: -1rem !important;
    }
    .ms-sm-n4 {
        margin-left: -1.5rem !important;
    }
    .ms-sm-n5 {
        margin-left: -2rem !important;
    }
    .ms-sm-n6 {
        margin-left: -2.5rem !important;
    }
    .ms-sm-n7 {
        margin-left: -3rem !important;
    }
    .ms-sm-n8 {
        margin-left: -4rem !important;
    }
    .ms-sm-n9 {
        margin-left: -5rem !important;
    }
    .ms-sm-n10 {
        margin-left: -6rem !important;
    }
    .p-sm-0 {
        padding: 0 !important;
    }
    .p-sm-1 {
        padding: 0.25rem !important;
    }
    .p-sm-2 {
        padding: 0.5rem !important;
    }
    .p-sm-3 {
        padding: 1rem !important;
    }
    .p-sm-4 {
        padding: 1.5rem !important;
    }
    .p-sm-5 {
        padding: 2rem !important;
    }
    .p-sm-6 {
        padding: 2.5rem !important;
    }
    .p-sm-7 {
        padding: 3rem !important;
    }
    .p-sm-8 {
        padding: 4rem !important;
    }
    .p-sm-9 {
        padding: 5rem !important;
    }
    .p-sm-10 {
        padding: 6rem !important;
    }
    .px-sm-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .px-sm-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }
    .px-sm-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    .px-sm-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .px-sm-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
    .px-sm-5 {
        padding-right: 2rem !important;
        padding-left: 2rem !important;
    }
    .px-sm-6 {
        padding-right: 2.5rem !important;
        padding-left: 2.5rem !important;
    }
    .px-sm-7 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }
    .px-sm-8 {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
    }
    .px-sm-9 {
        padding-right: 5rem !important;
        padding-left: 5rem !important;
    }
    .px-sm-10 {
        padding-right: 6rem !important;
        padding-left: 6rem !important;
    }
    .py-sm-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .py-sm-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }
    .py-sm-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
    .py-sm-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
    .py-sm-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
    .py-sm-5 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }
    .py-sm-6 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
    }
    .py-sm-7 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
    .py-sm-8 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }
    .py-sm-9 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }
    .py-sm-10 {
        padding-top: 6rem !important;
        padding-bottom: 6rem !important;
    }
    .pt-sm-0 {
        padding-top: 0 !important;
    }
    .pt-sm-1 {
        padding-top: 0.25rem !important;
    }
    .pt-sm-2 {
        padding-top: 0.5rem !important;
    }
    .pt-sm-3 {
        padding-top: 1rem !important;
    }
    .pt-sm-4 {
        padding-top: 1.5rem !important;
    }
    .pt-sm-5 {
        padding-top: 2rem !important;
    }
    .pt-sm-6 {
        padding-top: 2.5rem !important;
    }
    .pt-sm-7 {
        padding-top: 3rem !important;
    }
    .pt-sm-8 {
        padding-top: 4rem !important;
    }
    .pt-sm-9 {
        padding-top: 5rem !important;
    }
    .pt-sm-10 {
        padding-top: 6rem !important;
    }
    .pe-sm-0 {
        padding-right: 0 !important;
    }
    .pe-sm-1 {
        padding-right: 0.25rem !important;
    }
    .pe-sm-2 {
        padding-right: 0.5rem !important;
    }
    .pe-sm-3 {
        padding-right: 1rem !important;
    }
    .pe-sm-4 {
        padding-right: 1.5rem !important;
    }
    .pe-sm-5 {
        padding-right: 2rem !important;
    }
    .pe-sm-6 {
        padding-right: 2.5rem !important;
    }
    .pe-sm-7 {
        padding-right: 3rem !important;
    }
    .pe-sm-8 {
        padding-right: 4rem !important;
    }
    .pe-sm-9 {
        padding-right: 5rem !important;
    }
    .pe-sm-10 {
        padding-right: 6rem !important;
    }
    .pb-sm-0 {
        padding-bottom: 0 !important;
    }
    .pb-sm-1 {
        padding-bottom: 0.25rem !important;
    }
    .pb-sm-2 {
        padding-bottom: 0.5rem !important;
    }
    .pb-sm-3 {
        padding-bottom: 1rem !important;
    }
    .pb-sm-4 {
        padding-bottom: 1.5rem !important;
    }
    .pb-sm-5 {
        padding-bottom: 2rem !important;
    }
    .pb-sm-6 {
        padding-bottom: 2.5rem !important;
    }
    .pb-sm-7 {
        padding-bottom: 3rem !important;
    }
    .pb-sm-8 {
        padding-bottom: 4rem !important;
    }
    .pb-sm-9 {
        padding-bottom: 5rem !important;
    }
    .pb-sm-10 {
        padding-bottom: 6rem !important;
    }
    .ps-sm-0 {
        padding-left: 0 !important;
    }
    .ps-sm-1 {
        padding-left: 0.25rem !important;
    }
    .ps-sm-2 {
        padding-left: 0.5rem !important;
    }
    .ps-sm-3 {
        padding-left: 1rem !important;
    }
    .ps-sm-4 {
        padding-left: 1.5rem !important;
    }
    .ps-sm-5 {
        padding-left: 2rem !important;
    }
    .ps-sm-6 {
        padding-left: 2.5rem !important;
    }
    .ps-sm-7 {
        padding-left: 3rem !important;
    }
    .ps-sm-8 {
        padding-left: 4rem !important;
    }
    .ps-sm-9 {
        padding-left: 5rem !important;
    }
    .ps-sm-10 {
        padding-left: 6rem !important;
    }
    .gap-sm-0 {
        gap: 0 !important;
    }
    .gap-sm-1 {
        gap: 0.25rem !important;
    }
    .gap-sm-2 {
        gap: 0.5rem !important;
    }
    .gap-sm-3 {
        gap: 1rem !important;
    }
    .gap-sm-4 {
        gap: 1.5rem !important;
    }
    .gap-sm-5 {
        gap: 2rem !important;
    }
    .gap-sm-6 {
        gap: 2.5rem !important;
    }
    .gap-sm-7 {
        gap: 3rem !important;
    }
    .gap-sm-8 {
        gap: 4rem !important;
    }
    .gap-sm-9 {
        gap: 5rem !important;
    }
    .gap-sm-10 {
        gap: 6rem !important;
    }
    .row-gap-sm-0 {
        row-gap: 0 !important;
    }
    .row-gap-sm-1 {
        row-gap: 0.25rem !important;
    }
    .row-gap-sm-2 {
        row-gap: 0.5rem !important;
    }
    .row-gap-sm-3 {
        row-gap: 1rem !important;
    }
    .row-gap-sm-4 {
        row-gap: 1.5rem !important;
    }
    .row-gap-sm-5 {
        row-gap: 2rem !important;
    }
    .row-gap-sm-6 {
        row-gap: 2.5rem !important;
    }
    .row-gap-sm-7 {
        row-gap: 3rem !important;
    }
    .row-gap-sm-8 {
        row-gap: 4rem !important;
    }
    .row-gap-sm-9 {
        row-gap: 5rem !important;
    }
    .row-gap-sm-10 {
        row-gap: 6rem !important;
    }
    .column-gap-sm-0 {
        -moz-column-gap: 0 !important;
        column-gap: 0 !important;
    }
    .column-gap-sm-1 {
        -moz-column-gap: 0.25rem !important;
        column-gap: 0.25rem !important;
    }
    .column-gap-sm-2 {
        -moz-column-gap: 0.5rem !important;
        column-gap: 0.5rem !important;
    }
    .column-gap-sm-3 {
        -moz-column-gap: 1rem !important;
        column-gap: 1rem !important;
    }
    .column-gap-sm-4 {
        -moz-column-gap: 1.5rem !important;
        column-gap: 1.5rem !important;
    }
    .column-gap-sm-5 {
        -moz-column-gap: 2rem !important;
        column-gap: 2rem !important;
    }
    .column-gap-sm-6 {
        -moz-column-gap: 2.5rem !important;
        column-gap: 2.5rem !important;
    }
    .column-gap-sm-7 {
        -moz-column-gap: 3rem !important;
        column-gap: 3rem !important;
    }
    .column-gap-sm-8 {
        -moz-column-gap: 4rem !important;
        column-gap: 4rem !important;
    }
    .column-gap-sm-9 {
        -moz-column-gap: 5rem !important;
        column-gap: 5rem !important;
    }
    .column-gap-sm-10 {
        -moz-column-gap: 6rem !important;
        column-gap: 6rem !important;
    }
    .text-sm-start {
        text-align: left !important;
    }
    .text-sm-end {
        text-align: right !important;
    }
    .text-sm-center {
        text-align: center !important;
    }
    .rounded-top-sm {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-top-sm-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }
    .rounded-top-sm-1 {
        border-top-left-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-top-sm-2 {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-top-sm-3 {
        border-top-left-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-top-sm-4 {
        border-top-left-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-top-sm-5 {
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-top-sm-circle {
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important;
    }
    .rounded-top-sm-pill {
        border-top-left-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-end-sm {
        border-top-right-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-end-sm-0 {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    .rounded-end-sm-1 {
        border-top-right-radius: var(--bs-border-radius-sm) !important;
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-end-sm-2 {
        border-top-right-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-end-sm-3 {
        border-top-right-radius: var(--bs-border-radius-lg) !important;
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-end-sm-4 {
        border-top-right-radius: var(--bs-border-radius-xl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-end-sm-5 {
        border-top-right-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-end-sm-circle {
        border-top-right-radius: 50% !important;
        border-bottom-right-radius: 50% !important;
    }
    .rounded-end-sm-pill {
        border-top-right-radius: var(--bs-border-radius-pill) !important;
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-bottom-sm {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-bottom-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-bottom-sm-0 {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    .rounded-bottom-sm-1 {
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-bottom-sm-2 {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-bottom-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-bottom-sm-3 {
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-bottom-sm-4 {
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-bottom-sm-5 {
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-bottom-sm-circle {
        border-bottom-right-radius: 50% !important;
        border-bottom-left-radius: 50% !important;
    }
    .rounded-bottom-sm-pill {
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-start-sm {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-start-sm-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }
    .rounded-start-sm-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-top-left-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-start-sm-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-start-sm-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-top-left-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-start-sm-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-top-left-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-start-sm-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-start-sm-circle {
        border-bottom-left-radius: 50% !important;
        border-top-left-radius: 50% !important;
    }
    .rounded-start-sm-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-top-left-radius: var(--bs-border-radius-pill) !important;
    }
}
@media (min-width: 768px) {
    .float-md-start {
        float: left !important;
    }
    .float-md-end {
        float: right !important;
    }
    .float-md-none {
        float: none !important;
    }
    .object-fit-md-contain {
        -o-object-fit: contain !important;
        object-fit: contain !important;
    }
    .object-fit-md-cover {
        -o-object-fit: cover !important;
        object-fit: cover !important;
    }
    .object-fit-md-fill {
        -o-object-fit: fill !important;
        object-fit: fill !important;
    }
    .object-fit-md-scale {
        -o-object-fit: scale-down !important;
        object-fit: scale-down !important;
    }
    .object-fit-md-none {
        -o-object-fit: none !important;
        object-fit: none !important;
    }
    .d-md-inline {
        display: inline !important;
    }
    .d-md-inline-block {
        display: inline-block !important;
    }
    .d-md-block {
        display: block !important;
    }
    .d-md-grid {
        display: grid !important;
    }
    .d-md-inline-grid {
        display: inline-grid !important;
    }
    .d-md-table {
        display: table !important;
    }
    .d-md-table-row {
        display: table-row !important;
    }
    .d-md-table-cell {
        display: table-cell !important;
    }
    .d-md-flex {
        display: flex !important;
    }
    .d-md-inline-flex {
        display: inline-flex !important;
    }
    .d-md-none {
        display: none !important;
    }
    .position-md-static {
        position: static !important;
    }
    .position-md-relative {
        position: relative !important;
    }
    .position-md-absolute {
        position: absolute !important;
    }
    .position-md-fixed {
        position: fixed !important;
    }
    .position-md-sticky {
        position: sticky !important;
    }
    .top-md-0 {
        top: 0 !important;
    }
    .top-md-50 {
        top: 50% !important;
    }
    .top-md-100 {
        top: 100% !important;
    }
    .bottom-md-0 {
        bottom: 0 !important;
    }
    .bottom-md-50 {
        bottom: 50% !important;
    }
    .bottom-md-100 {
        bottom: 100% !important;
    }
    .start-md-0 {
        left: 0 !important;
    }
    .start-md-50 {
        left: 50% !important;
    }
    .start-md-100 {
        left: 100% !important;
    }
    .translate-middle-md {
        transform: translate(-50%, -50%) !important;
    }
    .translate-middle-md-x {
        transform: translateX(-50%) !important;
    }
    .translate-middle-md-y {
        transform: translateY(-50%) !important;
    }
    .border-md {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-md-0 {
        border: 0 !important;
    }
    .border-top-md {
        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-top-md-0 {
        border-top: 0 !important;
    }
    .border-end-md {
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-end-md-0 {
        border-right: 0 !important;
    }
    .border-bottom-md {
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-bottom-md-0 {
        border-bottom: 0 !important;
    }
    .border-start-md {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-start-md-0 {
        border-left: 0 !important;
    }
    .border-md-primary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
    }
    .border-md-secondary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
    }
    .border-md-success {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
    }
    .border-md-info {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
    }
    .border-md-warning {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
    }
    .border-md-danger {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
    }
    .border-md-light {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
    }
    .border-md-dark {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
    }
    .border-md-white {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
    }
    .border-md-black {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
    }
    .w-md-25 {
        width: 25% !important;
    }
    .w-md-40 {
        width: 40% !important;
    }
    .w-md-50 {
        width: 50% !important;
    }
    .w-md-75 {
        width: 75% !important;
    }
    .w-md-100 {
        width: 100% !important;
    }
    .w-md-auto {
        width: auto !important;
    }
    .mw-md-100 {
        max-width: 100% !important;
    }
    .vw-md-100 {
        width: 100vw !important;
    }
    .min-vw-md-100 {
        min-width: 100vw !important;
    }
    .h-md-25 {
        height: 25% !important;
    }
    .h-md-50 {
        height: 50% !important;
    }
    .h-md-75 {
        height: 75% !important;
    }
    .h-md-100 {
        height: 100% !important;
    }
    .h-md-auto {
        height: auto !important;
    }
    .mh-md-100 {
        max-height: 100% !important;
    }
    .min-vh-md-100 {
        min-height: 100vh !important;
    }
    .flex-md-fill {
        flex: 1 1 auto !important;
    }
    .flex-md-row {
        flex-direction: row !important;
    }
    .flex-md-column {
        flex-direction: column !important;
    }
    .flex-md-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-md-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-md-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-md-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-md-shrink-1 {
        flex-shrink: 1 !important;
    }
    .flex-md-wrap {
        flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .justify-content-md-start {
        justify-content: flex-start !important;
    }
    .justify-content-md-end {
        justify-content: flex-end !important;
    }
    .justify-content-md-center {
        justify-content: center !important;
    }
    .justify-content-md-between {
        justify-content: space-between !important;
    }
    .justify-content-md-around {
        justify-content: space-around !important;
    }
    .justify-content-md-evenly {
        justify-content: space-evenly !important;
    }
    .align-items-md-start {
        align-items: flex-start !important;
    }
    .align-items-md-end {
        align-items: flex-end !important;
    }
    .align-items-md-center {
        align-items: center !important;
    }
    .align-items-md-baseline {
        align-items: baseline !important;
    }
    .align-items-md-stretch {
        align-items: stretch !important;
    }
    .align-content-md-start {
        align-content: flex-start !important;
    }
    .align-content-md-end {
        align-content: flex-end !important;
    }
    .align-content-md-center {
        align-content: center !important;
    }
    .align-content-md-between {
        align-content: space-between !important;
    }
    .align-content-md-around {
        align-content: space-around !important;
    }
    .align-content-md-stretch {
        align-content: stretch !important;
    }
    .align-self-md-auto {
        align-self: auto !important;
    }
    .align-self-md-start {
        align-self: flex-start !important;
    }
    .align-self-md-end {
        align-self: flex-end !important;
    }
    .align-self-md-center {
        align-self: center !important;
    }
    .align-self-md-baseline {
        align-self: baseline !important;
    }
    .align-self-md-stretch {
        align-self: stretch !important;
    }
    .order-md-first {
        order: -1 !important;
    }
    .order-md-0 {
        order: 0 !important;
    }
    .order-md-1 {
        order: 1 !important;
    }
    .order-md-2 {
        order: 2 !important;
    }
    .order-md-3 {
        order: 3 !important;
    }
    .order-md-4 {
        order: 4 !important;
    }
    .order-md-5 {
        order: 5 !important;
    }
    .order-md-last {
        order: 6 !important;
    }
    .m-md-0 {
        margin: 0 !important;
    }
    .m-md-1 {
        margin: 0.25rem !important;
    }
    .m-md-2 {
        margin: 0.5rem !important;
    }
    .m-md-3 {
        margin: 1rem !important;
    }
    .m-md-4 {
        margin: 1.5rem !important;
    }
    .m-md-5 {
        margin: 2rem !important;
    }
    .m-md-6 {
        margin: 2.5rem !important;
    }
    .m-md-7 {
        margin: 3rem !important;
    }
    .m-md-8 {
        margin: 4rem !important;
    }
    .m-md-9 {
        margin: 5rem !important;
    }
    .m-md-10 {
        margin: 6rem !important;
    }
    .m-md-auto {
        margin: auto !important;
    }
    .mx-md-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .mx-md-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }
    .mx-md-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }
    .mx-md-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }
    .mx-md-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }
    .mx-md-5 {
        margin-right: 2rem !important;
        margin-left: 2rem !important;
    }
    .mx-md-6 {
        margin-right: 2.5rem !important;
        margin-left: 2.5rem !important;
    }
    .mx-md-7 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }
    .mx-md-8 {
        margin-right: 4rem !important;
        margin-left: 4rem !important;
    }
    .mx-md-9 {
        margin-right: 5rem !important;
        margin-left: 5rem !important;
    }
    .mx-md-10 {
        margin-right: 6rem !important;
        margin-left: 6rem !important;
    }
    .mx-md-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .my-md-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .my-md-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
    .my-md-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    .my-md-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    .my-md-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
    .my-md-5 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
    }
    .my-md-6 {
        margin-top: 2.5rem !important;
        margin-bottom: 2.5rem !important;
    }
    .my-md-7 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
    .my-md-8 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
    }
    .my-md-9 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important;
    }
    .my-md-10 {
        margin-top: 6rem !important;
        margin-bottom: 6rem !important;
    }
    .my-md-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }
    .mt-md-0 {
        margin-top: 0 !important;
    }
    .mt-md-1 {
        margin-top: 0.25rem !important;
    }
    .mt-md-2 {
        margin-top: 0.5rem !important;
    }
    .mt-md-3 {
        margin-top: 1rem !important;
    }
    .mt-md-4 {
        margin-top: 1.5rem !important;
    }
    .mt-md-5 {
        margin-top: 2rem !important;
    }
    .mt-md-6 {
        margin-top: 2.5rem !important;
    }
    .mt-md-7 {
        margin-top: 3rem !important;
    }
    .mt-md-8 {
        margin-top: 4rem !important;
    }
    .mt-md-9 {
        margin-top: 5rem !important;
    }
    .mt-md-10 {
        margin-top: 6rem !important;
    }
    .mt-md-auto {
        margin-top: auto !important;
    }
    .me-md-0 {
        margin-right: 0 !important;
    }
    .me-md-1 {
        margin-right: 0.25rem !important;
    }
    .me-md-2 {
        margin-right: 0.5rem !important;
    }
    .me-md-3 {
        margin-right: 1rem !important;
    }
    .me-md-4 {
        margin-right: 1.5rem !important;
    }
    .me-md-5 {
        margin-right: 2rem !important;
    }
    .me-md-6 {
        margin-right: 2.5rem !important;
    }
    .me-md-7 {
        margin-right: 3rem !important;
    }
    .me-md-8 {
        margin-right: 4rem !important;
    }
    .me-md-9 {
        margin-right: 5rem !important;
    }
    .me-md-10 {
        margin-right: 6rem !important;
    }
    .me-md-auto {
        margin-right: auto !important;
    }
    .mb-md-0 {
        margin-bottom: 0 !important;
    }
    .mb-md-1 {
        margin-bottom: 0.25rem !important;
    }
    .mb-md-2 {
        margin-bottom: 0.5rem !important;
    }
    .mb-md-3 {
        margin-bottom: 1rem !important;
    }
    .mb-md-4 {
        margin-bottom: 1.5rem !important;
    }
    .mb-md-5 {
        margin-bottom: 2rem !important;
    }
    .mb-md-6 {
        margin-bottom: 2.5rem !important;
    }
    .mb-md-7 {
        margin-bottom: 3rem !important;
    }
    .mb-md-8 {
        margin-bottom: 4rem !important;
    }
    .mb-md-9 {
        margin-bottom: 5rem !important;
    }
    .mb-md-10 {
        margin-bottom: 6rem !important;
    }
    .mb-md-auto {
        margin-bottom: auto !important;
    }
    .ms-md-0 {
        margin-left: 0 !important;
    }
    .ms-md-1 {
        margin-left: 0.25rem !important;
    }
    .ms-md-2 {
        margin-left: 0.5rem !important;
    }
    .ms-md-3 {
        margin-left: 1rem !important;
    }
    .ms-md-4 {
        margin-left: 1.5rem !important;
    }
    .ms-md-5 {
        margin-left: 2rem !important;
    }
    .ms-md-6 {
        margin-left: 2.5rem !important;
    }
    .ms-md-7 {
        margin-left: 3rem !important;
    }
    .ms-md-8 {
        margin-left: 4rem !important;
    }
    .ms-md-9 {
        margin-left: 5rem !important;
    }
    .ms-md-10 {
        margin-left: 6rem !important;
    }
    .ms-md-auto {
        margin-left: auto !important;
    }
    .m-md-n1 {
        margin: -0.25rem !important;
    }
    .m-md-n2 {
        margin: -0.5rem !important;
    }
    .m-md-n3 {
        margin: -1rem !important;
    }
    .m-md-n4 {
        margin: -1.5rem !important;
    }
    .m-md-n5 {
        margin: -2rem !important;
    }
    .m-md-n6 {
        margin: -2.5rem !important;
    }
    .m-md-n7 {
        margin: -3rem !important;
    }
    .m-md-n8 {
        margin: -4rem !important;
    }
    .m-md-n9 {
        margin: -5rem !important;
    }
    .m-md-n10 {
        margin: -6rem !important;
    }
    .mx-md-n1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important;
    }
    .mx-md-n2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important;
    }
    .mx-md-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important;
    }
    .mx-md-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important;
    }
    .mx-md-n5 {
        margin-right: -2rem !important;
        margin-left: -2rem !important;
    }
    .mx-md-n6 {
        margin-right: -2.5rem !important;
        margin-left: -2.5rem !important;
    }
    .mx-md-n7 {
        margin-right: -3rem !important;
        margin-left: -3rem !important;
    }
    .mx-md-n8 {
        margin-right: -4rem !important;
        margin-left: -4rem !important;
    }
    .mx-md-n9 {
        margin-right: -5rem !important;
        margin-left: -5rem !important;
    }
    .mx-md-n10 {
        margin-right: -6rem !important;
        margin-left: -6rem !important;
    }
    .my-md-n1 {
        margin-top: -0.25rem !important;
        margin-bottom: -0.25rem !important;
    }
    .my-md-n2 {
        margin-top: -0.5rem !important;
        margin-bottom: -0.5rem !important;
    }
    .my-md-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important;
    }
    .my-md-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important;
    }
    .my-md-n5 {
        margin-top: -2rem !important;
        margin-bottom: -2rem !important;
    }
    .my-md-n6 {
        margin-top: -2.5rem !important;
        margin-bottom: -2.5rem !important;
    }
    .my-md-n7 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important;
    }
    .my-md-n8 {
        margin-top: -4rem !important;
        margin-bottom: -4rem !important;
    }
    .my-md-n9 {
        margin-top: -5rem !important;
        margin-bottom: -5rem !important;
    }
    .my-md-n10 {
        margin-top: -6rem !important;
        margin-bottom: -6rem !important;
    }
    .mt-md-n1 {
        margin-top: -0.25rem !important;
    }
    .mt-md-n2 {
        margin-top: -0.5rem !important;
    }
    .mt-md-n3 {
        margin-top: -1rem !important;
    }
    .mt-md-n4 {
        margin-top: -1.5rem !important;
    }
    .mt-md-n5 {
        margin-top: -2rem !important;
    }
    .mt-md-n6 {
        margin-top: -2.5rem !important;
    }
    .mt-md-n7 {
        margin-top: -3rem !important;
    }
    .mt-md-n8 {
        margin-top: -4rem !important;
    }
    .mt-md-n9 {
        margin-top: -5rem !important;
    }
    .mt-md-n10 {
        margin-top: -6rem !important;
    }
    .me-md-n1 {
        margin-right: -0.25rem !important;
    }
    .me-md-n2 {
        margin-right: -0.5rem !important;
    }
    .me-md-n3 {
        margin-right: -1rem !important;
    }
    .me-md-n4 {
        margin-right: -1.5rem !important;
    }
    .me-md-n5 {
        margin-right: -2rem !important;
    }
    .me-md-n6 {
        margin-right: -2.5rem !important;
    }
    .me-md-n7 {
        margin-right: -3rem !important;
    }
    .me-md-n8 {
        margin-right: -4rem !important;
    }
    .me-md-n9 {
        margin-right: -5rem !important;
    }
    .me-md-n10 {
        margin-right: -6rem !important;
    }
    .mb-md-n1 {
        margin-bottom: -0.25rem !important;
    }
    .mb-md-n2 {
        margin-bottom: -0.5rem !important;
    }
    .mb-md-n3 {
        margin-bottom: -1rem !important;
    }
    .mb-md-n4 {
        margin-bottom: -1.5rem !important;
    }
    .mb-md-n5 {
        margin-bottom: -2rem !important;
    }
    .mb-md-n6 {
        margin-bottom: -2.5rem !important;
    }
    .mb-md-n7 {
        margin-bottom: -3rem !important;
    }
    .mb-md-n8 {
        margin-bottom: -4rem !important;
    }
    .mb-md-n9 {
        margin-bottom: -5rem !important;
    }
    .mb-md-n10 {
        margin-bottom: -6rem !important;
    }
    .ms-md-n1 {
        margin-left: -0.25rem !important;
    }
    .ms-md-n2 {
        margin-left: -0.5rem !important;
    }
    .ms-md-n3 {
        margin-left: -1rem !important;
    }
    .ms-md-n4 {
        margin-left: -1.5rem !important;
    }
    .ms-md-n5 {
        margin-left: -2rem !important;
    }
    .ms-md-n6 {
        margin-left: -2.5rem !important;
    }
    .ms-md-n7 {
        margin-left: -3rem !important;
    }
    .ms-md-n8 {
        margin-left: -4rem !important;
    }
    .ms-md-n9 {
        margin-left: -5rem !important;
    }
    .ms-md-n10 {
        margin-left: -6rem !important;
    }
    .p-md-0 {
        padding: 0 !important;
    }
    .p-md-1 {
        padding: 0.25rem !important;
    }
    .p-md-2 {
        padding: 0.5rem !important;
    }
    .p-md-3 {
        padding: 1rem !important;
    }
    .p-md-4 {
        padding: 1.5rem !important;
    }
    .p-md-5 {
        padding: 2rem !important;
    }
    .p-md-6 {
        padding: 2.5rem !important;
    }
    .p-md-7 {
        padding: 3rem !important;
    }
    .p-md-8 {
        padding: 4rem !important;
    }
    .p-md-9 {
        padding: 5rem !important;
    }
    .p-md-10 {
        padding: 6rem !important;
    }
    .px-md-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .px-md-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }
    .px-md-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    .px-md-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .px-md-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
    .px-md-5 {
        padding-right: 2rem !important;
        padding-left: 2rem !important;
    }
    .px-md-6 {
        padding-right: 2.5rem !important;
        padding-left: 2.5rem !important;
    }
    .px-md-7 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }
    .px-md-8 {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
    }
    .px-md-9 {
        padding-right: 5rem !important;
        padding-left: 5rem !important;
    }
    .px-md-10 {
        padding-right: 6rem !important;
        padding-left: 6rem !important;
    }
    .py-md-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .py-md-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }
    .py-md-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
    .py-md-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
    .py-md-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
    .py-md-5 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }
    .py-md-6 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
    }
    .py-md-7 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
    .py-md-8 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }
    .py-md-9 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }
    .py-md-10 {
        padding-top: 6rem !important;
        padding-bottom: 6rem !important;
    }
    .pt-md-0 {
        padding-top: 0 !important;
    }
    .pt-md-1 {
        padding-top: 0.25rem !important;
    }
    .pt-md-2 {
        padding-top: 0.5rem !important;
    }
    .pt-md-3 {
        padding-top: 1rem !important;
    }
    .pt-md-4 {
        padding-top: 1.5rem !important;
    }
    .pt-md-5 {
        padding-top: 2rem !important;
    }
    .pt-md-6 {
        padding-top: 2.5rem !important;
    }
    .pt-md-7 {
        padding-top: 3rem !important;
    }
    .pt-md-8 {
        padding-top: 4rem !important;
    }
    .pt-md-9 {
        padding-top: 5rem !important;
    }
    .pt-md-10 {
        padding-top: 6rem !important;
    }
    .pe-md-0 {
        padding-right: 0 !important;
    }
    .pe-md-1 {
        padding-right: 0.25rem !important;
    }
    .pe-md-2 {
        padding-right: 0.5rem !important;
    }
    .pe-md-3 {
        padding-right: 1rem !important;
    }
    .pe-md-4 {
        padding-right: 1.5rem !important;
    }
    .pe-md-5 {
        padding-right: 2rem !important;
    }
    .pe-md-6 {
        padding-right: 2.5rem !important;
    }
    .pe-md-7 {
        padding-right: 3rem !important;
    }
    .pe-md-8 {
        padding-right: 4rem !important;
    }
    .pe-md-9 {
        padding-right: 5rem !important;
    }
    .pe-md-10 {
        padding-right: 6rem !important;
    }
    .pb-md-0 {
        padding-bottom: 0 !important;
    }
    .pb-md-1 {
        padding-bottom: 0.25rem !important;
    }
    .pb-md-2 {
        padding-bottom: 0.5rem !important;
    }
    .pb-md-3 {
        padding-bottom: 1rem !important;
    }
    .pb-md-4 {
        padding-bottom: 1.5rem !important;
    }
    .pb-md-5 {
        padding-bottom: 2rem !important;
    }
    .pb-md-6 {
        padding-bottom: 2.5rem !important;
    }
    .pb-md-7 {
        padding-bottom: 3rem !important;
    }
    .pb-md-8 {
        padding-bottom: 4rem !important;
    }
    .pb-md-9 {
        padding-bottom: 5rem !important;
    }
    .pb-md-10 {
        padding-bottom: 6rem !important;
    }
    .pb-md-250 {
        padding-bottom: 250px !important;
    }
    .ps-md-0 {
        padding-left: 0 !important;
    }
    .ps-md-1 {
        padding-left: 0.25rem !important;
    }
    .ps-md-2 {
        padding-left: 0.5rem !important;
    }
    .ps-md-3 {
        padding-left: 1rem !important;
    }
    .ps-md-4 {
        padding-left: 1.5rem !important;
    }
    .ps-md-5 {
        padding-left: 2rem !important;
    }
    .ps-md-6 {
        padding-left: 2.5rem !important;
    }
    .ps-md-7 {
        padding-left: 3rem !important;
    }
    .ps-md-8 {
        padding-left: 4rem !important;
    }
    .ps-md-9 {
        padding-left: 5rem !important;
    }
    .ps-md-10 {
        padding-left: 6rem !important;
    }
    .gap-md-0 {
        gap: 0 !important;
    }
    .gap-md-1 {
        gap: 0.25rem !important;
    }
    .gap-md-2 {
        gap: 0.5rem !important;
    }
    .gap-md-3 {
        gap: 1rem !important;
    }
    .gap-md-4 {
        gap: 1.5rem !important;
    }
    .gap-md-5 {
        gap: 2rem !important;
    }
    .gap-md-6 {
        gap: 2.5rem !important;
    }
    .gap-md-7 {
        gap: 3rem !important;
    }
    .gap-md-8 {
        gap: 4rem !important;
    }
    .gap-md-9 {
        gap: 5rem !important;
    }
    .gap-md-10 {
        gap: 6rem !important;
    }
    .row-gap-md-0 {
        row-gap: 0 !important;
    }
    .row-gap-md-1 {
        row-gap: 0.25rem !important;
    }
    .row-gap-md-2 {
        row-gap: 0.5rem !important;
    }
    .row-gap-md-3 {
        row-gap: 1rem !important;
    }
    .row-gap-md-4 {
        row-gap: 1.5rem !important;
    }
    .row-gap-md-5 {
        row-gap: 2rem !important;
    }
    .row-gap-md-6 {
        row-gap: 2.5rem !important;
    }
    .row-gap-md-7 {
        row-gap: 3rem !important;
    }
    .row-gap-md-8 {
        row-gap: 4rem !important;
    }
    .row-gap-md-9 {
        row-gap: 5rem !important;
    }
    .row-gap-md-10 {
        row-gap: 6rem !important;
    }
    .column-gap-md-0 {
        -moz-column-gap: 0 !important;
        column-gap: 0 !important;
    }
    .column-gap-md-1 {
        -moz-column-gap: 0.25rem !important;
        column-gap: 0.25rem !important;
    }
    .column-gap-md-2 {
        -moz-column-gap: 0.5rem !important;
        column-gap: 0.5rem !important;
    }
    .column-gap-md-3 {
        -moz-column-gap: 1rem !important;
        column-gap: 1rem !important;
    }
    .column-gap-md-4 {
        -moz-column-gap: 1.5rem !important;
        column-gap: 1.5rem !important;
    }
    .column-gap-md-5 {
        -moz-column-gap: 2rem !important;
        column-gap: 2rem !important;
    }
    .column-gap-md-6 {
        -moz-column-gap: 2.5rem !important;
        column-gap: 2.5rem !important;
    }
    .column-gap-md-7 {
        -moz-column-gap: 3rem !important;
        column-gap: 3rem !important;
    }
    .column-gap-md-8 {
        -moz-column-gap: 4rem !important;
        column-gap: 4rem !important;
    }
    .column-gap-md-9 {
        -moz-column-gap: 5rem !important;
        column-gap: 5rem !important;
    }
    .column-gap-md-10 {
        -moz-column-gap: 6rem !important;
        column-gap: 6rem !important;
    }
    .text-md-start {
        text-align: left !important;
    }
    .text-md-end {
        text-align: right !important;
    }
    .text-md-center {
        text-align: center !important;
    }
    .rounded-top-md {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-top-md-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }
    .rounded-top-md-1 {
        border-top-left-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-top-md-2 {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-top-md-3 {
        border-top-left-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-top-md-4 {
        border-top-left-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-top-md-5 {
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-top-md-circle {
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important;
    }
    .rounded-top-md-pill {
        border-top-left-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-end-md {
        border-top-right-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-end-md-0 {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    .rounded-end-md-1 {
        border-top-right-radius: var(--bs-border-radius-sm) !important;
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-end-md-2 {
        border-top-right-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-end-md-3 {
        border-top-right-radius: var(--bs-border-radius-lg) !important;
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-end-md-4 {
        border-top-right-radius: var(--bs-border-radius-xl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-end-md-5 {
        border-top-right-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-end-md-circle {
        border-top-right-radius: 50% !important;
        border-bottom-right-radius: 50% !important;
    }
    .rounded-end-md-pill {
        border-top-right-radius: var(--bs-border-radius-pill) !important;
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-bottom-md {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-bottom-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-bottom-md-0 {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    .rounded-bottom-md-1 {
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-bottom-md-2 {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-bottom-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-bottom-md-3 {
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-bottom-md-4 {
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-bottom-md-5 {
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-bottom-md-circle {
        border-bottom-right-radius: 50% !important;
        border-bottom-left-radius: 50% !important;
    }
    .rounded-bottom-md-pill {
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-start-md {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-start-md-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }
    .rounded-start-md-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-top-left-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-start-md-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-start-md-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-top-left-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-start-md-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-top-left-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-start-md-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-start-md-circle {
        border-bottom-left-radius: 50% !important;
        border-top-left-radius: 50% !important;
    }
    .rounded-start-md-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-top-left-radius: var(--bs-border-radius-pill) !important;
    }
}
@media (min-width: 992px) {
    .w-lg-20 {
        width: 20% !important;
    }
    .text-lg-white {
        color: white !important;
    }
    .float-lg-start {
        float: left !important;
    }
    .float-lg-end {
        float: right !important;
    }
    .float-lg-none {
        float: none !important;
    }
    .object-fit-lg-contain {
        -o-object-fit: contain !important;
        object-fit: contain !important;
    }
    .object-fit-lg-cover {
        -o-object-fit: cover !important;
        object-fit: cover !important;
    }
    .object-fit-lg-fill {
        -o-object-fit: fill !important;
        object-fit: fill !important;
    }
    .object-fit-lg-scale {
        -o-object-fit: scale-down !important;
        object-fit: scale-down !important;
    }
    .object-fit-lg-none {
        -o-object-fit: none !important;
        object-fit: none !important;
    }
    .d-lg-inline {
        display: inline !important;
    }
    .d-lg-inline-block {
        display: inline-block !important;
    }
    .d-lg-block {
        display: block !important;
    }
    .d-lg-grid {
        display: grid !important;
    }
    .d-lg-inline-grid {
        display: inline-grid !important;
    }
    .d-lg-table {
        display: table !important;
    }
    .d-lg-table-row {
        display: table-row !important;
    }
    .d-lg-table-cell {
        display: table-cell !important;
    }
    .d-lg-flex {
        display: flex !important;
    }
    .d-lg-inline-flex {
        display: inline-flex !important;
    }
    .d-lg-none {
        display: none !important;
    }
    .position-lg-static {
        position: static !important;
    }
    .position-lg-relative {
        position: relative !important;
    }
    .position-lg-absolute {
        position: absolute !important;
    }
    .position-lg-fixed {
        position: fixed !important;
    }
    .position-lg-sticky {
        position: sticky !important;
    }
    .top-lg-0 {
        top: 0 !important;
    }
    .top-lg-50 {
        top: 50% !important;
    }
    .top-lg-100 {
        top: 100% !important;
    }
    .bottom-lg-0 {
        bottom: 0 !important;
    }
    .bottom-lg-50 {
        bottom: 50% !important;
    }
    .bottom-lg-100 {
        bottom: 100% !important;
    }
    .start-lg-0 {
        left: 0 !important;
    }
    .start-lg-50 {
        left: 50% !important;
    }
    .end-lg-50 {
        right: 50% !important;
    }
    .start-lg-100 {
        left: 100% !important;
    }
    .translate-middle-lg {
        transform: translate(-50%, -50%) !important;
    }
    .translate-middle-lg-x {
        transform: translateX(-50%) !important;
    }
    .translate-middle-lg-y {
        transform: translateY(-50%) !important;
    }
    .border-lg {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-lg-0 {
        border: 0 !important;
    }
    .border-top-lg {
        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-top-lg-0 {
        border-top: 0 !important;
    }
    .border-end-lg {
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-end-lg-0 {
        border-right: 0 !important;
    }
    .border-bottom-lg {
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-bottom-lg-0 {
        border-bottom: 0 !important;
    }
    .border-start-lg {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-start-lg-0 {
        border-left: 0 !important;
    }
    .border-lg-primary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
    }
    .border-lg-secondary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
    }
    .border-lg-success {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
    }
    .border-lg-info {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
    }
    .border-lg-warning {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
    }
    .border-lg-danger {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
    }
    .border-lg-light {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
    }
    .border-lg-dark {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
    }
    .border-lg-white {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
    }
    .border-lg-black {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
    }
    .w-lg-25 {
        width: 25% !important;
    }
    .w-lg-40 {
        width: 40% !important;
    }
    .w-lg-50 {
        width: 50% !important;
    }
    .w-lg-75 {
        width: 75% !important;
    }
    .w-lg-100 {
        width: 100% !important;
    }
    .w-lg-auto {
        width: auto !important;
    }
    .mw-lg-100 {
        max-width: 100% !important;
    }
    .vw-lg-100 {
        width: 100vw !important;
    }
    .min-vw-lg-100 {
        min-width: 100vw !important;
    }
    .h-lg-25 {
        height: 25% !important;
    }
    .h-lg-50 {
        height: 50% !important;
    }
    .h-lg-75 {
        height: 75% !important;
    }
    .h-lg-100 {
        height: 100% !important;
    }
    .h-lg-auto {
        height: auto !important;
    }
    .mh-lg-100 {
        max-height: 100% !important;
    }
    .min-vh-lg-100 {
        min-height: 100vh !important;
    }
    .flex-lg-fill {
        flex: 1 1 auto !important;
    }
    .flex-lg-row {
        flex-direction: row !important;
    }
    .flex-lg-column {
        flex-direction: column !important;
    }
    .flex-lg-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-lg-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-lg-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-lg-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1 {
        flex-shrink: 1 !important;
    }
    .flex-lg-wrap {
        flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .justify-content-lg-start {
        justify-content: flex-start !important;
    }
    .justify-content-lg-end {
        justify-content: flex-end !important;
    }
    .justify-content-lg-center {
        justify-content: center !important;
    }
    .justify-content-lg-between {
        justify-content: space-between !important;
    }
    .justify-content-lg-around {
        justify-content: space-around !important;
    }
    .justify-content-lg-evenly {
        justify-content: space-evenly !important;
    }
    .align-items-lg-start {
        align-items: flex-start !important;
    }
    .align-items-lg-end {
        align-items: flex-end !important;
    }
    .align-items-lg-center {
        align-items: center !important;
    }
    .align-items-lg-baseline {
        align-items: baseline !important;
    }
    .align-items-lg-stretch {
        align-items: stretch !important;
    }
    .align-content-lg-start {
        align-content: flex-start !important;
    }
    .align-content-lg-end {
        align-content: flex-end !important;
    }
    .align-content-lg-center {
        align-content: center !important;
    }
    .align-content-lg-between {
        align-content: space-between !important;
    }
    .align-content-lg-around {
        align-content: space-around !important;
    }
    .align-content-lg-stretch {
        align-content: stretch !important;
    }
    .align-self-lg-auto {
        align-self: auto !important;
    }
    .align-self-lg-start {
        align-self: flex-start !important;
    }
    .align-self-lg-end {
        align-self: flex-end !important;
    }
    .align-self-lg-center {
        align-self: center !important;
    }
    .align-self-lg-baseline {
        align-self: baseline !important;
    }
    .align-self-lg-stretch {
        align-self: stretch !important;
    }
    .order-lg-first {
        order: -1 !important;
    }
    .order-lg-0 {
        order: 0 !important;
    }
    .order-lg-1 {
        order: 1 !important;
    }
    .order-lg-2 {
        order: 2 !important;
    }
    .order-lg-3 {
        order: 3 !important;
    }
    .order-lg-4 {
        order: 4 !important;
    }
    .order-lg-5 {
        order: 5 !important;
    }
    .order-lg-last {
        order: 6 !important;
    }
    .m-lg-0 {
        margin: 0 !important;
    }
    .m-lg-1 {
        margin: 0.25rem !important;
    }
    .m-lg-2 {
        margin: 0.5rem !important;
    }
    .m-lg-3 {
        margin: 1rem !important;
    }
    .m-lg-4 {
        margin: 1.5rem !important;
    }
    .m-lg-5 {
        margin: 2rem !important;
    }
    .m-lg-6 {
        margin: 2.5rem !important;
    }
    .m-lg-7 {
        margin: 3rem !important;
    }
    .m-lg-8 {
        margin: 4rem !important;
    }
    .m-lg-9 {
        margin: 5rem !important;
    }
    .m-lg-10 {
        margin: 6rem !important;
    }
    .m-lg-auto {
        margin: auto !important;
    }
    .mx-lg-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .mx-lg-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }
    .mx-lg-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }
    .mx-lg-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }
    .mx-lg-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }
    .mx-lg-5 {
        margin-right: 2rem !important;
        margin-left: 2rem !important;
    }
    .mx-lg-6 {
        margin-right: 2.5rem !important;
        margin-left: 2.5rem !important;
    }
    .mx-lg-7 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }
    .mx-lg-8 {
        margin-right: 4rem !important;
        margin-left: 4rem !important;
    }
    .mx-lg-9 {
        margin-right: 5rem !important;
        margin-left: 5rem !important;
    }
    .mx-lg-10 {
        margin-right: 6rem !important;
        margin-left: 6rem !important;
    }
    .mx-lg-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .my-lg-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .my-lg-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
    .my-lg-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    .my-lg-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    .my-lg-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
    .my-lg-5 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
    }
    .my-lg-6 {
        margin-top: 2.5rem !important;
        margin-bottom: 2.5rem !important;
    }
    .my-lg-7 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
    .my-lg-8 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
    }
    .my-lg-9 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important;
    }
    .my-lg-10 {
        margin-top: 6rem !important;
        margin-bottom: 6rem !important;
    }
    .my-lg-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }
    .mt-lg-0 {
        margin-top: 0 !important;
    }
    .mt-lg-1 {
        margin-top: 0.25rem !important;
    }
    .mt-lg-2 {
        margin-top: 0.5rem !important;
    }
    .mt-lg-3 {
        margin-top: 1rem !important;
    }
    .mt-lg-4 {
        margin-top: 1.5rem !important;
    }
    .mt-lg-5 {
        margin-top: 2rem !important;
    }
    .mt-lg-6 {
        margin-top: 2.5rem !important;
    }
    .mt-lg-7 {
        margin-top: 3rem !important;
    }
    .mt-lg-8 {
        margin-top: 4rem !important;
    }
    .mt-lg-9 {
        margin-top: 5rem !important;
    }
    .mt-lg-10 {
        margin-top: 6rem !important;
    }
    .mt-lg-auto {
        margin-top: auto !important;
    }
    .me-lg-0 {
        margin-right: 0 !important;
    }
    .me-lg-1 {
        margin-right: 0.25rem !important;
    }
    .me-lg-2 {
        margin-right: 0.5rem !important;
    }
    .me-lg-3 {
        margin-right: 1rem !important;
    }
    .me-lg-4 {
        margin-right: 1.5rem !important;
    }
    .me-lg-5 {
        margin-right: 2rem !important;
    }
    .me-lg-6 {
        margin-right: 2.5rem !important;
    }
    .me-lg-7 {
        margin-right: 3rem !important;
    }
    .me-lg-8 {
        margin-right: 4rem !important;
    }
    .me-lg-9 {
        margin-right: 5rem !important;
    }
    .me-lg-10 {
        margin-right: 6rem !important;
    }
    .me-lg-auto {
        margin-right: auto !important;
    }
    .mb-lg-0 {
        margin-bottom: 0 !important;
    }
    .mb-lg-1 {
        margin-bottom: 0.25rem !important;
    }
    .mb-lg-2 {
        margin-bottom: 0.5rem !important;
    }
    .mb-lg-3 {
        margin-bottom: 1rem !important;
    }
    .mb-lg-4 {
        margin-bottom: 1.5rem !important;
    }
    .mb-lg-5 {
        margin-bottom: 2rem !important;
    }
    .mb-lg-6 {
        margin-bottom: 2.5rem !important;
    }
    .mb-lg-7 {
        margin-bottom: 3rem !important;
    }
    .mb-lg-8 {
        margin-bottom: 4rem !important;
    }
    .mb-lg-9 {
        margin-bottom: 5rem !important;
    }
    .mb-lg-10 {
        margin-bottom: 6rem !important;
    }
    .mb-lg-auto {
        margin-bottom: auto !important;
    }
    .ms-lg-0 {
        margin-left: 0 !important;
    }
    .ms-lg-1 {
        margin-left: 0.25rem !important;
    }
    .ms-lg-2 {
        margin-left: 0.5rem !important;
    }
    .ms-lg-3 {
        margin-left: 1rem !important;
    }
    .ms-lg-4 {
        margin-left: 1.5rem !important;
    }
    .ms-lg-5 {
        margin-left: 2rem !important;
    }
    .ms-lg-6 {
        margin-left: 2.5rem !important;
    }
    .ms-lg-7 {
        margin-left: 3rem !important;
    }
    .ms-lg-8 {
        margin-left: 4rem !important;
    }
    .ms-lg-9 {
        margin-left: 5rem !important;
    }
    .ms-lg-10 {
        margin-left: 6rem !important;
    }
    .ms-lg-auto {
        margin-left: auto !important;
    }
    .m-lg-n1 {
        margin: -0.25rem !important;
    }
    .m-lg-n2 {
        margin: -0.5rem !important;
    }
    .m-lg-n3 {
        margin: -1rem !important;
    }
    .m-lg-n4 {
        margin: -1.5rem !important;
    }
    .m-lg-n5 {
        margin: -2rem !important;
    }
    .m-lg-n6 {
        margin: -2.5rem !important;
    }
    .m-lg-n7 {
        margin: -3rem !important;
    }
    .m-lg-n8 {
        margin: -4rem !important;
    }
    .m-lg-n9 {
        margin: -5rem !important;
    }
    .m-lg-n10 {
        margin: -6rem !important;
    }
    .mx-lg-n1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important;
    }
    .mx-lg-n2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important;
    }
    .mx-lg-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important;
    }
    .mx-lg-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important;
    }
    .mx-lg-n5 {
        margin-right: -2rem !important;
        margin-left: -2rem !important;
    }
    .mx-lg-n6 {
        margin-right: -2.5rem !important;
        margin-left: -2.5rem !important;
    }
    .mx-lg-n7 {
        margin-right: -3rem !important;
        margin-left: -3rem !important;
    }
    .mx-lg-n8 {
        margin-right: -4rem !important;
        margin-left: -4rem !important;
    }
    .mx-lg-n9 {
        margin-right: -5rem !important;
        margin-left: -5rem !important;
    }
    .mx-lg-n10 {
        margin-right: -6rem !important;
        margin-left: -6rem !important;
    }
    .my-lg-n1 {
        margin-top: -0.25rem !important;
        margin-bottom: -0.25rem !important;
    }
    .my-lg-n2 {
        margin-top: -0.5rem !important;
        margin-bottom: -0.5rem !important;
    }
    .my-lg-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important;
    }
    .my-lg-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important;
    }
    .my-lg-n5 {
        margin-top: -2rem !important;
        margin-bottom: -2rem !important;
    }
    .my-lg-n6 {
        margin-top: -2.5rem !important;
        margin-bottom: -2.5rem !important;
    }
    .my-lg-n7 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important;
    }
    .my-lg-n8 {
        margin-top: -4rem !important;
        margin-bottom: -4rem !important;
    }
    .my-lg-n9 {
        margin-top: -5rem !important;
        margin-bottom: -5rem !important;
    }
    .my-lg-n10 {
        margin-top: -6rem !important;
        margin-bottom: -6rem !important;
    }
    .mt-lg-n1 {
        margin-top: -0.25rem !important;
    }
    .mt-lg-n2 {
        margin-top: -0.5rem !important;
    }
    .mt-lg-n3 {
        margin-top: -1rem !important;
    }
    .mt-lg-n4 {
        margin-top: -1.5rem !important;
    }
    .mt-lg-n5 {
        margin-top: -2rem !important;
    }
    .mt-lg-n6 {
        margin-top: -2.5rem !important;
    }
    .mt-lg-n7 {
        margin-top: -3rem !important;
    }
    .mt-lg-n8 {
        margin-top: -4rem !important;
    }
    .mt-lg-n9 {
        margin-top: -5rem !important;
    }
    .mt-lg-n10 {
        margin-top: -6rem !important;
    }
    .me-lg-n1 {
        margin-right: -0.25rem !important;
    }
    .me-lg-n2 {
        margin-right: -0.5rem !important;
    }
    .me-lg-n3 {
        margin-right: -1rem !important;
    }
    .me-lg-n4 {
        margin-right: -1.5rem !important;
    }
    .me-lg-n5 {
        margin-right: -2rem !important;
    }
    .me-lg-n6 {
        margin-right: -2.5rem !important;
    }
    .me-lg-n7 {
        margin-right: -3rem !important;
    }
    .me-lg-n8 {
        margin-right: -4rem !important;
    }
    .me-lg-n9 {
        margin-right: -5rem !important;
    }
    .me-lg-n10 {
        margin-right: -6rem !important;
    }
    .mb-lg-n1 {
        margin-bottom: -0.25rem !important;
    }
    .mb-lg-n2 {
        margin-bottom: -0.5rem !important;
    }
    .mb-lg-n3 {
        margin-bottom: -1rem !important;
    }
    .mb-lg-n4 {
        margin-bottom: -1.5rem !important;
    }
    .mb-lg-n5 {
        margin-bottom: -2rem !important;
    }
    .mb-lg-n6 {
        margin-bottom: -2.5rem !important;
    }
    .mb-lg-n7 {
        margin-bottom: -3rem !important;
    }
    .mb-lg-n8 {
        margin-bottom: -4rem !important;
    }
    .mb-lg-n9 {
        margin-bottom: -5rem !important;
    }
    .mb-lg-n10 {
        margin-bottom: -6rem !important;
    }
    .ms-lg-n1 {
        margin-left: -0.25rem !important;
    }
    .ms-lg-n2 {
        margin-left: -0.5rem !important;
    }
    .ms-lg-n3 {
        margin-left: -1rem !important;
    }
    .ms-lg-n4 {
        margin-left: -1.5rem !important;
    }
    .ms-lg-n5 {
        margin-left: -2rem !important;
    }
    .ms-lg-n6 {
        margin-left: -2.5rem !important;
    }
    .ms-lg-n7 {
        margin-left: -3rem !important;
    }
    .ms-lg-n8 {
        margin-left: -4rem !important;
    }
    .ms-lg-n9 {
        margin-left: -5rem !important;
    }
    .ms-lg-n10 {
        margin-left: -6rem !important;
    }
    .p-lg-0 {
        padding: 0 !important;
    }
    .p-lg-1 {
        padding: 0.25rem !important;
    }
    .p-lg-2 {
        padding: 0.5rem !important;
    }
    .p-lg-3 {
        padding: 1rem !important;
    }
    .p-lg-4 {
        padding: 1.5rem !important;
    }
    .p-lg-5 {
        padding: 2rem !important;
    }
    .p-lg-6 {
        padding: 2.5rem !important;
    }
    .p-lg-7 {
        padding: 3rem !important;
    }
    .p-lg-8 {
        padding: 4rem !important;
    }
    .p-lg-9 {
        padding: 5rem !important;
    }
    .p-lg-10 {
        padding: 6rem !important;
    }
    .px-lg-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .px-lg-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }
    .px-lg-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    .px-lg-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .px-lg-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
    .px-lg-5 {
        padding-right: 2rem !important;
        padding-left: 2rem !important;
    }
    .px-lg-6 {
        padding-right: 2.5rem !important;
        padding-left: 2.5rem !important;
    }
    .px-lg-7 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }
    .px-lg-8 {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
    }
    .px-lg-9 {
        padding-right: 5rem !important;
        padding-left: 5rem !important;
    }
    .px-lg-10 {
        padding-right: 6rem !important;
        padding-left: 6rem !important;
    }
    .py-lg-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .py-lg-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }
    .py-lg-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
    .py-lg-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
    .py-lg-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
    .py-lg-5 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }
    .py-lg-6 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
    }
    .py-lg-7 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
    .py-lg-8 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }
    .py-lg-9 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }
    .py-lg-10 {
        padding-top: 6rem !important;
        padding-bottom: 6rem !important;
    }
    .py-lg-200 {
        padding-top: 200px !important;
        padding-bottom: 200px !important;
    }
    .pt-lg-0 {
        padding-top: 0 !important;
    }
    .pt-lg-1 {
        padding-top: 0.25rem !important;
    }
    .pt-lg-2 {
        padding-top: 0.5rem !important;
    }
    .pt-lg-3 {
        padding-top: 1rem !important;
    }
    .pt-lg-4 {
        padding-top: 1.5rem !important;
    }
    .pt-lg-5 {
        padding-top: 2rem !important;
    }
    .pt-lg-6 {
        padding-top: 2.5rem !important;
    }
    .pt-lg-7 {
        padding-top: 3rem !important;
    }
    .pt-lg-8 {
        padding-top: 4rem !important;
    }
    .pt-lg-9 {
        padding-top: 5rem !important;
    }
    .pt-lg-10 {
        padding-top: 6rem !important;
    }
    .pt-lg-150 {
        padding-top: 150px !important;
    }
    .pe-lg-0 {
        padding-right: 0 !important;
    }
    .pe-lg-1 {
        padding-right: 0.25rem !important;
    }
    .pe-lg-2 {
        padding-right: 0.5rem !important;
    }
    .pe-lg-3 {
        padding-right: 1rem !important;
    }
    .pe-lg-4 {
        padding-right: 1.5rem !important;
    }
    .pe-lg-5 {
        padding-right: 2rem !important;
    }
    .pe-lg-6 {
        padding-right: 2.5rem !important;
    }
    .pe-lg-7 {
        padding-right: 3rem !important;
    }
    .pe-lg-8 {
        padding-right: 4rem !important;
    }
    .pe-lg-9 {
        padding-right: 5rem !important;
    }
    .pe-lg-10 {
        padding-right: 6rem !important;
    }
    .pb-lg-0 {
        padding-bottom: 0 !important;
    }
    .pb-lg-1 {
        padding-bottom: 0.25rem !important;
    }
    .pb-lg-2 {
        padding-bottom: 0.5rem !important;
    }
    .pb-lg-3 {
        padding-bottom: 1rem !important;
    }
    .pb-lg-4 {
        padding-bottom: 1.5rem !important;
    }
    .pb-lg-5 {
        padding-bottom: 2rem !important;
    }
    .pb-lg-6 {
        padding-bottom: 2.5rem !important;
    }
    .pb-lg-7 {
        padding-bottom: 3rem !important;
    }
    .pb-lg-8 {
        padding-bottom: 4rem !important;
    }
    .pb-lg-9 {
        padding-bottom: 5rem !important;
    }
    .pb-lg-10 {
        padding-bottom: 6rem !important;
    }
    .pb-lg-120 {
        padding-bottom: 120px !important;
    }
    .pb-lg-150 {
        padding-bottom: 150px !important;
    }
    .pb-lg-250 {
        padding-bottom: 250px !important;
    }
    .ps-lg-0 {
        padding-left: 0 !important;
    }
    .ps-lg-1 {
        padding-left: 0.25rem !important;
    }
    .ps-lg-2 {
        padding-left: 0.5rem !important;
    }
    .ps-lg-3 {
        padding-left: 1rem !important;
    }
    .ps-lg-4 {
        padding-left: 1.5rem !important;
    }
    .ps-lg-5 {
        padding-left: 2rem !important;
    }
    .ps-lg-6 {
        padding-left: 2.5rem !important;
    }
    .ps-lg-7 {
        padding-left: 3rem !important;
    }
    .ps-lg-8 {
        padding-left: 4rem !important;
    }
    .ps-lg-9 {
        padding-left: 5rem !important;
    }
    .ps-lg-10 {
        padding-left: 6rem !important;
    }
    .gap-lg-0 {
        gap: 0 !important;
    }
    .gap-lg-1 {
        gap: 0.25rem !important;
    }
    .gap-lg-2 {
        gap: 0.5rem !important;
    }
    .gap-lg-3 {
        gap: 1rem !important;
    }
    .gap-lg-4 {
        gap: 1.5rem !important;
    }
    .gap-lg-5 {
        gap: 2rem !important;
    }
    .gap-lg-6 {
        gap: 2.5rem !important;
    }
    .gap-lg-7 {
        gap: 3rem !important;
    }
    .gap-lg-8 {
        gap: 4rem !important;
    }
    .gap-lg-9 {
        gap: 5rem !important;
    }
    .gap-lg-10 {
        gap: 6rem !important;
    }
    .row-gap-lg-0 {
        row-gap: 0 !important;
    }
    .row-gap-lg-1 {
        row-gap: 0.25rem !important;
    }
    .row-gap-lg-2 {
        row-gap: 0.5rem !important;
    }
    .row-gap-lg-3 {
        row-gap: 1rem !important;
    }
    .row-gap-lg-4 {
        row-gap: 1.5rem !important;
    }
    .row-gap-lg-5 {
        row-gap: 2rem !important;
    }
    .row-gap-lg-6 {
        row-gap: 2.5rem !important;
    }
    .row-gap-lg-7 {
        row-gap: 3rem !important;
    }
    .row-gap-lg-8 {
        row-gap: 4rem !important;
    }
    .row-gap-lg-9 {
        row-gap: 5rem !important;
    }
    .row-gap-lg-10 {
        row-gap: 6rem !important;
    }
    .column-gap-lg-0 {
        -moz-column-gap: 0 !important;
        column-gap: 0 !important;
    }
    .column-gap-lg-1 {
        -moz-column-gap: 0.25rem !important;
        column-gap: 0.25rem !important;
    }
    .column-gap-lg-2 {
        -moz-column-gap: 0.5rem !important;
        column-gap: 0.5rem !important;
    }
    .column-gap-lg-3 {
        -moz-column-gap: 1rem !important;
        column-gap: 1rem !important;
    }
    .column-gap-lg-4 {
        -moz-column-gap: 1.5rem !important;
        column-gap: 1.5rem !important;
    }
    .column-gap-lg-5 {
        -moz-column-gap: 2rem !important;
        column-gap: 2rem !important;
    }
    .column-gap-lg-6 {
        -moz-column-gap: 2.5rem !important;
        column-gap: 2.5rem !important;
    }
    .column-gap-lg-7 {
        -moz-column-gap: 3rem !important;
        column-gap: 3rem !important;
    }
    .column-gap-lg-8 {
        -moz-column-gap: 4rem !important;
        column-gap: 4rem !important;
    }
    .column-gap-lg-9 {
        -moz-column-gap: 5rem !important;
        column-gap: 5rem !important;
    }
    .column-gap-lg-10 {
        -moz-column-gap: 6rem !important;
        column-gap: 6rem !important;
    }
    .text-lg-start {
        text-align: left !important;
    }
    .text-lg-end {
        text-align: right !important;
    }
    .text-lg-center {
        text-align: center !important;
    }
    .rounded-top-lg {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-top-lg-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }
    .rounded-top-lg-1 {
        border-top-left-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-top-lg-2 {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-top-lg-3 {
        border-top-left-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-top-lg-4 {
        border-top-left-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-top-lg-5 {
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-top-lg-circle {
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important;
    }
    .rounded-top-lg-pill {
        border-top-left-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-end-lg {
        border-top-right-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-end-lg-0 {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    .rounded-end-lg-1 {
        border-top-right-radius: var(--bs-border-radius-sm) !important;
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-end-lg-2 {
        border-top-right-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-end-lg-3 {
        border-top-right-radius: var(--bs-border-radius-lg) !important;
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-end-lg-4 {
        border-top-right-radius: var(--bs-border-radius-xl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-end-lg-5 {
        border-top-right-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-end-lg-circle {
        border-top-right-radius: 50% !important;
        border-bottom-right-radius: 50% !important;
    }
    .rounded-end-lg-pill {
        border-top-right-radius: var(--bs-border-radius-pill) !important;
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-bottom-lg {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-bottom-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-bottom-lg-0 {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    .rounded-bottom-lg-1 {
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-bottom-lg-2 {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-bottom-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-bottom-lg-3 {
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-bottom-lg-4 {
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-bottom-lg-5 {
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-bottom-lg-circle {
        border-bottom-right-radius: 50% !important;
        border-bottom-left-radius: 50% !important;
    }
    .rounded-bottom-lg-pill {
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-start-lg {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-start-lg-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }
    .rounded-start-lg-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-top-left-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-start-lg-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-start-lg-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-top-left-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-start-lg-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-top-left-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-start-lg-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-start-lg-circle {
        border-bottom-left-radius: 50% !important;
        border-top-left-radius: 50% !important;
    }
    .rounded-start-lg-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-top-left-radius: var(--bs-border-radius-pill) !important;
    }
}
@media (min-width: 1200px) {
    .float-xl-start {
        float: left !important;
    }
    .float-xl-end {
        float: right !important;
    }
    .float-xl-none {
        float: none !important;
    }
    .object-fit-xl-contain {
        -o-object-fit: contain !important;
        object-fit: contain !important;
    }
    .object-fit-xl-cover {
        -o-object-fit: cover !important;
        object-fit: cover !important;
    }
    .object-fit-xl-fill {
        -o-object-fit: fill !important;
        object-fit: fill !important;
    }
    .object-fit-xl-scale {
        -o-object-fit: scale-down !important;
        object-fit: scale-down !important;
    }
    .object-fit-xl-none {
        -o-object-fit: none !important;
        object-fit: none !important;
    }
    .d-xl-inline {
        display: inline !important;
    }
    .d-xl-inline-block {
        display: inline-block !important;
    }
    .d-xl-block {
        display: block !important;
    }
    .d-xl-grid {
        display: grid !important;
    }
    .d-xl-inline-grid {
        display: inline-grid !important;
    }
    .d-xl-table {
        display: table !important;
    }
    .d-xl-table-row {
        display: table-row !important;
    }
    .d-xl-table-cell {
        display: table-cell !important;
    }
    .d-xl-flex {
        display: flex !important;
    }
    .d-xl-inline-flex {
        display: inline-flex !important;
    }
    .d-xl-none {
        display: none !important;
    }
    .position-xl-static {
        position: static !important;
    }
    .position-xl-relative {
        position: relative !important;
    }
    .position-xl-absolute {
        position: absolute !important;
    }
    .position-xl-fixed {
        position: fixed !important;
    }
    .position-xl-sticky {
        position: sticky !important;
    }
    .top-xl-0 {
        top: 0 !important;
    }
    .top-xl-50 {
        top: 50% !important;
    }
    .top-xl-100 {
        top: 100% !important;
    }
    .bottom-xl-0 {
        bottom: 0 !important;
    }
    .bottom-xl-50 {
        bottom: 50% !important;
    }
    .bottom-xl-100 {
        bottom: 100% !important;
    }
    .start-xl-0 {
        left: 0 !important;
    }
    .start-xl-50 {
        left: 50% !important;
    }
    .start-xl-100 {
        left: 100% !important;
    }
    .translate-middle-xl {
        transform: translate(-50%, -50%) !important;
    }
    .translate-middle-xl-x {
        transform: translateX(-50%) !important;
    }
    .translate-middle-xl-y {
        transform: translateY(-50%) !important;
    }
    .border-xl {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-xl-0 {
        border: 0 !important;
    }
    .border-top-xl {
        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-top-xl-0 {
        border-top: 0 !important;
    }
    .border-end-xl {
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-end-xl-0 {
        border-right: 0 !important;
    }
    .border-bottom-xl {
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-bottom-xl-0 {
        border-bottom: 0 !important;
    }
    .border-start-xl {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-start-xl-0 {
        border-left: 0 !important;
    }
    .border-xl-primary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xl-secondary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xl-success {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xl-info {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xl-warning {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xl-danger {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xl-light {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xl-dark {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xl-white {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xl-black {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
    }
    .w-xl-25 {
        width: 25% !important;
    }
    .w-xl-50 {
        width: 50% !important;
    }
    .w-xl-75 {
        width: 75% !important;
    }
    .w-xl-100 {
        width: 100% !important;
    }
    .w-xl-auto {
        width: auto !important;
    }
    .mw-xl-100 {
        max-width: 100% !important;
    }
    .vw-xl-100 {
        width: 100vw !important;
    }
    .min-vw-xl-100 {
        min-width: 100vw !important;
    }
    .h-xl-25 {
        height: 25% !important;
    }
    .h-xl-50 {
        height: 50% !important;
    }
    .h-xl-75 {
        height: 75% !important;
    }
    .h-xl-100 {
        height: 100% !important;
    }
    .h-xl-auto {
        height: auto !important;
    }
    .mh-xl-100 {
        max-height: 100% !important;
    }
    .min-vh-xl-100 {
        min-height: 100vh !important;
    }
    .flex-xl-fill {
        flex: 1 1 auto !important;
    }
    .flex-xl-row {
        flex-direction: row !important;
    }
    .flex-xl-column {
        flex-direction: column !important;
    }
    .flex-xl-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-xl-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-xl-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-xl-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1 {
        flex-shrink: 1 !important;
    }
    .flex-xl-wrap {
        flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .justify-content-xl-start {
        justify-content: flex-start !important;
    }
    .justify-content-xl-end {
        justify-content: flex-end !important;
    }
    .justify-content-xl-center {
        justify-content: center !important;
    }
    .justify-content-xl-between {
        justify-content: space-between !important;
    }
    .justify-content-xl-around {
        justify-content: space-around !important;
    }
    .justify-content-xl-evenly {
        justify-content: space-evenly !important;
    }
    .align-items-xl-start {
        align-items: flex-start !important;
    }
    .align-items-xl-end {
        align-items: flex-end !important;
    }
    .align-items-xl-center {
        align-items: center !important;
    }
    .align-items-xl-baseline {
        align-items: baseline !important;
    }
    .align-items-xl-stretch {
        align-items: stretch !important;
    }
    .align-content-xl-start {
        align-content: flex-start !important;
    }
    .align-content-xl-end {
        align-content: flex-end !important;
    }
    .align-content-xl-center {
        align-content: center !important;
    }
    .align-content-xl-between {
        align-content: space-between !important;
    }
    .align-content-xl-around {
        align-content: space-around !important;
    }
    .align-content-xl-stretch {
        align-content: stretch !important;
    }
    .align-self-xl-auto {
        align-self: auto !important;
    }
    .align-self-xl-start {
        align-self: flex-start !important;
    }
    .align-self-xl-end {
        align-self: flex-end !important;
    }
    .align-self-xl-center {
        align-self: center !important;
    }
    .align-self-xl-baseline {
        align-self: baseline !important;
    }
    .align-self-xl-stretch {
        align-self: stretch !important;
    }
    .order-xl-first {
        order: -1 !important;
    }
    .order-xl-0 {
        order: 0 !important;
    }
    .order-xl-1 {
        order: 1 !important;
    }
    .order-xl-2 {
        order: 2 !important;
    }
    .order-xl-3 {
        order: 3 !important;
    }
    .order-xl-4 {
        order: 4 !important;
    }
    .order-xl-5 {
        order: 5 !important;
    }
    .order-xl-last {
        order: 6 !important;
    }
    .m-xl-0 {
        margin: 0 !important;
    }
    .m-xl-1 {
        margin: 0.25rem !important;
    }
    .m-xl-2 {
        margin: 0.5rem !important;
    }
    .m-xl-3 {
        margin: 1rem !important;
    }
    .m-xl-4 {
        margin: 1.5rem !important;
    }
    .m-xl-5 {
        margin: 2rem !important;
    }
    .m-xl-6 {
        margin: 2.5rem !important;
    }
    .m-xl-7 {
        margin: 3rem !important;
    }
    .m-xl-8 {
        margin: 4rem !important;
    }
    .m-xl-9 {
        margin: 5rem !important;
    }
    .m-xl-10 {
        margin: 6rem !important;
    }
    .m-xl-auto {
        margin: auto !important;
    }
    .mx-xl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .mx-xl-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }
    .mx-xl-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }
    .mx-xl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }
    .mx-xl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }
    .mx-xl-5 {
        margin-right: 2rem !important;
        margin-left: 2rem !important;
    }
    .mx-xl-6 {
        margin-right: 2.5rem !important;
        margin-left: 2.5rem !important;
    }
    .mx-xl-7 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }
    .mx-xl-8 {
        margin-right: 4rem !important;
        margin-left: 4rem !important;
    }
    .mx-xl-9 {
        margin-right: 5rem !important;
        margin-left: 5rem !important;
    }
    .mx-xl-10 {
        margin-right: 6rem !important;
        margin-left: 6rem !important;
    }
    .mx-xl-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .my-xl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .my-xl-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
    .my-xl-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    .my-xl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    .my-xl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
    .my-xl-5 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
    }
    .my-xl-6 {
        margin-top: 2.5rem !important;
        margin-bottom: 2.5rem !important;
    }
    .my-xl-7 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
    .my-xl-8 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
    }
    .my-xl-9 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important;
    }
    .my-xl-10 {
        margin-top: 6rem !important;
        margin-bottom: 6rem !important;
    }
    .my-xl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }
    .mt-xl-0 {
        margin-top: 0 !important;
    }
    .mt-xl-1 {
        margin-top: 0.25rem !important;
    }
    .mt-xl-2 {
        margin-top: 0.5rem !important;
    }
    .mt-xl-3 {
        margin-top: 1rem !important;
    }
    .mt-xl-4 {
        margin-top: 1.5rem !important;
    }
    .mt-xl-5 {
        margin-top: 2rem !important;
    }
    .mt-xl-6 {
        margin-top: 2.5rem !important;
    }
    .mt-xl-7 {
        margin-top: 3rem !important;
    }
    .mt-xl-8 {
        margin-top: 4rem !important;
    }
    .mt-xl-9 {
        margin-top: 5rem !important;
    }
    .mt-xl-10 {
        margin-top: 6rem !important;
    }
    .mt-xl-auto {
        margin-top: auto !important;
    }
    .me-xl-0 {
        margin-right: 0 !important;
    }
    .me-xl-1 {
        margin-right: 0.25rem !important;
    }
    .me-xl-2 {
        margin-right: 0.5rem !important;
    }
    .me-xl-3 {
        margin-right: 1rem !important;
    }
    .me-xl-4 {
        margin-right: 1.5rem !important;
    }
    .me-xl-5 {
        margin-right: 2rem !important;
    }
    .me-xl-6 {
        margin-right: 2.5rem !important;
    }
    .me-xl-7 {
        margin-right: 3rem !important;
    }
    .me-xl-8 {
        margin-right: 4rem !important;
    }
    .me-xl-9 {
        margin-right: 5rem !important;
    }
    .me-xl-10 {
        margin-right: 6rem !important;
    }
    .me-xl-auto {
        margin-right: auto !important;
    }
    .mb-xl-0 {
        margin-bottom: 0 !important;
    }
    .mb-xl-1 {
        margin-bottom: 0.25rem !important;
    }
    .mb-xl-2 {
        margin-bottom: 0.5rem !important;
    }
    .mb-xl-3 {
        margin-bottom: 1rem !important;
    }
    .mb-xl-4 {
        margin-bottom: 1.5rem !important;
    }
    .mb-xl-5 {
        margin-bottom: 2rem !important;
    }
    .mb-xl-6 {
        margin-bottom: 2.5rem !important;
    }
    .mb-xl-7 {
        margin-bottom: 3rem !important;
    }
    .mb-xl-8 {
        margin-bottom: 4rem !important;
    }
    .mb-xl-9 {
        margin-bottom: 5rem !important;
    }
    .mb-xl-10 {
        margin-bottom: 6rem !important;
    }
    .mb-xl-auto {
        margin-bottom: auto !important;
    }
    .ms-xl-0 {
        margin-left: 0 !important;
    }
    .ms-xl-1 {
        margin-left: 0.25rem !important;
    }
    .ms-xl-2 {
        margin-left: 0.5rem !important;
    }
    .ms-xl-3 {
        margin-left: 1rem !important;
    }
    .ms-xl-4 {
        margin-left: 1.5rem !important;
    }
    .ms-xl-5 {
        margin-left: 2rem !important;
    }
    .ms-xl-6 {
        margin-left: 2.5rem !important;
    }
    .ms-xl-7 {
        margin-left: 3rem !important;
    }
    .ms-xl-8 {
        margin-left: 4rem !important;
    }
    .ms-xl-9 {
        margin-left: 5rem !important;
    }
    .ms-xl-10 {
        margin-left: 6rem !important;
    }
    .ms-xl-auto {
        margin-left: auto !important;
    }
    .m-xl-n1 {
        margin: -0.25rem !important;
    }
    .m-xl-n2 {
        margin: -0.5rem !important;
    }
    .m-xl-n3 {
        margin: -1rem !important;
    }
    .m-xl-n4 {
        margin: -1.5rem !important;
    }
    .m-xl-n5 {
        margin: -2rem !important;
    }
    .m-xl-n6 {
        margin: -2.5rem !important;
    }
    .m-xl-n7 {
        margin: -3rem !important;
    }
    .m-xl-n8 {
        margin: -4rem !important;
    }
    .m-xl-n9 {
        margin: -5rem !important;
    }
    .m-xl-n10 {
        margin: -6rem !important;
    }
    .mx-xl-n1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important;
    }
    .mx-xl-n2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important;
    }
    .mx-xl-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important;
    }
    .mx-xl-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important;
    }
    .mx-xl-n5 {
        margin-right: -2rem !important;
        margin-left: -2rem !important;
    }
    .mx-xl-n6 {
        margin-right: -2.5rem !important;
        margin-left: -2.5rem !important;
    }
    .mx-xl-n7 {
        margin-right: -3rem !important;
        margin-left: -3rem !important;
    }
    .mx-xl-n8 {
        margin-right: -4rem !important;
        margin-left: -4rem !important;
    }
    .mx-xl-n9 {
        margin-right: -5rem !important;
        margin-left: -5rem !important;
    }
    .mx-xl-n10 {
        margin-right: -6rem !important;
        margin-left: -6rem !important;
    }
    .my-xl-n1 {
        margin-top: -0.25rem !important;
        margin-bottom: -0.25rem !important;
    }
    .my-xl-n2 {
        margin-top: -0.5rem !important;
        margin-bottom: -0.5rem !important;
    }
    .my-xl-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important;
    }
    .my-xl-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important;
    }
    .my-xl-n5 {
        margin-top: -2rem !important;
        margin-bottom: -2rem !important;
    }
    .my-xl-n6 {
        margin-top: -2.5rem !important;
        margin-bottom: -2.5rem !important;
    }
    .my-xl-n7 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important;
    }
    .my-xl-n8 {
        margin-top: -4rem !important;
        margin-bottom: -4rem !important;
    }
    .my-xl-n9 {
        margin-top: -5rem !important;
        margin-bottom: -5rem !important;
    }
    .my-xl-n10 {
        margin-top: -6rem !important;
        margin-bottom: -6rem !important;
    }
    .mt-xl-n1 {
        margin-top: -0.25rem !important;
    }
    .mt-xl-n2 {
        margin-top: -0.5rem !important;
    }
    .mt-xl-n3 {
        margin-top: -1rem !important;
    }
    .mt-xl-n4 {
        margin-top: -1.5rem !important;
    }
    .mt-xl-n5 {
        margin-top: -2rem !important;
    }
    .mt-xl-n6 {
        margin-top: -2.5rem !important;
    }
    .mt-xl-n7 {
        margin-top: -3rem !important;
    }
    .mt-xl-n8 {
        margin-top: -4rem !important;
    }
    .mt-xl-n9 {
        margin-top: -5rem !important;
    }
    .mt-xl-n10 {
        margin-top: -6rem !important;
    }
    .me-xl-n1 {
        margin-right: -0.25rem !important;
    }
    .me-xl-n2 {
        margin-right: -0.5rem !important;
    }
    .me-xl-n3 {
        margin-right: -1rem !important;
    }
    .me-xl-n4 {
        margin-right: -1.5rem !important;
    }
    .me-xl-n5 {
        margin-right: -2rem !important;
    }
    .me-xl-n6 {
        margin-right: -2.5rem !important;
    }
    .me-xl-n7 {
        margin-right: -3rem !important;
    }
    .me-xl-n8 {
        margin-right: -4rem !important;
    }
    .me-xl-n9 {
        margin-right: -5rem !important;
    }
    .me-xl-n10 {
        margin-right: -6rem !important;
    }
    .mb-xl-n1 {
        margin-bottom: -0.25rem !important;
    }
    .mb-xl-n2 {
        margin-bottom: -0.5rem !important;
    }
    .mb-xl-n3 {
        margin-bottom: -1rem !important;
    }
    .mb-xl-n4 {
        margin-bottom: -1.5rem !important;
    }
    .mb-xl-n5 {
        margin-bottom: -2rem !important;
    }
    .mb-xl-n6 {
        margin-bottom: -2.5rem !important;
    }
    .mb-xl-n7 {
        margin-bottom: -3rem !important;
    }
    .mb-xl-n8 {
        margin-bottom: -4rem !important;
    }
    .mb-xl-n9 {
        margin-bottom: -5rem !important;
    }
    .mb-xl-n10 {
        margin-bottom: -6rem !important;
    }
    .ms-xl-n1 {
        margin-left: -0.25rem !important;
    }
    .ms-xl-n2 {
        margin-left: -0.5rem !important;
    }
    .ms-xl-n3 {
        margin-left: -1rem !important;
    }
    .ms-xl-n4 {
        margin-left: -1.5rem !important;
    }
    .ms-xl-n5 {
        margin-left: -2rem !important;
    }
    .ms-xl-n6 {
        margin-left: -2.5rem !important;
    }
    .ms-xl-n7 {
        margin-left: -3rem !important;
    }
    .ms-xl-n8 {
        margin-left: -4rem !important;
    }
    .ms-xl-n9 {
        margin-left: -5rem !important;
    }
    .ms-xl-n10 {
        margin-left: -6rem !important;
    }
    .p-xl-0 {
        padding: 0 !important;
    }
    .p-xl-1 {
        padding: 0.25rem !important;
    }
    .p-xl-2 {
        padding: 0.5rem !important;
    }
    .p-xl-3 {
        padding: 1rem !important;
    }
    .p-xl-4 {
        padding: 1.5rem !important;
    }
    .p-xl-5 {
        padding: 2rem !important;
    }
    .p-xl-6 {
        padding: 2.5rem !important;
    }
    .p-xl-7 {
        padding: 3rem !important;
    }
    .p-xl-8 {
        padding: 4rem !important;
    }
    .p-xl-9 {
        padding: 5rem !important;
    }
    .p-xl-10 {
        padding: 6rem !important;
    }
    .px-xl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .px-xl-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }
    .px-xl-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    .px-xl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .px-xl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
    .px-xl-5 {
        padding-right: 2rem !important;
        padding-left: 2rem !important;
    }
    .px-xl-6 {
        padding-right: 2.5rem !important;
        padding-left: 2.5rem !important;
    }
    .px-xl-7 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }
    .px-xl-8 {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
    }
    .px-xl-9 {
        padding-right: 5rem !important;
        padding-left: 5rem !important;
    }
    .px-xl-10 {
        padding-right: 6rem !important;
        padding-left: 6rem !important;
    }
    .py-xl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .py-xl-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }
    .py-xl-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
    .py-xl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
    .py-xl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
    .py-xl-5 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }
    .py-xl-6 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
    }
    .py-xl-7 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
    .py-xl-8 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }
    .py-xl-9 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }
    .py-xl-10 {
        padding-top: 6rem !important;
        padding-bottom: 6rem !important;
    }
    .pt-xl-0 {
        padding-top: 0 !important;
    }
    .pt-xl-1 {
        padding-top: 0.25rem !important;
    }
    .pt-xl-2 {
        padding-top: 0.5rem !important;
    }
    .pt-xl-3 {
        padding-top: 1rem !important;
    }
    .pt-xl-4 {
        padding-top: 1.5rem !important;
    }
    .pt-xl-5 {
        padding-top: 2rem !important;
    }
    .pt-xl-6 {
        padding-top: 2.5rem !important;
    }
    .pt-xl-7 {
        padding-top: 3rem !important;
    }
    .pt-xl-8 {
        padding-top: 4rem !important;
    }
    .pt-xl-9 {
        padding-top: 5rem !important;
    }
    .pt-xl-10 {
        padding-top: 6rem !important;
    }
    .pe-xl-0 {
        padding-right: 0 !important;
    }
    .pe-xl-1 {
        padding-right: 0.25rem !important;
    }
    .pe-xl-2 {
        padding-right: 0.5rem !important;
    }
    .pe-xl-3 {
        padding-right: 1rem !important;
    }
    .pe-xl-4 {
        padding-right: 1.5rem !important;
    }
    .pe-xl-5 {
        padding-right: 2rem !important;
    }
    .pe-xl-6 {
        padding-right: 2.5rem !important;
    }
    .pe-xl-7 {
        padding-right: 3rem !important;
    }
    .pe-xl-8 {
        padding-right: 4rem !important;
    }
    .pe-xl-9 {
        padding-right: 5rem !important;
    }
    .pe-xl-10 {
        padding-right: 6rem !important;
    }
    .pb-xl-0 {
        padding-bottom: 0 !important;
    }
    .pb-xl-1 {
        padding-bottom: 0.25rem !important;
    }
    .pb-xl-2 {
        padding-bottom: 0.5rem !important;
    }
    .pb-xl-3 {
        padding-bottom: 1rem !important;
    }
    .pb-xl-4 {
        padding-bottom: 1.5rem !important;
    }
    .pb-xl-5 {
        padding-bottom: 2rem !important;
    }
    .pb-xl-6 {
        padding-bottom: 2.5rem !important;
    }
    .pb-xl-7 {
        padding-bottom: 3rem !important;
    }
    .pb-xl-8 {
        padding-bottom: 4rem !important;
    }
    .pb-xl-9 {
        padding-bottom: 5rem !important;
    }
    .pb-xl-10 {
        padding-bottom: 6rem !important;
    }
    .ps-xl-0 {
        padding-left: 0 !important;
    }
    .ps-xl-1 {
        padding-left: 0.25rem !important;
    }
    .ps-xl-2 {
        padding-left: 0.5rem !important;
    }
    .ps-xl-3 {
        padding-left: 1rem !important;
    }
    .ps-xl-4 {
        padding-left: 1.5rem !important;
    }
    .ps-xl-5 {
        padding-left: 2rem !important;
    }
    .ps-xl-6 {
        padding-left: 2.5rem !important;
    }
    .ps-xl-7 {
        padding-left: 3rem !important;
    }
    .ps-xl-8 {
        padding-left: 4rem !important;
    }
    .ps-xl-9 {
        padding-left: 5rem !important;
    }
    .ps-xl-10 {
        padding-left: 6rem !important;
    }
    .gap-xl-0 {
        gap: 0 !important;
    }
    .gap-xl-1 {
        gap: 0.25rem !important;
    }
    .gap-xl-2 {
        gap: 0.5rem !important;
    }
    .gap-xl-3 {
        gap: 1rem !important;
    }
    .gap-xl-4 {
        gap: 1.5rem !important;
    }
    .gap-xl-5 {
        gap: 2rem !important;
    }
    .gap-xl-6 {
        gap: 2.5rem !important;
    }
    .gap-xl-7 {
        gap: 3rem !important;
    }
    .gap-xl-8 {
        gap: 4rem !important;
    }
    .gap-xl-9 {
        gap: 5rem !important;
    }
    .gap-xl-10 {
        gap: 6rem !important;
    }
    .row-gap-xl-0 {
        row-gap: 0 !important;
    }
    .row-gap-xl-1 {
        row-gap: 0.25rem !important;
    }
    .row-gap-xl-2 {
        row-gap: 0.5rem !important;
    }
    .row-gap-xl-3 {
        row-gap: 1rem !important;
    }
    .row-gap-xl-4 {
        row-gap: 1.5rem !important;
    }
    .row-gap-xl-5 {
        row-gap: 2rem !important;
    }
    .row-gap-xl-6 {
        row-gap: 2.5rem !important;
    }
    .row-gap-xl-7 {
        row-gap: 3rem !important;
    }
    .row-gap-xl-8 {
        row-gap: 4rem !important;
    }
    .row-gap-xl-9 {
        row-gap: 5rem !important;
    }
    .row-gap-xl-10 {
        row-gap: 6rem !important;
    }
    .column-gap-xl-0 {
        -moz-column-gap: 0 !important;
        column-gap: 0 !important;
    }
    .column-gap-xl-1 {
        -moz-column-gap: 0.25rem !important;
        column-gap: 0.25rem !important;
    }
    .column-gap-xl-2 {
        -moz-column-gap: 0.5rem !important;
        column-gap: 0.5rem !important;
    }
    .column-gap-xl-3 {
        -moz-column-gap: 1rem !important;
        column-gap: 1rem !important;
    }
    .column-gap-xl-4 {
        -moz-column-gap: 1.5rem !important;
        column-gap: 1.5rem !important;
    }
    .column-gap-xl-5 {
        -moz-column-gap: 2rem !important;
        column-gap: 2rem !important;
    }
    .column-gap-xl-6 {
        -moz-column-gap: 2.5rem !important;
        column-gap: 2.5rem !important;
    }
    .column-gap-xl-7 {
        -moz-column-gap: 3rem !important;
        column-gap: 3rem !important;
    }
    .column-gap-xl-8 {
        -moz-column-gap: 4rem !important;
        column-gap: 4rem !important;
    }
    .column-gap-xl-9 {
        -moz-column-gap: 5rem !important;
        column-gap: 5rem !important;
    }
    .column-gap-xl-10 {
        -moz-column-gap: 6rem !important;
        column-gap: 6rem !important;
    }
    .text-xl-start {
        text-align: left !important;
    }
    .text-xl-end {
        text-align: right !important;
    }
    .text-xl-center {
        text-align: center !important;
    }
    .rounded-top-xl {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-top-xl-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }
    .rounded-top-xl-1 {
        border-top-left-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-top-xl-2 {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-top-xl-3 {
        border-top-left-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-top-xl-4 {
        border-top-left-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-top-xl-5 {
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-top-xl-circle {
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important;
    }
    .rounded-top-xl-pill {
        border-top-left-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-end-xl {
        border-top-right-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-end-xl-0 {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    .rounded-end-xl-1 {
        border-top-right-radius: var(--bs-border-radius-sm) !important;
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-end-xl-2 {
        border-top-right-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-end-xl-3 {
        border-top-right-radius: var(--bs-border-radius-lg) !important;
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-end-xl-4 {
        border-top-right-radius: var(--bs-border-radius-xl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-end-xl-5 {
        border-top-right-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-end-xl-circle {
        border-top-right-radius: 50% !important;
        border-bottom-right-radius: 50% !important;
    }
    .rounded-end-xl-pill {
        border-top-right-radius: var(--bs-border-radius-pill) !important;
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-bottom-xl {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-bottom-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-bottom-xl-0 {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    .rounded-bottom-xl-1 {
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-bottom-xl-2 {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-bottom-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-bottom-xl-3 {
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-bottom-xl-4 {
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-bottom-xl-5 {
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-bottom-xl-circle {
        border-bottom-right-radius: 50% !important;
        border-bottom-left-radius: 50% !important;
    }
    .rounded-bottom-xl-pill {
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-start-xl {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-start-xl-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }
    .rounded-start-xl-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-top-left-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-start-xl-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-start-xl-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-top-left-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-start-xl-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-top-left-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-start-xl-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-start-xl-circle {
        border-bottom-left-radius: 50% !important;
        border-top-left-radius: 50% !important;
    }
    .rounded-start-xl-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-top-left-radius: var(--bs-border-radius-pill) !important;
    }
}
@media (min-width: 1400px) {
    .float-xxl-start {
        float: left !important;
    }
    .float-xxl-end {
        float: right !important;
    }
    .float-xxl-none {
        float: none !important;
    }
    .object-fit-xxl-contain {
        -o-object-fit: contain !important;
        object-fit: contain !important;
    }
    .object-fit-xxl-cover {
        -o-object-fit: cover !important;
        object-fit: cover !important;
    }
    .object-fit-xxl-fill {
        -o-object-fit: fill !important;
        object-fit: fill !important;
    }
    .object-fit-xxl-scale {
        -o-object-fit: scale-down !important;
        object-fit: scale-down !important;
    }
    .object-fit-xxl-none {
        -o-object-fit: none !important;
        object-fit: none !important;
    }
    .d-xxl-inline {
        display: inline !important;
    }
    .d-xxl-inline-block {
        display: inline-block !important;
    }
    .d-xxl-block {
        display: block !important;
    }
    .d-xxl-grid {
        display: grid !important;
    }
    .d-xxl-inline-grid {
        display: inline-grid !important;
    }
    .d-xxl-table {
        display: table !important;
    }
    .d-xxl-table-row {
        display: table-row !important;
    }
    .d-xxl-table-cell {
        display: table-cell !important;
    }
    .d-xxl-flex {
        display: flex !important;
    }
    .d-xxl-inline-flex {
        display: inline-flex !important;
    }
    .d-xxl-none {
        display: none !important;
    }
    .position-xxl-static {
        position: static !important;
    }
    .position-xxl-relative {
        position: relative !important;
    }
    .position-xxl-absolute {
        position: absolute !important;
    }
    .position-xxl-fixed {
        position: fixed !important;
    }
    .position-xxl-sticky {
        position: sticky !important;
    }
    .top-xxl-0 {
        top: 0 !important;
    }
    .top-xxl-50 {
        top: 50% !important;
    }
    .top-xxl-100 {
        top: 100% !important;
    }
    .bottom-xxl-0 {
        bottom: 0 !important;
    }
    .bottom-xxl-50 {
        bottom: 50% !important;
    }
    .bottom-xxl-100 {
        bottom: 100% !important;
    }
    .start-xxl-0 {
        left: 0 !important;
    }
    .start-xxl-50 {
        left: 50% !important;
    }
    .start-xxl-100 {
        left: 100% !important;
    }
    .translate-middle-xxl {
        transform: translate(-50%, -50%) !important;
    }
    .translate-middle-xxl-x {
        transform: translateX(-50%) !important;
    }
    .translate-middle-xxl-y {
        transform: translateY(-50%) !important;
    }
    .border-xxl {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-xxl-0 {
        border: 0 !important;
    }
    .border-top-xxl {
        border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-top-xxl-0 {
        border-top: 0 !important;
    }
    .border-end-xxl {
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-end-xxl-0 {
        border-right: 0 !important;
    }
    .border-bottom-xxl {
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-bottom-xxl-0 {
        border-bottom: 0 !important;
    }
    .border-start-xxl {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
    .border-start-xxl-0 {
        border-left: 0 !important;
    }
    .border-xxl-primary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xxl-secondary {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xxl-success {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xxl-info {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xxl-warning {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xxl-danger {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xxl-light {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xxl-dark {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xxl-white {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
    }
    .border-xxl-black {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
    }
    .w-xxl-25 {
        width: 25% !important;
    }
    .w-xxl-50 {
        width: 50% !important;
    }
    .w-xxl-75 {
        width: 75% !important;
    }
    .w-xxl-100 {
        width: 100% !important;
    }
    .w-xxl-auto {
        width: auto !important;
    }
    .mw-xxl-100 {
        max-width: 100% !important;
    }
    .vw-xxl-100 {
        width: 100vw !important;
    }
    .min-vw-xxl-100 {
        min-width: 100vw !important;
    }
    .h-xxl-25 {
        height: 25% !important;
    }
    .h-xxl-50 {
        height: 50% !important;
    }
    .h-xxl-75 {
        height: 75% !important;
    }
    .h-xxl-100 {
        height: 100% !important;
    }
    .h-xxl-auto {
        height: auto !important;
    }
    .mh-xxl-100 {
        max-height: 100% !important;
    }
    .min-vh-xxl-100 {
        min-height: 100vh !important;
    }
    .flex-xxl-fill {
        flex: 1 1 auto !important;
    }
    .flex-xxl-row {
        flex-direction: row !important;
    }
    .flex-xxl-column {
        flex-direction: column !important;
    }
    .flex-xxl-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-xxl-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-xxl-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-xxl-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-xxl-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-xxl-shrink-1 {
        flex-shrink: 1 !important;
    }
    .flex-xxl-wrap {
        flex-wrap: wrap !important;
    }
    .flex-xxl-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-xxl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .justify-content-xxl-start {
        justify-content: flex-start !important;
    }
    .justify-content-xxl-end {
        justify-content: flex-end !important;
    }
    .justify-content-xxl-center {
        justify-content: center !important;
    }
    .justify-content-xxl-between {
        justify-content: space-between !important;
    }
    .justify-content-xxl-around {
        justify-content: space-around !important;
    }
    .justify-content-xxl-evenly {
        justify-content: space-evenly !important;
    }
    .align-items-xxl-start {
        align-items: flex-start !important;
    }
    .align-items-xxl-end {
        align-items: flex-end !important;
    }
    .align-items-xxl-center {
        align-items: center !important;
    }
    .align-items-xxl-baseline {
        align-items: baseline !important;
    }
    .align-items-xxl-stretch {
        align-items: stretch !important;
    }
    .align-content-xxl-start {
        align-content: flex-start !important;
    }
    .align-content-xxl-end {
        align-content: flex-end !important;
    }
    .align-content-xxl-center {
        align-content: center !important;
    }
    .align-content-xxl-between {
        align-content: space-between !important;
    }
    .align-content-xxl-around {
        align-content: space-around !important;
    }
    .align-content-xxl-stretch {
        align-content: stretch !important;
    }
    .align-self-xxl-auto {
        align-self: auto !important;
    }
    .align-self-xxl-start {
        align-self: flex-start !important;
    }
    .align-self-xxl-end {
        align-self: flex-end !important;
    }
    .align-self-xxl-center {
        align-self: center !important;
    }
    .align-self-xxl-baseline {
        align-self: baseline !important;
    }
    .align-self-xxl-stretch {
        align-self: stretch !important;
    }
    .order-xxl-first {
        order: -1 !important;
    }
    .order-xxl-0 {
        order: 0 !important;
    }
    .order-xxl-1 {
        order: 1 !important;
    }
    .order-xxl-2 {
        order: 2 !important;
    }
    .order-xxl-3 {
        order: 3 !important;
    }
    .order-xxl-4 {
        order: 4 !important;
    }
    .order-xxl-5 {
        order: 5 !important;
    }
    .order-xxl-last {
        order: 6 !important;
    }
    .m-xxl-0 {
        margin: 0 !important;
    }
    .m-xxl-1 {
        margin: 0.25rem !important;
    }
    .m-xxl-2 {
        margin: 0.5rem !important;
    }
    .m-xxl-3 {
        margin: 1rem !important;
    }
    .m-xxl-4 {
        margin: 1.5rem !important;
    }
    .m-xxl-5 {
        margin: 2rem !important;
    }
    .m-xxl-6 {
        margin: 2.5rem !important;
    }
    .m-xxl-7 {
        margin: 3rem !important;
    }
    .m-xxl-8 {
        margin: 4rem !important;
    }
    .m-xxl-9 {
        margin: 5rem !important;
    }
    .m-xxl-10 {
        margin: 6rem !important;
    }
    .m-xxl-auto {
        margin: auto !important;
    }
    .mx-xxl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .mx-xxl-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }
    .mx-xxl-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }
    .mx-xxl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }
    .mx-xxl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }
    .mx-xxl-5 {
        margin-right: 2rem !important;
        margin-left: 2rem !important;
    }
    .mx-xxl-6 {
        margin-right: 2.5rem !important;
        margin-left: 2.5rem !important;
    }
    .mx-xxl-7 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }
    .mx-xxl-8 {
        margin-right: 4rem !important;
        margin-left: 4rem !important;
    }
    .mx-xxl-9 {
        margin-right: 5rem !important;
        margin-left: 5rem !important;
    }
    .mx-xxl-10 {
        margin-right: 6rem !important;
        margin-left: 6rem !important;
    }
    .mx-xxl-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .my-xxl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .my-xxl-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
    .my-xxl-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    .my-xxl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    .my-xxl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
    .my-xxl-5 {
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
    }
    .my-xxl-6 {
        margin-top: 2.5rem !important;
        margin-bottom: 2.5rem !important;
    }
    .my-xxl-7 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
    .my-xxl-8 {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
    }
    .my-xxl-9 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important;
    }
    .my-xxl-10 {
        margin-top: 6rem !important;
        margin-bottom: 6rem !important;
    }
    .my-xxl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }
    .mt-xxl-0 {
        margin-top: 0 !important;
    }
    .mt-xxl-1 {
        margin-top: 0.25rem !important;
    }
    .mt-xxl-2 {
        margin-top: 0.5rem !important;
    }
    .mt-xxl-3 {
        margin-top: 1rem !important;
    }
    .mt-xxl-4 {
        margin-top: 1.5rem !important;
    }
    .mt-xxl-5 {
        margin-top: 2rem !important;
    }
    .mt-xxl-6 {
        margin-top: 2.5rem !important;
    }
    .mt-xxl-7 {
        margin-top: 3rem !important;
    }
    .mt-xxl-8 {
        margin-top: 4rem !important;
    }
    .mt-xxl-9 {
        margin-top: 5rem !important;
    }
    .mt-xxl-10 {
        margin-top: 6rem !important;
    }
    .mt-xxl-auto {
        margin-top: auto !important;
    }
    .me-xxl-0 {
        margin-right: 0 !important;
    }
    .me-xxl-1 {
        margin-right: 0.25rem !important;
    }
    .me-xxl-2 {
        margin-right: 0.5rem !important;
    }
    .me-xxl-3 {
        margin-right: 1rem !important;
    }
    .me-xxl-4 {
        margin-right: 1.5rem !important;
    }
    .me-xxl-5 {
        margin-right: 2rem !important;
    }
    .me-xxl-6 {
        margin-right: 2.5rem !important;
    }
    .me-xxl-7 {
        margin-right: 3rem !important;
    }
    .me-xxl-8 {
        margin-right: 4rem !important;
    }
    .me-xxl-9 {
        margin-right: 5rem !important;
    }
    .me-xxl-10 {
        margin-right: 6rem !important;
    }
    .me-xxl-auto {
        margin-right: auto !important;
    }
    .mb-xxl-0 {
        margin-bottom: 0 !important;
    }
    .mb-xxl-1 {
        margin-bottom: 0.25rem !important;
    }
    .mb-xxl-2 {
        margin-bottom: 0.5rem !important;
    }
    .mb-xxl-3 {
        margin-bottom: 1rem !important;
    }
    .mb-xxl-4 {
        margin-bottom: 1.5rem !important;
    }
    .mb-xxl-5 {
        margin-bottom: 2rem !important;
    }
    .mb-xxl-6 {
        margin-bottom: 2.5rem !important;
    }
    .mb-xxl-7 {
        margin-bottom: 3rem !important;
    }
    .mb-xxl-8 {
        margin-bottom: 4rem !important;
    }
    .mb-xxl-9 {
        margin-bottom: 5rem !important;
    }
    .mb-xxl-10 {
        margin-bottom: 6rem !important;
    }
    .mb-xxl-auto {
        margin-bottom: auto !important;
    }
    .ms-xxl-0 {
        margin-left: 0 !important;
    }
    .ms-xxl-1 {
        margin-left: 0.25rem !important;
    }
    .ms-xxl-2 {
        margin-left: 0.5rem !important;
    }
    .ms-xxl-3 {
        margin-left: 1rem !important;
    }
    .ms-xxl-4 {
        margin-left: 1.5rem !important;
    }
    .ms-xxl-5 {
        margin-left: 2rem !important;
    }
    .ms-xxl-6 {
        margin-left: 2.5rem !important;
    }
    .ms-xxl-7 {
        margin-left: 3rem !important;
    }
    .ms-xxl-8 {
        margin-left: 4rem !important;
    }
    .ms-xxl-9 {
        margin-left: 5rem !important;
    }
    .ms-xxl-10 {
        margin-left: 6rem !important;
    }
    .ms-xxl-auto {
        margin-left: auto !important;
    }
    .m-xxl-n1 {
        margin: -0.25rem !important;
    }
    .m-xxl-n2 {
        margin: -0.5rem !important;
    }
    .m-xxl-n3 {
        margin: -1rem !important;
    }
    .m-xxl-n4 {
        margin: -1.5rem !important;
    }
    .m-xxl-n5 {
        margin: -2rem !important;
    }
    .m-xxl-n6 {
        margin: -2.5rem !important;
    }
    .m-xxl-n7 {
        margin: -3rem !important;
    }
    .m-xxl-n8 {
        margin: -4rem !important;
    }
    .m-xxl-n9 {
        margin: -5rem !important;
    }
    .m-xxl-n10 {
        margin: -6rem !important;
    }
    .mx-xxl-n1 {
        margin-right: -0.25rem !important;
        margin-left: -0.25rem !important;
    }
    .mx-xxl-n2 {
        margin-right: -0.5rem !important;
        margin-left: -0.5rem !important;
    }
    .mx-xxl-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important;
    }
    .mx-xxl-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important;
    }
    .mx-xxl-n5 {
        margin-right: -2rem !important;
        margin-left: -2rem !important;
    }
    .mx-xxl-n6 {
        margin-right: -2.5rem !important;
        margin-left: -2.5rem !important;
    }
    .mx-xxl-n7 {
        margin-right: -3rem !important;
        margin-left: -3rem !important;
    }
    .mx-xxl-n8 {
        margin-right: -4rem !important;
        margin-left: -4rem !important;
    }
    .mx-xxl-n9 {
        margin-right: -5rem !important;
        margin-left: -5rem !important;
    }
    .mx-xxl-n10 {
        margin-right: -6rem !important;
        margin-left: -6rem !important;
    }
    .my-xxl-n1 {
        margin-top: -0.25rem !important;
        margin-bottom: -0.25rem !important;
    }
    .my-xxl-n2 {
        margin-top: -0.5rem !important;
        margin-bottom: -0.5rem !important;
    }
    .my-xxl-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important;
    }
    .my-xxl-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important;
    }
    .my-xxl-n5 {
        margin-top: -2rem !important;
        margin-bottom: -2rem !important;
    }
    .my-xxl-n6 {
        margin-top: -2.5rem !important;
        margin-bottom: -2.5rem !important;
    }
    .my-xxl-n7 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important;
    }
    .my-xxl-n8 {
        margin-top: -4rem !important;
        margin-bottom: -4rem !important;
    }
    .my-xxl-n9 {
        margin-top: -5rem !important;
        margin-bottom: -5rem !important;
    }
    .my-xxl-n10 {
        margin-top: -6rem !important;
        margin-bottom: -6rem !important;
    }
    .mt-xxl-n1 {
        margin-top: -0.25rem !important;
    }
    .mt-xxl-n2 {
        margin-top: -0.5rem !important;
    }
    .mt-xxl-n3 {
        margin-top: -1rem !important;
    }
    .mt-xxl-n4 {
        margin-top: -1.5rem !important;
    }
    .mt-xxl-n5 {
        margin-top: -2rem !important;
    }
    .mt-xxl-n6 {
        margin-top: -2.5rem !important;
    }
    .mt-xxl-n7 {
        margin-top: -3rem !important;
    }
    .mt-xxl-n8 {
        margin-top: -4rem !important;
    }
    .mt-xxl-n9 {
        margin-top: -5rem !important;
    }
    .mt-xxl-n10 {
        margin-top: -6rem !important;
    }
    .me-xxl-n1 {
        margin-right: -0.25rem !important;
    }
    .me-xxl-n2 {
        margin-right: -0.5rem !important;
    }
    .me-xxl-n3 {
        margin-right: -1rem !important;
    }
    .me-xxl-n4 {
        margin-right: -1.5rem !important;
    }
    .me-xxl-n5 {
        margin-right: -2rem !important;
    }
    .me-xxl-n6 {
        margin-right: -2.5rem !important;
    }
    .me-xxl-n7 {
        margin-right: -3rem !important;
    }
    .me-xxl-n8 {
        margin-right: -4rem !important;
    }
    .me-xxl-n9 {
        margin-right: -5rem !important;
    }
    .me-xxl-n10 {
        margin-right: -6rem !important;
    }
    .mb-xxl-n1 {
        margin-bottom: -0.25rem !important;
    }
    .mb-xxl-n2 {
        margin-bottom: -0.5rem !important;
    }
    .mb-xxl-n3 {
        margin-bottom: -1rem !important;
    }
    .mb-xxl-n4 {
        margin-bottom: -1.5rem !important;
    }
    .mb-xxl-n5 {
        margin-bottom: -2rem !important;
    }
    .mb-xxl-n6 {
        margin-bottom: -2.5rem !important;
    }
    .mb-xxl-n7 {
        margin-bottom: -3rem !important;
    }
    .mb-xxl-n8 {
        margin-bottom: -4rem !important;
    }
    .mb-xxl-n9 {
        margin-bottom: -5rem !important;
    }
    .mb-xxl-n10 {
        margin-bottom: -6rem !important;
    }
    .ms-xxl-n1 {
        margin-left: -0.25rem !important;
    }
    .ms-xxl-n2 {
        margin-left: -0.5rem !important;
    }
    .ms-xxl-n3 {
        margin-left: -1rem !important;
    }
    .ms-xxl-n4 {
        margin-left: -1.5rem !important;
    }
    .ms-xxl-n5 {
        margin-left: -2rem !important;
    }
    .ms-xxl-n6 {
        margin-left: -2.5rem !important;
    }
    .ms-xxl-n7 {
        margin-left: -3rem !important;
    }
    .ms-xxl-n8 {
        margin-left: -4rem !important;
    }
    .ms-xxl-n9 {
        margin-left: -5rem !important;
    }
    .ms-xxl-n10 {
        margin-left: -6rem !important;
    }
    .p-xxl-0 {
        padding: 0 !important;
    }
    .p-xxl-1 {
        padding: 0.25rem !important;
    }
    .p-xxl-2 {
        padding: 0.5rem !important;
    }
    .p-xxl-3 {
        padding: 1rem !important;
    }
    .p-xxl-4 {
        padding: 1.5rem !important;
    }
    .p-xxl-5 {
        padding: 2rem !important;
    }
    .p-xxl-6 {
        padding: 2.5rem !important;
    }
    .p-xxl-7 {
        padding: 3rem !important;
    }
    .p-xxl-8 {
        padding: 4rem !important;
    }
    .p-xxl-9 {
        padding: 5rem !important;
    }
    .p-xxl-10 {
        padding: 6rem !important;
    }
    .px-xxl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .px-xxl-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }
    .px-xxl-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    .px-xxl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .px-xxl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
    .px-xxl-5 {
        padding-right: 2rem !important;
        padding-left: 2rem !important;
    }
    .px-xxl-6 {
        padding-right: 2.5rem !important;
        padding-left: 2.5rem !important;
    }
    .px-xxl-7 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }
    .px-xxl-8 {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
    }
    .px-xxl-9 {
        padding-right: 5rem !important;
        padding-left: 5rem !important;
    }
    .px-xxl-10 {
        padding-right: 6rem !important;
        padding-left: 6rem !important;
    }
    .py-xxl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .py-xxl-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }
    .py-xxl-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
    .py-xxl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
    .py-xxl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
    .py-xxl-5 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }
    .py-xxl-6 {
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
    }
    .py-xxl-7 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
    .py-xxl-8 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }
    .py-xxl-9 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }
    .py-xxl-10 {
        padding-top: 6rem !important;
        padding-bottom: 6rem !important;
    }
    .pt-xxl-0 {
        padding-top: 0 !important;
    }
    .pt-xxl-1 {
        padding-top: 0.25rem !important;
    }
    .pt-xxl-2 {
        padding-top: 0.5rem !important;
    }
    .pt-xxl-3 {
        padding-top: 1rem !important;
    }
    .pt-xxl-4 {
        padding-top: 1.5rem !important;
    }
    .pt-xxl-5 {
        padding-top: 2rem !important;
    }
    .pt-xxl-6 {
        padding-top: 2.5rem !important;
    }
    .pt-xxl-7 {
        padding-top: 3rem !important;
    }
    .pt-xxl-8 {
        padding-top: 4rem !important;
    }
    .pt-xxl-9 {
        padding-top: 5rem !important;
    }
    .pt-xxl-10 {
        padding-top: 6rem !important;
    }
    .pe-xxl-0 {
        padding-right: 0 !important;
    }
    .pe-xxl-1 {
        padding-right: 0.25rem !important;
    }
    .pe-xxl-2 {
        padding-right: 0.5rem !important;
    }
    .pe-xxl-3 {
        padding-right: 1rem !important;
    }
    .pe-xxl-4 {
        padding-right: 1.5rem !important;
    }
    .pe-xxl-5 {
        padding-right: 2rem !important;
    }
    .pe-xxl-6 {
        padding-right: 2.5rem !important;
    }
    .pe-xxl-7 {
        padding-right: 3rem !important;
    }
    .pe-xxl-8 {
        padding-right: 4rem !important;
    }
    .pe-xxl-9 {
        padding-right: 5rem !important;
    }
    .pe-xxl-10 {
        padding-right: 6rem !important;
    }
    .pb-xxl-0 {
        padding-bottom: 0 !important;
    }
    .pb-xxl-1 {
        padding-bottom: 0.25rem !important;
    }
    .pb-xxl-2 {
        padding-bottom: 0.5rem !important;
    }
    .pb-xxl-3 {
        padding-bottom: 1rem !important;
    }
    .pb-xxl-4 {
        padding-bottom: 1.5rem !important;
    }
    .pb-xxl-5 {
        padding-bottom: 2rem !important;
    }
    .pb-xxl-6 {
        padding-bottom: 2.5rem !important;
    }
    .pb-xxl-7 {
        padding-bottom: 3rem !important;
    }
    .pb-xxl-8 {
        padding-bottom: 4rem !important;
    }
    .pb-xxl-9 {
        padding-bottom: 5rem !important;
    }
    .pb-xxl-10 {
        padding-bottom: 6rem !important;
    }
    .ps-xxl-0 {
        padding-left: 0 !important;
    }
    .ps-xxl-1 {
        padding-left: 0.25rem !important;
    }
    .ps-xxl-2 {
        padding-left: 0.5rem !important;
    }
    .ps-xxl-3 {
        padding-left: 1rem !important;
    }
    .ps-xxl-4 {
        padding-left: 1.5rem !important;
    }
    .ps-xxl-5 {
        padding-left: 2rem !important;
    }
    .ps-xxl-6 {
        padding-left: 2.5rem !important;
    }
    .ps-xxl-7 {
        padding-left: 3rem !important;
    }
    .ps-xxl-8 {
        padding-left: 4rem !important;
    }
    .ps-xxl-9 {
        padding-left: 5rem !important;
    }
    .ps-xxl-10 {
        padding-left: 6rem !important;
    }
    .gap-xxl-0 {
        gap: 0 !important;
    }
    .gap-xxl-1 {
        gap: 0.25rem !important;
    }
    .gap-xxl-2 {
        gap: 0.5rem !important;
    }
    .gap-xxl-3 {
        gap: 1rem !important;
    }
    .gap-xxl-4 {
        gap: 1.5rem !important;
    }
    .gap-xxl-5 {
        gap: 2rem !important;
    }
    .gap-xxl-6 {
        gap: 2.5rem !important;
    }
    .gap-xxl-7 {
        gap: 3rem !important;
    }
    .gap-xxl-8 {
        gap: 4rem !important;
    }
    .gap-xxl-9 {
        gap: 5rem !important;
    }
    .gap-xxl-10 {
        gap: 6rem !important;
    }
    .row-gap-xxl-0 {
        row-gap: 0 !important;
    }
    .row-gap-xxl-1 {
        row-gap: 0.25rem !important;
    }
    .row-gap-xxl-2 {
        row-gap: 0.5rem !important;
    }
    .row-gap-xxl-3 {
        row-gap: 1rem !important;
    }
    .row-gap-xxl-4 {
        row-gap: 1.5rem !important;
    }
    .row-gap-xxl-5 {
        row-gap: 2rem !important;
    }
    .row-gap-xxl-6 {
        row-gap: 2.5rem !important;
    }
    .row-gap-xxl-7 {
        row-gap: 3rem !important;
    }
    .row-gap-xxl-8 {
        row-gap: 4rem !important;
    }
    .row-gap-xxl-9 {
        row-gap: 5rem !important;
    }
    .row-gap-xxl-10 {
        row-gap: 6rem !important;
    }
    .column-gap-xxl-0 {
        -moz-column-gap: 0 !important;
        column-gap: 0 !important;
    }
    .column-gap-xxl-1 {
        -moz-column-gap: 0.25rem !important;
        column-gap: 0.25rem !important;
    }
    .column-gap-xxl-2 {
        -moz-column-gap: 0.5rem !important;
        column-gap: 0.5rem !important;
    }
    .column-gap-xxl-3 {
        -moz-column-gap: 1rem !important;
        column-gap: 1rem !important;
    }
    .column-gap-xxl-4 {
        -moz-column-gap: 1.5rem !important;
        column-gap: 1.5rem !important;
    }
    .column-gap-xxl-5 {
        -moz-column-gap: 2rem !important;
        column-gap: 2rem !important;
    }
    .column-gap-xxl-6 {
        -moz-column-gap: 2.5rem !important;
        column-gap: 2.5rem !important;
    }
    .column-gap-xxl-7 {
        -moz-column-gap: 3rem !important;
        column-gap: 3rem !important;
    }
    .column-gap-xxl-8 {
        -moz-column-gap: 4rem !important;
        column-gap: 4rem !important;
    }
    .column-gap-xxl-9 {
        -moz-column-gap: 5rem !important;
        column-gap: 5rem !important;
    }
    .column-gap-xxl-10 {
        -moz-column-gap: 6rem !important;
        column-gap: 6rem !important;
    }
    .text-xxl-start {
        text-align: left !important;
    }
    .text-xxl-end {
        text-align: right !important;
    }
    .text-xxl-center {
        text-align: center !important;
    }
    .rounded-top-xxl {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-top-xxl-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }
    .rounded-top-xxl-1 {
        border-top-left-radius: var(--bs-border-radius-sm) !important;
        border-top-right-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-top-xxl-2 {
        border-top-left-radius: var(--bs-border-radius) !important;
        border-top-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-top-xxl-3 {
        border-top-left-radius: var(--bs-border-radius-lg) !important;
        border-top-right-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-top-xxl-4 {
        border-top-left-radius: var(--bs-border-radius-xl) !important;
        border-top-right-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-top-xxl-5 {
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-right-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-top-xxl-circle {
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important;
    }
    .rounded-top-xxl-pill {
        border-top-left-radius: var(--bs-border-radius-pill) !important;
        border-top-right-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-end-xxl {
        border-top-right-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-end-xxl-0 {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    .rounded-end-xxl-1 {
        border-top-right-radius: var(--bs-border-radius-sm) !important;
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-end-xxl-2 {
        border-top-right-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important;
    }
    .rounded-end-xxl-3 {
        border-top-right-radius: var(--bs-border-radius-lg) !important;
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-end-xxl-4 {
        border-top-right-radius: var(--bs-border-radius-xl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-end-xxl-5 {
        border-top-right-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-end-xxl-circle {
        border-top-right-radius: 50% !important;
        border-bottom-right-radius: 50% !important;
    }
    .rounded-end-xxl-pill {
        border-top-right-radius: var(--bs-border-radius-pill) !important;
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-bottom-xxl {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-bottom-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-bottom-xxl-0 {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    .rounded-bottom-xxl-1 {
        border-bottom-right-radius: var(--bs-border-radius-sm) !important;
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-bottom-xxl-2 {
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-bottom-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-bottom-xxl-3 {
        border-bottom-right-radius: var(--bs-border-radius-lg) !important;
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-bottom-xxl-4 {
        border-bottom-right-radius: var(--bs-border-radius-xl) !important;
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-bottom-xxl-5 {
        border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-bottom-xxl-circle {
        border-bottom-right-radius: 50% !important;
        border-bottom-left-radius: 50% !important;
    }
    .rounded-bottom-xxl-pill {
        border-bottom-right-radius: var(--bs-border-radius-pill) !important;
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
    }
    .rounded-start-xxl {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-start-xxl-0 {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }
    .rounded-start-xxl-1 {
        border-bottom-left-radius: var(--bs-border-radius-sm) !important;
        border-top-left-radius: var(--bs-border-radius-sm) !important;
    }
    .rounded-start-xxl-2 {
        border-bottom-left-radius: var(--bs-border-radius) !important;
        border-top-left-radius: var(--bs-border-radius) !important;
    }
    .rounded-start-xxl-3 {
        border-bottom-left-radius: var(--bs-border-radius-lg) !important;
        border-top-left-radius: var(--bs-border-radius-lg) !important;
    }
    .rounded-start-xxl-4 {
        border-bottom-left-radius: var(--bs-border-radius-xl) !important;
        border-top-left-radius: var(--bs-border-radius-xl) !important;
    }
    .rounded-start-xxl-5 {
        border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
        border-top-left-radius: var(--bs-border-radius-xxl) !important;
    }
    .rounded-start-xxl-circle {
        border-bottom-left-radius: 50% !important;
        border-top-left-radius: 50% !important;
    }
    .rounded-start-xxl-pill {
        border-bottom-left-radius: var(--bs-border-radius-pill) !important;
        border-top-left-radius: var(--bs-border-radius-pill) !important;
    }
}
// `xxl` applies to x-large devices (large desktops, less than 1400px)
@media screen and (max-width: 1399.98px) {
}
// `xl` applies to large devices (desktops, less than 1200px)
@media screen and (max-width: 1199.98px) {
}
// `lg` applies to medium devices (tablets, less than 992px)
@media screen and (max-width: 991.98px) {
    br {
        display: none !important;
    }

    .pb-350 {
        padding-bottom: 250px;
    }
    .card-portfolio-content {
        opacity: 1 !important;
        padding-top: 30px !important;
    }
    .fs-200 {
        font-size: 100px;
    }
    .fs-160 {
        font-size: 80px;
    }
    // padding
    .py-120 {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .pt-120 {
        padding-top: 80px;
    }
    .pb-120 {
        padding-bottom: 80px;
    }
    .pt-250 {
        padding-top: 100px;
    }
    .pb-250 {
        padding-bottom: 100px;
    }
}
// `md` applies to small devices (landscape phones, less than 768px)
@media screen and (max-width: 767.98px) {
}
// `sm` applies to x-small devices (portrait phones, less than 576px)
@media screen and (max-width: 575.98px) {
    .pt-200 {
        padding-top: 150px;
    }
    .pb-250 {
        padding-bottom: 200px;
    }
    .pb-300 {
        padding-bottom: 200px;
    }
    .pt-300 {
        padding-top: 200px;
    }
    .pt-250 {
        padding-top: 200px;
    }
    .pb-350 {
        padding-bottom: 200px;
    }
    .ds-1 {
        font-size: 54px;
    }
    .fs-120 {
        font-size: 64px;
    }
    .fs-128 {
        font-size: 64px;
    }
    .fs-200 {
        font-size: 80px;
    }
    h1 {
        font-size: 48px;
    }
}
// `xs` applies to x-small devices (portrait phones, less than 475px)
@media screen and (max-width: 474.98px) {
    .py-120 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pt-120 {
        padding-top: 50px;
    }
    .pb-120 {
        padding-bottom: 50px;
    }
    .pt-250 {
        padding-top: 80px;
    }
    .pb-250 {
        padding-bottom: 80px;
    }
}
// `lg` applies to medium devices (PC, bigger than 992px)
@media screen and (min-width: 992px) {
}
