/* Popup Content */
.popup-content {
    background: #fff;

    .popup-icon {
        img {
            height: 100px;
            width: 100px;
            object-fit: contain;
        }
    }
}
