// Dropdown menu

.dropdown-menu {
    line-height: 1.5rem;
    border: none;
    box-shadow: var(--tc-shadow-1);
    border-radius: 6px;
    z-index: 1021;
    padding: 1rem 0rem;
}

// Dropdown item
.dropdown-item {
    padding: 0.3rem 1.5rem;
    display: flex;
    align-items: center;
    font-weight: 600;
}
// Dropdown toggle arrow
.dropdown-toggle::after {
    margin-left: 0.255em;
    vertical-align: 0.255em;
    font-family: 'bootstrap-icons' !important;
    content: '\F282';
    border-top: 0rem;
    border-right: 0rem;
    border-bottom: 0;
    border-left: 0rem;
    float: right;
}
