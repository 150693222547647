/*Scroll to top*/
.btn-scroll-top {
    position: fixed;
    right: 1.5rem;
    bottom: 1.5rem;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    display: block;
    border-radius: 10px;
    box-shadow: inset 0 0 0 0.1rem rgba(227, 229, 233, 0.25);
    z-index: 1010;
    opacity: 0;
    visibility: hidden;
    transform: translateY(0.75rem);
    transition:
        all 0.2s linear,
        margin-right 0s;
    background-color: var(--tc-system-white);
    &:after {
        position: absolute;
        font-family: 'bootstrap-icons' !important;
        content: '\F145';
        text-align: center;
        line-height: 3rem;
        font-size: 1.5rem;
        color: var(--tc-theme-primary);
        left: 0;
        top: 0;
        height: 3rem;
        width: 3rem;
        cursor: pointer;
        display: block;
        z-index: 1;
        transition: all 0.2s linear;
    }
    &.active-progress {
        visibility: visible;
        transform: translateY(0);
        @media (min-width: 320px) {
            opacity: 1;
        }
    }
    & svg path {
        fill: none;
    }
    & svg.progress-square path {
        stroke: var(--tc-theme-primary);
        stroke-width: 2;
        box-sizing: border-box;
        transition: all 0.2s linear;
    }
}
.btn {
    --bs-btn-padding-x: 30px;
    --bs-btn-padding-y: 19px;
    --bs-btn-font-family: var(--tc-btn-font-family);
    --bs-btn-font-size: 14px;
    --bs-btn-font-weight: 600;
    --bs-btn-line-height: 100%;
    --bs-btn-color: var(--bs-body-color);
    --bs-btn-bg: transparent;
    --bs-btn-border-width: var(--bs-border-width);
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: 10px;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    background: var(--bs-btn-bg);
    gap: 10px;
    text-transform: uppercase;
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    svg {
        path {
            transition: 0.15s ease-in-out;
        }
    }
    &:hover {
        svg {
            path {
                transition: 0.15s ease-in-out;
                fill: var(--bs-btn-hover-color) !important;
            }
        }
    }
}

.btn-linear {
    --bs-btn-color: var(--tc-system-white);
    --bs-btn-bg: var(--tc-linear-1);
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: var(--tc-system-white);
    --bs-btn-hover-bg: var(--tc-linear-1);
    --bs-btn-hover-border-color: transparent;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: var(--tc-system-white);
    --bs-btn-active-bg: var(--tc-linear-1);
    --bs-btn-active-border-color: transparent;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: var(--tc-system-black);
    --bs-btn-disabled-bg: var(--tc-theme-primary);
    --bs-btn-disabled-border-color: var(--tc-theme-primary);
    --bs-btn-border-width: 0px;
}
.btn-dark {
    --bs-btn-color: var(--tc-system-white);
    --bs-btn-bg: var(--tc-system-black);
    --bs-btn-border-color: none;
    --bs-btn-hover-color: var(--tc-system-white);
    --bs-btn-hover-bg: var(--tc-system-black);
    --bs-btn-hover-border-color: none;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: var(--tc-system-white);
    --bs-btn-active-bg: var(--tc-system-black);
    --bs-btn-active-border-color: var(--tc-system-black);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: var(--tc-system-white);
    --bs-btn-disabled-bg: var(--tc-theme-primary);
    --bs-btn-disabled-border-color: var(--tc-theme-primary);
}
.btn-white {
    --bs-btn-color: var(--tc-system-black);
    --bs-btn-bg: var(--tc-system-white);
    --bs-btn-border-color: none;
    --bs-btn-hover-color: var(--tc-system-black);
    --bs-btn-hover-bg: var(--tc-system-white);
    --bs-btn-hover-border-color: none;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: var(--tc-system-black);
    --bs-btn-active-bg: var(--tc-system-white);
    --bs-btn-active-border-color: none;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: var(--tc-system-white);
    --bs-btn-disabled-bg: var(--tc-theme-primary);
    --bs-btn-disabled-border-color: var(--tc-theme-primary);
}
.btn-outline-dark {
    --bs-btn-color: var(--tc-system-black);
    --bs-btn-bg: var(--tc-system-white);
    --bs-btn-border-color: var(--tc-system-black);
    --bs-btn-hover-color: var(--tc-system-white);
    --bs-btn-hover-bg: var(--tc-theme-primary);
    --bs-btn-hover-border-color: var(--tc-theme-primary);
    --bs-btn-focus-shadow-rgb: 108, 117, 125;
    --bs-btn-active-color: var(--tc-system-white);
    --bs-btn-active-bg: var(--tc-theme-primary);
    --bs-btn-active-border-color: var(--tc-theme-primary);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-outline-secondary {
    --bs-btn-color: var(--tc-system-black);
    --bs-btn-bg: var(--tc-system-white);
    --bs-btn-border-color: var(--tc-system-stroke);
    --bs-btn-hover-color: var(--tc-system-white);
    --bs-btn-hover-bg: var(--tc-theme-primary);
    --bs-btn-hover-border-color: var(--tc-theme-primary);
    --bs-btn-focus-shadow-rgb: 108, 117, 125;
    --bs-btn-active-color: var(--tc-system-white);
    --bs-btn-active-bg: var(--tc-theme-primary);
    --bs-btn-active-border-color: var(--tc-theme-primary);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.play-btn {
    font-size: 24px;
    line-height: 32px;
    position: relative;
    background-color: rgba(255, 255, 255, 1);
    &:before {
        content: '';
        position: absolute;
        width: 150%;
        height: 150%;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
        -webkit-animation: pulsate1 2s;
        animation: pulsate1 2s;
        -webkit-animation-direction: forwards;
        animation-direction: forwards;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: steps;
        animation-timing-function: steps;
        opacity: 1;
        border-radius: 50%;
        border: 5px solid var(--tc-system-white);
        box-shadow: rgba(255, 255, 255, 0.78) 0px 2px 4px 0px;
    }
}
@-webkit-keyframes pulsate1 {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
        box-shadow: none;
    }
}
@keyframes pulsate1 {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1);
        opacity: 0;
        box-shadow: none;
    }
}

// Avatar
$avatar-size-xs: 1.5rem !default;
$avatar-size-sm: 2rem !default;
$avatar-size-md: 2.5rem !default;
$avatar-size-lg: 3.5rem !default;
$avatar-size-xl: 5rem !default;
$avatar-size-xxl: 7rem !default;
// Avatar
.avatar {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 3rem;
}
// Avatar Size
.avatar-xs {
    width: $avatar-size-xs;
    height: $avatar-size-xs;
}
.avatar-sm {
    width: $avatar-size-sm;
    height: $avatar-size-sm;
}
.avatar-md {
    width: $avatar-size-md;
    height: $avatar-size-md;
}
.avatar-lg {
    width: $avatar-size-lg;
    height: $avatar-size-lg;
}
.avatar-xl {
    width: $avatar-size-xl;
    height: $avatar-size-xl;
}
.avatar-xxl {
    width: $avatar-size-xxl;
    height: $avatar-size-xxl;
}
// Icon
$icon-size-xxs: 1rem !default;
$icon-size-xs: 1.5rem !default;
$icon-size-sm: 2rem !default;
$icon-size-md: 2.5rem !default;
$icon-size-lg: 3rem !default;
$icon-size-xl: 3.5rem !default;
$icon-size-xxl: 4rem !default;
$icon-size-xxxl: 7rem !default;
.icon-xl {
    width: 3.5rem;
    height: 3.5rem;
    line-height: 3.5rem;
}
.icon-shape {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
}
// Icon shape
.icon-xxs {
    width: $icon-size-xxs;
    height: $icon-size-xxs;
    line-height: $icon-size-xxs;
}
.icon-xs {
    width: $icon-size-xs;
    height: $icon-size-xs;
    line-height: $icon-size-xs;
}
.icon-sm {
    width: $icon-size-sm;
    height: $icon-size-sm;
    line-height: $icon-size-sm;
}
.icon-md {
    width: $icon-size-md;
    height: $icon-size-md;
    line-height: $icon-size-md;
}
.icon-lg {
    width: $icon-size-lg;
    height: $icon-size-lg;
    line-height: $icon-size-lg;
}
.icon-xl {
    width: $icon-size-xl;
    height: $icon-size-xl;
    line-height: $icon-size-xl;
}
.icon-xxl {
    width: $icon-size-xxl;
    height: $icon-size-xxl;
    line-height: $icon-size-xxl;
}
.icon-xxxl {
    width: $icon-size-xxxl;
    height: $icon-size-xxxl;
    line-height: $icon-size-xxxl;
}
.icon-40 {
    width: 40px !important;
    height: 40px !important;
    line-height: 40px !important;
}
.icon-50 {
    width: 50px !important;
    height: 50px !important;
    line-height: 50px !important;
}
.icon-60 {
    width: 60px !important;
    height: 60px !important;
    line-height: 60px !important;
}
.icon-100 {
    width: 100px !important;
    height: 100px !important;
    line-height: 100px !important;
}
.icon-110 {
    width: 110px !important;
    height: 110px !important;
    line-height: 110px !important;
}
.icon-120 {
    width: 120px !important;
    height: 120px !important;
    line-height: 120px !important;
}
.icon-160 {
    width: 160px !important;
    height: 160px !important;
    line-height: 160px !important;
}
.icon-177 {
    width: 177px !important;
    height: 177px !important;
    line-height: 177px !important;
}
.icon-150 {
    width: 150px !important;
    height: 150px !important;
    line-height: 150px !important;
}
.icon-140 {
    width: 140px !important;
    height: 140px !important;
    line-height: 140px !important;
}
.icon-200 {
    width: 200px !important;
    height: 200px !important;
    line-height: 200px !important;
}
.icon-70 {
    width: 70px !important;
    height: 70px !important;
    line-height: 70px !important;
}
.icon-75 {
    width: 75px !important;
    height: 75px !important;
    line-height: 75px !important;
}
.icon-80 {
    width: 80px !important;
    height: 80px !important;
    line-height: 80px !important;
}
