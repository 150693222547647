// Navbar
.navbar {
    padding: 0.5rem;
    background-color: var(--tc-system-white);

    @media (max-width: 990px) {
        padding: 0.75rem 0.5rem;
    }

    .navbar-brand {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    .dropdown-menu {
        top: 100%;
        color: var(--tc-system-black);
        border: none;
        min-width: 12rem;
        box-shadow:
            0 0.5rem 1rem rgba(0, 0, 0, 0.15),
            inset 0 -1px 0 rgba(255, 255, 255, 0.15);
        border-radius: 6px;
        margin-top: 0rem;

        @media (max-width: 992px) {
            box-shadow: none;
            min-width: 0 !important;
            background-color: transparent;
        }
        .dropdown-item {
            font-size: 14px;
            &:hover {
                background-color: transparent;
                color: var(--tc-theme-primary);
            }

            &:active {
                background-color: transparent;
                color: var(--tc-theme-primary);
            }
        }
        &.sub-menu {
            .dropdown-item {
                .arrow {
                    opacity: 0;
                    transition: all 0.2s ease-in-out;
                }
                &:hover {
                    .arrow {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .navbar-nav {
        .dropdown {
            @media (min-width: 990px) {
                > .dropdown-menu {
                    left: 0;
                    transform: translate(0%, 30px);
                }
            }
        }

        .nav-item {
            &.menu-item-has-children {
                position: relative;

                .nav-link {
                    &::after {
                        font-family: 'remixicon' !important;
                        content: '\ea4e';
                        position: absolute;
                        bottom: 0.5rem;
                        right: 0rem;
                        color: var(--tc-theme-primary);
                    }
                }

                &.dot-style {
                    .nav-link {
                        &::after {
                            display: none;
                        }

                        &::before {
                            font-family: 'remixicon' !important;
                            content: '';
                            width: 5px;
                            height: 5px;
                            border-radius: 50%;
                            display: block;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 0.4rem;
                            background-color: var(--tc-theme-primary);
                        }
                    }
                }
            }

            .nav-link {
                font-size: 14px;
                color: var(--tc-system-black);
                font-weight: 600;
                padding: 0.5rem 1rem;
                border-radius: 0.25rem;
                font-family: var(--tc-btn-font-family);

                &:focus-visible {
                    box-shadow: none;
                }

                &:hover {
                    color: var(--tc-system-black);
                }

                &.active {
                    color: var(--tc-system-black);
                }
            }
        }
    }
}
// Responsive breakpoints
@media (min-width: 1200px) {
    .navbar {
        .dropdown-menu.show {
            display: none;
        }

        .dropdown-menu {
            display: block;
            visibility: hidden;
            opacity: 0;
            -webkit-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            transform: translate(-50%);
        }

        .dropdown {
            &:hover {
                > .dropdown-menu {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        .dropdown-submenu {
            &:hover {
                > .dropdown-menu {
                    transform: translateY(10px);
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}
// navbar-nav
.navbar-nav {
    .nav-item {
        .nav-link {
            color: var(--tc-system-white);

            &:hover {
                color: var(--tc-system-white);
            }
        }
    }
}
// navbar dark
.navbar-dark {
    width: 100%;
    background: #020202 !important;
    .navbar-nav {
        .nav-item {
            &.menu-item-has-children {
                .nav-link {
                    &::after {
                        background: var(--tc-linear-1);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
            .dropdown-menu {
                .dropdown-item {
                    color: var(--tc-system-black);

                    &:hover {
                        background: var(--tc-linear-1);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
            .nav-link {
                color: var(--tc-system-white) !important;

                &:focus-visible {
                    box-shadow: none;
                }

                &:hover {
                    span {
                        background: var(--tc-linear-1);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
                &.active {
                    span {
                        background: var(--tc-linear-1);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }
    }
}
// navbar light
.navbar-light {
    .navbar-nav {
        .nav-item {
            .nav-link {
                color: var(--tc-neutral-1000);
                font-weight: 500;

                &:hover,
                &.active {
                    color: var(--tc-neutral-800);
                    background-color: var(--tc-neutral-100);
                }
            }
        }
    }
}
// navbar transparent
.navbar-transparent {
    background-color: transparent;
    position: absolute;
    width: 100%;
    z-index: 1009;
    left: 0;
    right: 0;
    box-shadow: none;
    .navbar-nav {
        .nav-item {
            .nav-link {
                color: var(--tc-system-white);

                &:hover,
                &.active {
                    color: var(--tc-system-white) !important;
                }
            }

            &.menu-item-has-children {
                position: relative;

                .nav-link {
                    &::after {
                        color: var(--tc-system-white);
                    }
                }
            }
        }
    }
    .burger-icon > span::before,
    .burger-icon > span::after {
        background-color: var(--tc-system-black);
    }
    &.navbar-stick {
        background: rgba(41, 41, 41, 0.6) !important;
        backdrop-filter: blur(50px);
        z-index: 1009 !important;

        .navbar-nav {
            .nav-item {
                &.menu-item-has-children {
                    position: relative;

                    .nav-link {
                        &::after {
                            font-family: 'remixicon' !important;
                            content: '\ea4e';
                            position: absolute;
                            bottom: 0.5rem;
                            right: 0rem;
                            color: var(--tc-system-white);
                        }
                    }
                }
            }
        }
    }
}
body {
    &.crypto-agency {
        .navbar-stick {
            background: #040826 !important;
        }
    }
}
.navbar-transparent-2 {
    background-color: transparent;
}
// navbar transparent
.navbar-pink {
    background-color: var(--tc-system-pink);
    box-shadow: none;
    .navbar-nav {
        .nav-item {
            .nav-link {
                font-family: 'DM Serif Display', serif;
                font-weight: 400;
                color: var(--tc-system-white);
                &:hover,
                &.active {
                    color: var(--tc-system-white) !important;
                }
            }
            &.menu-item-has-children {
                position: relative;

                .nav-link {
                    &::after {
                        color: var(--tc-system-white);
                    }
                }
            }
            .dropdown-menu {
                .dropdown-item {
                    font-family: 'DM Serif Display', serif;
                    font-weight: 400;
                }
            }
        }
    }
    .burger-icon > span::before,
    .burger-icon > span::after {
        background-color: var(--tc-system-white);
    }
    &.navbar-stick {
        background-color: var(--tc-system-pink) !important;
        z-index: 1009 !important;
        .navbar-nav {
            .nav-item {
                &.menu-item-has-children {
                    position: relative;
                    .nav-link {
                        &::after {
                            font-family: 'remixicon' !important;
                            content: '\ea4e';
                            position: absolute;
                            bottom: 0.5rem;
                            right: 0rem;
                            color: var(--tc-system-white);
                        }
                    }
                }
            }
        }
    }
}
.navbar-page-8 {
    background-color: var(--tc-theme-primary);
    z-index: 1009;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #eff4f9;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        z-index: 0;
    }

    &.navbar-stick {
        &::before {
            display: none;
        }
    }
}

.navbar-page-9 {
    &:not(.navbar-dark) {
        box-shadow: none !important;
        background: var(--tc-system-white);
    }
}

// navbar stick
.navbar-stick {
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);

    // border-bottom: 1px solid var(--tc-system-black);
    &:not(.navbar-dark) {
        box-shadow:
            0 0.5rem 1rem rgba(0, 0, 0, 0.15),
            inset 0 -1px 0 rgba(255, 255, 255, 0.15);
        background: var(--tc-system-white);
    }
}

// offcanvas
.offcanvas.show:not(.hiding),
.offcanvas.showing {
    transform: none;
}

.offcanvas {
    height: 100vh;
}
