:root {
    --bs-border-color: var(--tc-system-stroke) !important;
}

// color-homepage
body {
    &.ai-solutions {
        --bs-border-radius-xl: 10px !important;

        .border-primary {
            --bs-primary-rgb: 121, 74, 255;
        }

        .text-linear {
            background: var(--tc-linear-1);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .bg-dark-1 {
            background-color: #020202 !important;
        }

        .bg-dark {
            background-color: #0a0614 !important;
        }

        .bg-secondary-2 {
            background-color: #f1f1f1;
        }

        .btn-outline-dark {
            --bs-btn-hover-bg: var(--tc-linear-1);
            --bs-btn-hover-border-color: var(--tc-linear-1);
            --bs-btn-active-bg: var(--tc-linear-1);
            --bs-btn-active-border-color: var(--tc-linear-1);
        }
        .btn-white {
            &:hover {
                --bs-btn-hover-color: var(--tc-system-black);
            }
        }
    }
}

// background colors
.bg-primary {
    background-color: var(--tc-theme-primary) !important;
}
.bg-1 {
    background-color: var(--tc-bg-1) !important;
}

.bg-light {
    background-color: var(--tc-bg-light) !important;
}

.bg-light-2 {
    background-color: var(--tc-bg-light-2) !important;
}
// text colors
.text-primary {
    color: var(--tc-theme-primary) !important;
}
.text-dark {
    color: var(--tc-system-black) !important;
}
.btn-text {
    font-family: var(--tc-btn-font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 14px */
    text-transform: uppercase;
}

.fill-primary {
    path {
        fill: var(--tc-theme-primary) !important;
    }

    fill: var(--tc-theme-primary) !important;
}

.fill-white {
    path {
        fill: var(--tc-system-white) !important;
    }

    fill: var(--tc-system-white) !important;
}

// variables end
.border-linear {
    background:
        linear-gradient(#060b2e, #060b2e) padding-box,
        linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(26, 28, 76, 0)) border-box;
    border: 2px solid transparent;
}

.border-linear-primary {
    background:
        linear-gradient(#03061c, #03061c) padding-box,
        linear-gradient(to bottom, rgba(177, 227, 70, 0.3), rgba(26, 28, 76, 0)) border-box;
    border: 1px solid transparent;
}

.border-linear-green-3 {
    border-image-slice: 1;
    border-image-source: -webkit-linear-gradient(180deg, #d5d52b 0%, #01473c 100%) !important;
}

.text-gradient {
    background: -webkit-linear-gradient(left, #6d4df2, #8c71ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.border-yellow {
    --bs-border-color: var(--tc-system-yellow) !important;
}

.backdrop-filter-20 {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
}

.backdrop-filter-50 {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(50px);
}

.mask-image {
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0));
}

.mask-image-2 {
    mask-image: linear-gradient(-270deg, #fff 14.77%, rgba(255, 255, 255, 0) 100%);
}

.bg-dark {
    --bs-dark-rgb: 41, 41, 41;
}

.bg-dark-2 {
    background: var(--tc-system-black-2);
}

.filter-invert {
    filter: invert(1);
}

.contact-map {
    max-height: 700px;

    iframe {
        height: 700px;
        width: 100%;
    }
}

.map {
    -webkit-filter: grayscale(75%);
    -moz-filter: grayscale(75%);
    -ms-filter: grayscale(75%);
    -o-filter: grayscale(75%);
    filter: grayscale(75%);
}

.bg-dark-5 {
    background-color: #24222c;
}
